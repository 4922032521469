<header>
    <mat-card-title>
        <mat-icon>analytics</mat-icon>
        Add Contractor
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<div>
    <small
        >This is only to be used for Contractors that do not require an account
        to upload their own Invoices.</small
    >
    <br />
    <mat-label>Please Enter name of Contractor</mat-label>

    <mat-form-field appearance="outline">
        <mat-label>Company Name</mat-label>
        <input matInput [(ngModel)]="companyName" required />
    </mat-form-field>
    <button
        (click)="addCompany()"
        mat-raised-button
        [disabled]="!companyName?.trim() || loading"
    >
        {{ loading ? 'Adding...' : 'Add Contractor' }}
    </button>
</div>
<div *ngIf="error">{{ error }}</div>
