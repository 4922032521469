import { Component, OnInit } from '@angular/core';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { FormControl, FormGroup } from '@angular/forms';
import { PropertyFilter } from '../../enums/property';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { PropertyDetail } from '../../interfaces/property';
import { StorageKeys } from '../../enums/storage';
import { InfoModalComponent } from '../../components/info-modal/info-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-property.ts-details-page',
    templateUrl: './property-details-page.component.html',
    styleUrls: ['./property-details-page.component.css'],
})
export class PropertyDetailsPageComponent implements OnInit {
    public unitCourts: Set<string> | undefined;
    public unitHouses: Set<string> | undefined;
    public unitNumbers: Set<string> | undefined;
    public loading: boolean = false;
    searchName!: string;
    searchField: 'leaseholder' | 'tenant' = 'leaseholder';
    showSearchField: 'leaseholder' | 'tenant' = 'leaseholder';
    searchResults!: Partial<PropertyDetail[]>;
    searching: boolean = false;
    disableList: boolean = false;

    searchForm = new FormGroup({
        unitCourt: new FormControl(),
        unitHouse: new FormControl(),
        unit: new FormControl(),
    });

    constructor(
        public propDetailsService: PropertyDetailsService,
        public router: Router,
        public dialog: MatDialog
    ) {}

    /** Angular lifecycle event */
    async ngOnInit(): Promise<void> {
        this.loading = true;
        this.unitCourts = await this.propDetailsService.getUnitFilters(
            PropertyFilter.UnitCourt
        );
        this.unitHouses = await this.propDetailsService.getUnitFilters(
            PropertyFilter.UnitHouse
        );

        this.subscribeToHouse();
        this.subscribeToCourt();
        this.loading = false;
    }

    /** Subscription to unitHouse value changes */
    subscribeToHouse(): void {
        this.searchForm.controls[
            PropertyFilter.UnitHouse
        ].valueChanges.subscribe(async (value) => {
            this.unitNumbers = await this.propDetailsService.getUnitNumbers(
                PropertyFilter.UnitHouse,
                value
            );
            this.searchForm.controls[PropertyFilter.UnitCourt].setValue(null, {
                onlySelf: true,
                emitEvent: false,
            });
            this.searchForm.controls[PropertyFilter.UnitNumber].setValue(null, {
                onlySelf: true,
                emitEvent: false,
            });
        });
    }

    /** Subscription to unitCourt value changes */
    subscribeToCourt(): void {
        this.searchForm.controls[
            PropertyFilter.UnitCourt
        ].valueChanges.subscribe(async (value) => {
            this.unitNumbers = await this.propDetailsService.getUnitNumbers(
                PropertyFilter.UnitCourt,
                value
            );
            this.searchForm.controls[PropertyFilter.UnitHouse].setValue(null, {
                onlySelf: true,
                emitEvent: false,
            });
            this.searchForm.controls[PropertyFilter.UnitNumber].setValue(null, {
                onlySelf: true,
                emitEvent: false,
            });
        });
    }

    /** Get Property details using filter */
    async submitViewProperty(criteria?: string): Promise<boolean | undefined> {
        let searchCriteria;
        if (criteria) {
            searchCriteria = criteria;
            this.disableList = true;
        } else {
            let field: PropertyFilter = this.searchForm.controls[
                PropertyFilter.UnitCourt
            ].value
                ? PropertyFilter.UnitCourt
                : PropertyFilter.UnitHouse;
            searchCriteria = await this.propDetailsService.getSearchCriteria(
                this.searchForm.controls[PropertyFilter.UnitNumber].value,
                field,
                this.searchForm.controls[field].value
            );
        }

        if (searchCriteria) {
            if (!criteria) this.loading = true;
            try {
                let propertyDetails: PropertyDetail = await firstValueFrom(
                    this.propDetailsService.callPropertyEndpoint(searchCriteria)
                );
                let propertyFiles: string[] = await firstValueFrom(
                    await this.propDetailsService.getFileList(searchCriteria)
                );

                if (propertyDetails) {
                    localStorage.setItem(
                        StorageKeys.PropertyDetail,
                        JSON.stringify(propertyDetails)
                    );
                    propertyFiles
                        ? localStorage.setItem(
                              StorageKeys.PropertyFiles,
                              JSON.stringify(propertyFiles)
                          )
                        : localStorage.removeItem(StorageKeys.PropertyFiles);
                    this.loading = false;
                    return this.router.navigateByUrl(
                        'home-page/property-results?from=search'
                    );
                }
            } catch (error: any) {
                if (!criteria) this.loading = false;
                this.disableList = false;
                this.openInfoDialog('Error connecting to Database');
            }
        }
        if (!criteria) this.loading = false;
        this.disableList = false;
        return;
    }

    /** Open Info Dialog Box */
    openInfoDialog = (message: string) => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    async getSearchResults(name: string, field: 'leaseholder' | 'tenant') {
        if (!name || name.length < 3) return;
        this.searching = true;
        this.searchResults = await firstValueFrom(
            this.propDetailsService.callSearchEndpoint(name, field)
        );
        this.showSearchField = field;
        this.searching = false;
    }
}
