import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ContractorDetails,
    SignUpForm,
} from '../../contractor-sign-up/contractor-sign-up.component';
import { firstValueFrom } from 'rxjs';
import { ContractorsService } from '../../../services/contractors/contractors.service';
import { InfoModalComponent } from '../../../components/info-modal/info-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmModalComponent } from '../../../components/confirm-modal/confirm-modal.component';
import { ContractorFileUploadComponent } from '../../../components/contractor-file-upload/contractor-file-upload.component';
import { WorkOrdersService } from '../../../services/work-orders/work-orders.service';
import { WorkOrder } from '../../work-orders/work-orders.component';
import { format } from 'date-fns';

@Component({
    selector: 'app-view-contractor',
    templateUrl: './view-contractor.component.html',
    styleUrls: ['./view-contractor.component.css'],
})
export class ViewContractorComponent implements OnInit {
    companyName!: string;
    contractorForm!: FormGroup;
    approval!: boolean;
    noAccount?: boolean;
    applicationId!: string;
    contractor!: ContractorDetails;
    loading: boolean = true;
    messageText: string = '';
    messageBanner: boolean = false;
    approved: boolean = false;
    files!: { fileKey: string; metaData: any }[];
    mappedFiles!: { fileKey: string; expiry?: Date; updatingExpiry: boolean }[];
    format = format;
    expiredFiles: boolean = false;

    workOrders!: Partial<WorkOrder>[];

    constructor(
        public route: ActivatedRoute,
        public contractorService: ContractorsService,
        public dialog: MatDialog,
        public router: Router,
        public workOrderService: WorkOrdersService
    ) {}

    async ngOnInit(): Promise<any> {
        this.loading = true;

        this.applicationId = (await firstValueFrom(this.route.params))['id'];
        this.contractor = await firstValueFrom(
            await this.contractorService.getContractorDetails(
                this.applicationId
            )
        );
        this.workOrders = (
            await firstValueFrom(
                await this.workOrderService.getContractorOrders(
                    this.applicationId
                )
            )
        ).sort((a, b) => b!.orderNumber!.localeCompare(a!.orderNumber!));
        this.files = await firstValueFrom(
            await this.contractorService.getFileList(this.applicationId)
        );
        this.mappedFiles = this.files.map((file) => ({
            fileKey: file.fileKey,
            expiry: file?.metaData?.Metadata?.expiry
                ? new Date(+file?.metaData?.Metadata?.expiry)
                : undefined,
            updatingExpiry: false,
        }));
        this.expiredFiles = !this.mappedFiles.every(
            (file) => !file.expiry || file?.expiry > new Date()
        );

        if (!this.contractor || Object.keys(this.contractor).length === 0) {
            await this.router.navigateByUrl('/home-page/contractors');
            return;
        }

        this.companyName = this.contractor?.companyName;
        this.approval = this.contractor.status === 'unverified';
        this.noAccount = this.contractor?.noAccount;

        this.initialiseForm();

        if (this.approval) this.contractorForm.disable();
        this.loading = false;
    }

    initialiseForm() {
        this.contractorForm = new FormGroup<Partial<SignUpForm>>({
            applicationId: new FormControl(
                { value: this.contractor.applicationId, disabled: true },
                Validators.required
            ),
            companyName: new FormControl(
                { value: this.contractor?.companyName, disabled: true },
                Validators.required
            ),
            contactName: new FormControl(
                {
                    value: this.contractor?.contactName,
                    disabled: false,
                },
                this.noAccount ? null : Validators.required
            ),
            email: new FormControl(
                {
                    value: this.contractor?.email,
                    disabled: !this.noAccount,
                },
                this.noAccount
                    ? [Validators.email]
                    : [Validators.email, Validators.required]
            ),
            phone: new FormControl(
                {
                    value: this.contractor?.phone,
                    disabled: false,
                },
                this.noAccount
                    ? [Validators.pattern(/^[0-9]*$/)]
                    : [Validators.required, Validators.pattern(/^[0-9]*$/)]
            ),
            addressOne: new FormControl(
                {
                    value: this.contractor?.addressOne,
                    disabled: false,
                },
                this.noAccount ? null : Validators.required
            ),
            addressTwo: new FormControl({
                value: this.contractor?.addressTwo,
                disabled: false,
            }),
            addressThree: new FormControl({
                value: this.contractor?.addressThree,
                disabled: false,
            }),
            postcode: new FormControl(
                {
                    value: this.contractor?.postcode,
                    disabled: false,
                },
                this.noAccount ? null : Validators.required
            ),
            consent: new FormControl(
                {
                    value: this.contractor?.consent,
                    disabled: false,
                },
                this.noAccount ? null : Validators.required
            ),
            bankName: new FormControl({
                value: this.contractor?.bankName,
                disabled: false,
            }),
            sortCode: new FormControl({
                value: this.contractor?.sortCode,
                disabled: false,
            }),
            accountNumber: new FormControl({
                value: this.contractor?.accountNumber,
                disabled: false,
            }),
            accountName: new FormControl({
                value: this.contractor?.accountName,
                disabled: false,
            }),
            workOrderEmails: new FormControl({
                value: this.contractor?.workOrderEmails ?? true,
                disabled: false,
            }),
        });
    }

    async approveContractor() {
        await firstValueFrom(
            await this.contractorService.updateContractor({
                applicationId: this.applicationId,
                status: 'verified',
                email: this.contractor.email,
                companyName: this.contractor.companyName,
            })
        );
        this.showMessageBanner('Application Approved');
        this.approved = true;
        await this.router.navigateByUrl('/home-page/contractors');
    }

    async deleteContractor() {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
                message: 'Are you sure you want to delete this Contractor?',
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                const deleteResponse = await firstValueFrom(
                    await this.contractorService.updateContractor({
                        applicationId: this.applicationId,
                        deletionMarker: true,
                        status: 'Deleted',
                        email: this.contractor.email,
                        ...(this.noAccount && { noAccount: this.noAccount }),
                    })
                );
                this.showMessageBanner(deleteResponse.body);
                await this.router.navigateByUrl('/home-page/contractors');
            }
        });
    }

    /**
     * Open info dialog
     * @param message
     */
    openInfoDialog = (message: string): void => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    /**
     * Show Message banner
     *
     * @param text
     */
    showMessageBanner = (text: string): void => {
        this.messageText = text;
        this.messageBanner = true;
        setTimeout(() => (this.messageBanner = false), 3000);
    };

    async saveChanges(): Promise<void> {
        const changedValues: Partial<ContractorDetails> = {
            applicationId: this.applicationId,
        };

        Object.keys(this.contractorForm.controls).forEach((controlName) => {
            if (this.contractorForm.get(controlName)?.dirty) {
                changedValues[controlName as keyof ContractorDetails] =
                    this.contractorForm.get(controlName)?.value;
            }
        });

        if (Object.keys(changedValues).length > 1) {
            try {
                const submitResponse = await firstValueFrom(
                    await this.contractorService.updateContractor(changedValues)
                );
                this.contractorForm.markAsPristine();
                this.showMessageBanner(submitResponse.body);
            } catch {
                this.openInfoDialog(
                    'Cannot connect to Server. Changes have not been saved.'
                );
            }
        }
    }

    resetForm = (): void => {
        this.initialiseForm();
        this.contractorForm.markAsPristine();
    };

    async canDeactivate() {
        return new Promise((resolve) => {
            if (this.contractorForm && this.contractorForm.dirty) {
                const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                        message:
                            'You have unsaved changes that will be lost, are you sure?',
                    },
                });

                dialogRef.afterClosed().subscribe(async (result) => {
                    if (!result) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    /**
     * * View file
     * @param fileName
     */
    openFile(fileName: string): void {
        const windowRef = window.open();
        firstValueFrom(
            this.contractorService.getPresignedUrl(fileName, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * Delete file for property
     * @param file
     */
    deleteFile(file: string): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this file?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                (await this.contractorService.deleteFile(file)).subscribe(
                    async () => {
                        this.files = await firstValueFrom(
                            await this.contractorService.getFileList(
                                this.applicationId
                            )
                        );
                    }
                );
            }
        });
    }

    /**
     * Open file upload modal
     */
    openFileUploadModal = (): void => {
        const fileRef = this.dialog.open(ContractorFileUploadComponent, {
            width: '500px',
            data: {
                applicationId: this.applicationId,
            },
            disableClose: true,
        });

        fileRef.afterClosed().subscribe(async () => {
            this.files = await firstValueFrom(
                await this.contractorService.getFileList(this.applicationId)
            );
            this.mappedFiles = this.files.map((file) => ({
                fileKey: file.fileKey,
                expiry: file?.metaData?.Metadata?.expiry
                    ? new Date(+file?.metaData?.Metadata?.expiry)
                    : undefined,
                updatingExpiry: false,
            }));
            this.expiredFiles = !this.mappedFiles.every(
                (file) => !file.expiry || file?.expiry > new Date()
            );
        });
    };

    async updateExpiry(file: {
        fileKey: string;
        expiry?: Date;
        updatingExpiry: boolean;
    }) {
        file.updatingExpiry = true;
        try {
            console.log(file);
            await firstValueFrom(
                this.contractorService.updateMeta(
                    file.fileKey,
                    file.expiry ? file.expiry.getTime().toString() : undefined
                )
            );
        } catch (error: any) {
            console.error(error.message);
        } finally {
            this.expiredFiles = !this.mappedFiles.every(
                (file) => !file.expiry || file?.expiry > new Date()
            );
            file.updatingExpiry = false;
        }
    }
}
