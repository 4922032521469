<header>
    <mat-card-title>
        <mat-icon>analytics</mat-icon>
        Invite Contractor
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<div *ngIf="!message">
    <mat-form-field appearance="outline">
        <mat-label>Contractor Email Address</mat-label>
        <input matInput [(ngModel)]="email" />
    </mat-form-field>
    <button
        (click)="sendInvite()"
        mat-raised-button
        [disabled]="sending || !email || (!!email && !emailRegex.test(email))"
    >
        Send Invite
    </button>
</div>
<div *ngIf="message">{{ message }}</div>
