<div style="text-align: center">
    <apx-chart
        [series]="chartOptions.series!"
        [chart]="chartOptions.chart!"
        [xaxis]="chartOptions.xaxis!"
        [title]="chartOptions.title!"
        [yaxis]="chartOptions.yaxis!"
        [colors]="chartOptions.colors!"
        [dataLabels]="chartOptions.dataLabels!"
    ></apx-chart>

    <mat-divider></mat-divider>
    <apx-chart
        [series]="chartOptions2.series!"
        [chart]="chartOptions2.chart!"
        [xaxis]="chartOptions2.xaxis!"
        [title]="chartOptions2.title!"
        [yaxis]="chartOptions2.yaxis!"
        [colors]="chartOptions2.colors!"
        [dataLabels]="chartOptions2.dataLabels!"
        [fill]="chartOptions2.fill!"
        [stroke]="chartOptions2.stroke!"
    ></apx-chart>
</div>
