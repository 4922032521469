<header>
    <mat-card-title>
        <mat-icon>edit_square</mat-icon>
        Update Service Charge
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<div *ngIf="completed" class="formDiv">
    <mat-label>Service Charge Demand Updated</mat-label>
</div>

<div *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div class="formDiv" *ngIf="!loading && !completed">
    <mat-form-field appearance="outline">
        <mat-label>Demand to Update </mat-label>
        <mat-select [(ngModel)]="serviceCharge">
            <mat-option
                *ngFor="let demand of data['serviceCharge']"
                [value]="demand"
                >{{ demand | underscore | ucwords }}</mat-option
            >
        </mat-select>
    </mat-form-field>
    <div class="buttonDiv">
        <button
            mat-raised-button
            [mat-dialog-close]="null"
            class="cancelButton"
        >
            Cancel
        </button>
        <button
            mat-raised-button
            [disabled]="loading || !serviceCharge"
            (click)="updateDemand()"
            class="saveButton"
        >
            Update
        </button>
    </div>
    <br />
    <mat-label *ngIf="errorMessage">{{ errorMessage }}</mat-label>
</div>
