import { Component, OnInit } from '@angular/core';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';

@Component({
    selector: 'app-leaseholder',
    templateUrl: './leaseholder.component.html',
    styleUrls: ['./leaseholder.component.css'],
})
export class LeaseholderComponent implements OnInit {
    userName!: string;

    constructor(public auth: AuthServiceService) {}

    async ngOnInit(): Promise<void> {
        this.userName = await this.auth.getUserName();
    }

    /**
     * Log user out of App and return to landing page
     */
    logOut = async (): Promise<void> => {
        await this.auth.signOut();
    };
}
