<app-page-toolbar></app-page-toolbar>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon slot="start">edit_square</mat-icon>
      Batch Service Charges
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="selectForm" [formGroup]="budgetForm">
      <mat-form-field appearance="outline">
        <mat-label>Budget Year</mat-label>
        <mat-select formControlName="budgetYear">
          <mat-option>-</mat-option>
          <mat-option
            *ngFor="let year of budgetYears"
            [value]="year"
          >{{ year }}
          </mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Service Charge Month</mat-label>
        <mat-select formControlName="month">
          <mat-option>-</mat-option>
          <mat-option [value]="'March'">March</mat-option>
          <mat-option [value]="'September'">September</mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-flat-button
        [disabled]="budgetForm.invalid || loading"
        (click)="generateCharges()"
      >
        Batch Generate
      </button>
    </form>
  </mat-card-content>
  <div *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon slot="start">currency_pound</mat-icon>
      Apply Charges to Accounts
    </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form class="selectForm" [formGroup]="applyChargesForm">
      <mat-form-field appearance="outline">
        <mat-label>Budget Year</mat-label>
        <mat-select formControlName="budgetYear">
          <mat-option>-</mat-option>
          <mat-option
            *ngFor="let year of budgetYears"
            [value]="year"
          >{{ year }}
          </mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>Service Charge Month</mat-label>
        <mat-select formControlName="month">
          <mat-option>-</mat-option>
          <mat-option [value]="'March'">March</mat-option>
          <mat-option [value]="'September'">September</mat-option>
        </mat-select>
      </mat-form-field>

      <button
        mat-flat-button
        [disabled]="applyChargesForm.invalid || loading"
        (click)="applyCharges()"
      >
        Apply Batch Charges
      </button>
      <button
        mat-flat-button
        [disabled]="applyChargesForm.invalid || loading"
        (click)="checkCharges()"
      >
        Check Charges
      </button>


    </form>

  </mat-card-content>
  <pre *ngIf="check">{{ check | json }}</pre>
  <div *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</mat-card>
