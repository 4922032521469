import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-footer-toolbar',
    templateUrl: './footer-toolbar.component.html',
    styleUrls: ['./footer-toolbar.component.css'],
})
export class FooterToolbarComponent {
    @Input() cancelButton: boolean | undefined;
    @Input() cancelDisabled: boolean | undefined;

    @Input() saveButton: boolean | undefined;
    @Input() saveDisabled: boolean | undefined;

    @Input() showToolBar: boolean | undefined;

    @Output() cancelClick = new EventEmitter();
    @Output() saveClick = new EventEmitter();

    constructor() {}

    onSaveClick() {
        this.saveClick.emit();
    }

    onCancelClick() {
        this.cancelClick.emit();
    }
}
