<header>
    <mat-card-title>Please Create a New Password</mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>
<div [formGroup]="passwordForm">
    <mat-form-field>
        <mat-label>New Password</mat-label>
        <input matInput type="password" formControlName="newPassword" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Confirm Password</mat-label>
        <input matInput type="password" formControlName="confirmPassword" />
    </mat-form-field>
    <ul>
        <li [class]="match ? 'valid' : 'invalid'">Passwords must match</li>
        <li [class]="oneNumber ? 'valid' : 'invalid'">At least one number</li>
        <li [class]="oneSpec ? 'valid' : 'invalid'">
            At least one Special Character
        </li>
        <li [class]="oneUpper ? 'valid' : 'invalid'">
            At least one Uppercase Character
        </li>
        <li [class]="oneLower ? 'valid' : 'invalid'">
            At least one Lowercase Character
        </li>
        <li [class]="minlength ? 'valid' : 'invalid'">Minimum Length 8</li>
        <br />
    </ul>
    <button
        mat-raised-button
        [mat-dialog-close]="newPassword"
        [disabled]="
            !(
                match &&
                oneNumber &&
                oneUpper &&
                oneLower &&
                minlength &&
                oneSpec
            )
        "
    >
        Create
    </button>
</div>
