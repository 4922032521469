import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cwe-spinner',
    templateUrl: './cwe-spinner.component.html',
    styleUrls: ['./cwe-spinner.component.css'],
})
export class CweSpinnerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
