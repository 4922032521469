<app-page-toolbar [pageTitle]="'Reports'"> </app-page-toolbar>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">analytics</mat-icon>
            Generate Report
        </mat-card-title>
    </mat-card-header>
    <div id="formDiv">
        <mat-label>Please select a report type:</mat-label>
        <mat-form-field appearance="outline">
            <mat-label>Report Type</mat-label>
            <mat-select [(value)]="reportType">
                <mat-option [value]="null">-</mat-option>
                <mat-option [value]="'boilers'">Boiler Servicing</mat-option>
                <mat-option [value]="'view'">View Properties</mat-option>
                <mat-option [value]="'sublet'">Sub Let</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-card>

<mat-card *ngIf="reportType === 'boilers'">
    <mat-label>Please select Unit Courts to view:</mat-label>
    <br />
    <mat-form-field appearance="outline">
        <mat-label>Unit Court</mat-label>
        <mat-select #boilerCourt [(value)]="courtSelected" multiple>
            <button
                (click)="selectAllNone(boilerCourt)"
                class="selectAll"
                mat-flat-button
            >
                <mat-icon>done</mat-icon>
                Select All / None
            </button>
            <mat-option *ngFor="let court of unitCourts" [value]="court">{{
                court
            }}</mat-option>
        </mat-select>
    </mat-form-field>
    <br />
    <mat-label>Please select:</mat-label>
    <mat-radio-group [(ngModel)]="overDueOrMonth">
        <mat-radio-button value="overdue">Expired Units</mat-radio-button>
        <mat-radio-button value="month">Units Due to Expire</mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="overDueOrMonth === 'month'" appearance="outline">
        <mat-label>Expiry Month</mat-label>
        <mat-select #boilerMonth [(ngModel)]="dateSelected" multiple>
            <button
                (click)="selectAllNone(boilerMonth)"
                class="selectAll"
                mat-flat-button
            >
                <mat-icon>done</mat-icon>
                Select All / None
            </button>
            <mat-option *ngFor="let month of monthsArray" [value]="month">{{
                month.toLocaleDateString('en-GB', {
                    month: 'long',
                    year: 'numeric'
                })
            }}</mat-option>
        </mat-select>
    </mat-form-field>

    <button
        (click)="generateBoilerReport()"
        [disabled]="
            !reportType ||
            !courtSelected ||
            courtSelected.length === 0 ||
            !overDueOrMonth ||
            (overDueOrMonth === 'month' &&
                (!dateSelected || dateSelected.length === 0))
        "
        mat-raised-button
    >
        Generate Report
    </button>
</mat-card>

<mat-card *ngIf="reportType === 'view'">
    <mat-label>Please select:</mat-label>
    <mat-radio-group [(ngModel)]="viewBy">
        <mat-radio-button value="all">View All Properties</mat-radio-button>
        <mat-radio-button value="court">View by Unit Court</mat-radio-button>
        <mat-radio-button value="house">View by Unit House</mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="viewBy === 'court'" appearance="outline">
        <mat-label>Unit Court</mat-label>
        <mat-select #viewCourt [(value)]="courtSelected" multiple>
            <button
                (click)="selectAllNone(viewCourt)"
                class="selectAll"
                mat-flat-button
            >
                <mat-icon>done</mat-icon>
                Select All / None
            </button>
            <mat-option *ngFor="let court of unitCourts" [value]="court">{{
                court
            }}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="viewBy === 'house'" appearance="outline">
        <mat-label>Unit House</mat-label>
        <mat-select #viewHouse [(value)]="houseSelected" multiple>
            <button
                (click)="selectAllNone(viewHouse)"
                class="selectAll"
                mat-flat-button
            >
                <mat-icon>done</mat-icon>
                Select All / None
            </button>
            <mat-option *ngFor="let house of unitHouses" [value]="house">{{
                house
            }}</mat-option>
        </mat-select>
    </mat-form-field>

    <button
        (click)="generateViewReport()"
        [disabled]="
            !viewBy ||
            (viewBy === 'court' &&
                (!courtSelected || courtSelected.length === 0)) ||
            (viewBy === 'house' &&
                (houseSelected?.length === 0 || !houseSelected))
        "
        mat-raised-button
    >
        Generate Report
    </button>
</mat-card>

<mat-card *ngIf="reportType === 'sublet'">
    <mat-label>Please select Unit Courts to view:</mat-label>
    <br />
    <mat-form-field appearance="outline">
        <mat-label>Unit Court</mat-label>
        <mat-select #letCourt [(value)]="courtSelected" multiple>
            <button
                (click)="selectAllNone(letCourt)"
                class="selectAll"
                mat-flat-button
            >
                <mat-icon>done</mat-icon>
                Select All / None
            </button>
            <mat-option *ngFor="let court of unitCourts" [value]="court">{{
                court
            }}</mat-option>
        </mat-select>
    </mat-form-field>
    <br />
    <mat-label>Please select:</mat-label>
    <mat-radio-group [(ngModel)]="overDueOrMonth">
        <mat-radio-button value="overdue">Expired Units</mat-radio-button>
        <mat-radio-button value="month">Units Due to Expire</mat-radio-button>
    </mat-radio-group>

    <mat-form-field *ngIf="overDueOrMonth === 'month'" appearance="outline">
        <mat-label>Expiry Month</mat-label>
        <mat-select #letMonth [(ngModel)]="dateSelected" multiple>
            <button
                (click)="selectAllNone(letMonth)"
                class="selectAll"
                mat-flat-button
            >
                <mat-icon>done</mat-icon>
                Select All / None
            </button>
            <mat-option *ngFor="let month of monthsArray" [value]="month">{{
                month.toLocaleDateString('en-GB', {
                    month: 'long',
                    year: 'numeric'
                })
            }}</mat-option>
        </mat-select>
    </mat-form-field>

    <button
        (click)="generateSubLetReport()"
        [disabled]="
            !reportType ||
            !courtSelected ||
            courtSelected.length === 0 ||
            !overDueOrMonth ||
            (overDueOrMonth === 'month' &&
                (!dateSelected || dateSelected.length === 0))
        "
        mat-raised-button
    >
        Generate Report
    </button>
</mat-card>

<footer>
    <mat-card id="lastUpdated">
        <button
            (click)="propertyService.getReportData(true)"
            [disabled]="propertyService.updating"
            id="refreshButton"
            mat-icon-button
        >
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-label slot="end"
            >Data last Updated:
            {{
                propertyService.updating
                    ? 'Updating...'
                    : (propertyService.lastUpdated
                      | date: 'dd-MM-yyyy, h:mm:ss a')
            }}</mat-label
        >
    </mat-card>
</footer>
