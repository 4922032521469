<app-page-toolbar [pageTitle]="'Budget / Expense Variance'"> </app-page-toolbar>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<mat-card *ngIf="selectedBudgets && !loading">
    <mat-card-header>
        <mat-label>Select Year:</mat-label>
        <mat-select
            [(ngModel)]="selectedYears"
            (ngModelChange)="updateSelected()"
        >
            <mat-option *ngFor="let year of budgetYears" [value]="year">{{
                year
            }}</mat-option>
        </mat-select>
    </mat-card-header>
    <div *ngIf="updating" class="loadingSpinner">
        <mat-spinner></mat-spinner>
    </div>
    <app-variance-chart
        *ngIf="!updating"
        [totalBudget]="budgetTotalNoRes"
        [budgetYear]="selectedYears"
        [workOrders]="filteredWorkOrders"
    >
    </app-variance-chart>
    <div *ngIf="!updating" class="listDiv">
        <mat-list dense>
            <mat-list-item>
                <div class="inputDiv">
                    <mat-label class="mat-body-strong catName"
                        >Categories</mat-label
                    >
                    <div class="moneyDiv">
                        <mat-label class="mat-body-strong">Budget</mat-label>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="moneyDiv">
                        <mat-label class="mat-body-strong">Expenses</mat-label>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="moneyDiv">
                        <mat-label class="mat-body-strong">Variance</mat-label>
                        <mat-divider></mat-divider>
                    </div>
                    <div class="moneyDiv">
                        <mat-label class="mat-body-strong">%</mat-label>
                        <mat-divider></mat-divider>
                    </div>
                </div>
            </mat-list-item>
            <mat-list-item *ngFor="let head of budgetHeads; index as i">
                <div class="inputDiv">
                    <mat-label class="catName">{{ head.name }}</mat-label>
                    <div class="moneyDiv">
                        <input
                            [options]="{ prefix: '£ ', align: 'center' }"
                            [value]="getModelValue(head, selectedBudgets)"
                            autocomplete="off"
                            class="inTheBlack"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [options]="{ prefix: '£ ', align: 'center' }"
                            [value]="expenses[head.code]"
                            autocomplete="off"
                            class="inTheBlack"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                getModelValue(head, selectedBudgets) -
                                    expenses[head.code] >=
                                0
                                    ? 'inTheGreen'
                                    : 'inTheRed'
                            "
                            [options]="{ prefix: '£ ', align: 'center' }"
                            [value]="
                                (
                                    getModelValue(head, selectedBudgets) -
                                    expenses[head.code]
                                ).toString()
                            "
                            autocomplete="off"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                getModelValue(head, selectedBudgets) -
                                    expenses[head.code] >=
                                0
                                    ? 'inTheGreen'
                                    : 'inTheRed'
                            "
                            [options]="{
                                prefix: '',
                                suffix: ' %',
                                align: 'center'
                            }"
                            [value]="
                                getPercentage(
                                    expenses[head.code],
                                    getModelValue(head, selectedBudgets)
                                )
                            "
                            autocomplete="off"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>

            <mat-list-item>
                <div class="inputDiv">
                    <mat-label class="mat-body-strong catName">Total</mat-label>
                    <div class="moneyDiv">
                        <input
                            [options]="{
                                prefix: '£ ',
                                allowNegative: false,
                                align: 'center'
                            }"
                            [value]="budgetTotalNoRes.toString()"
                            class="mat-body-strong inTheBlack"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [options]="{
                                prefix: '£ ',
                                allowNegative: false,
                                align: 'center'
                            }"
                            [value]="totalExpensesNoRes.toString()"
                            class="mat-body-strong inTheBlack"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                totalVarianceNoRes >= 0
                                    ? 'inTheGreen'
                                    : 'inTheRed'
                            "
                            [options]="{
                                prefix: '£ ',
                                allowNegative: false,
                                align: 'center'
                            }"
                            [value]="totalVarianceNoRes.toString()"
                            class="mat-body-strong"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                totalVarianceNoRes >= 0
                                    ? 'inTheGreen'
                                    : 'inTheRed'
                            "
                            [options]="{
                                prefix: '',
                                suffix: ' %',
                                align: 'center'
                            }"
                            [value]="totalPercentageNoRes.toString()"
                            class="mat-body-strong"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
            <mat-list-item></mat-list-item>
            <mat-list-item>
                <div class="inputDiv">
                    <mat-label class="catName">RESERVES</mat-label>
                    <div class="moneyDiv">
                        <input
                            [options]="{ prefix: '£ ', align: 'center' }"
                            [value]="getReservesValue(selectedBudgets)"
                            autocomplete="off"
                            class="inTheBlack"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [options]="{ prefix: '£ ', align: 'center' }"
                            [value]="expenses['RES'].toString()"
                            autocomplete="off"
                            class="inTheBlack"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                getReservesValue(selectedBudgets) -
                                    expenses['RES'] >=
                                0
                                    ? 'inTheGreen'
                                    : 'inTheRed'
                            "
                            [options]="{ prefix: '£ ', align: 'center' }"
                            [value]="
                                (
                                    getReservesValue(selectedBudgets) -
                                    expenses['RES']
                                ).toString()
                            "
                            autocomplete="off"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                getReservesValue(selectedBudgets) -
                                    expenses['RES'] >=
                                0
                                    ? 'inTheGreen'
                                    : 'inTheRed'
                            "
                            [options]="{
                                prefix: '',
                                suffix: ' %',
                                align: 'center'
                            }"
                            [value]="
                                getPercentage(
                                    expenses['RES'],
                                    getReservesValue(selectedBudgets)
                                )
                            "
                            autocomplete="off"
                            currencyMask
                            disabled
                            matInput
                            placeholder="0.00"
                        />
                    </div>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
            <mat-list-item></mat-list-item>
            <mat-list-item>
                <div class="inputDiv">
                    <mat-label class="mat-body-strong catName"
                        >Grand Total</mat-label
                    >
                    <div class="moneyDiv">
                        <input
                            [options]="{
                                prefix: '£ ',
                                allowNegative: false,
                                align: 'center'
                            }"
                            [value]="totalBudget.toString()"
                            class="mat-body-strong inTheBlack"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [options]="{
                                prefix: '£ ',
                                allowNegative: false,
                                align: 'center'
                            }"
                            [value]="totalExpenses.toString()"
                            class="mat-body-strong inTheBlack"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                totalVariance >= 0 ? 'inTheGreen' : 'inTheRed'
                            "
                            [options]="{
                                prefix: '£ ',
                                allowNegative: false,
                                align: 'center'
                            }"
                            [value]="totalVariance.toString()"
                            class="mat-body-strong"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                    <div class="moneyDiv">
                        <input
                            [ngClass]="
                                totalVariance >= 0 ? 'inTheGreen' : 'inTheRed'
                            "
                            [options]="{
                                prefix: '',
                                suffix: ' %',
                                align: 'center'
                            }"
                            [value]="totalPercentage.toString()"
                            class="mat-body-strong"
                            currencyMask
                            disabled
                            matInput
                        />
                    </div>
                </div>
                <mat-divider></mat-divider>
            </mat-list-item>
        </mat-list>
    </div>
</mat-card>
