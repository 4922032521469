<app-page-toolbar [backButtonRoute]="'/home-page/reports'"> </app-page-toolbar>

<mat-card class="reportToolbar">
    <button
        (click)="exportReport()"
        [disabled]="dataSource.filteredData.length === 0"
        mat-button
    >
        Export
    </button>
    <button
        [cdkCopyToClipboard]="getClipboardData()"
        [disabled]="dataSource.filteredData.length === 0"
        mat-button
    >
        Copy All Emails
    </button>
    <mat-label id="reportLabel" slot="end"
        >Results: {{ dataSource.filteredData.length }}</mat-label
    >
</mat-card>

<mat-card id="tableContainer">
    <table [dataSource]="dataSource" mat-table>
        <ng-container matColumnDef="unitFull">
            <th *matHeaderCellDef mat-header-cell>Unit Full</th>
            <td *matCellDef="let property" mat-cell>
                {{ property['unitFull'] }}
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>Name</th>
            <td *matCellDef="let property" mat-cell>
                {{ property['ownerDetails'].name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="phone">
            <th *matHeaderCellDef mat-header-cell>Phone</th>
            <td *matCellDef="let property" mat-cell>
                {{ property['ownerDetails']['phone'] }}
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th *matHeaderCellDef mat-header-cell>E-Mail</th>
            <td *matCellDef="let property" mat-cell>
                {{ property['ownerDetails']['email1'] }}
            </td>
        </ng-container>

        <ng-container
            *ngIf="
                propertyService?.reportDisplayColumns?.includes('lastServiced')
            "
            matColumnDef="lastServiced"
        >
            <th *matHeaderCellDef mat-header-cell>Last Serviced</th>
            <td *matCellDef="let property" mat-cell>
                {{
                    property['boilerDetails']['lastServiced']
                        | date: 'dd/MM/yyyy'
                }}
            </td>
        </ng-container>

        <ng-container
            *ngIf="
                propertyService?.reportDisplayColumns?.includes('subLetExpiry')
            "
            matColumnDef="subLetExpiry"
        >
            <th *matHeaderCellDef mat-header-cell>Sub Let Expiry</th>
            <td *matCellDef="let property" mat-cell>
                {{
                    property['subLetDetails']['subLetExpiry']
                        | date: 'dd/MM/yyyy'
                }}
            </td>
        </ng-container>

        <tr
            *matHeaderRowDef="
                propertyService.reportDisplayColumns;
                sticky: true
            "
            mat-header-row
        ></tr>
        <tr
            (dblclick)="clickedRow(row)"
            *matRowDef="let row; columns: propertyService.reportDisplayColumns"
            disabled="true"
            mat-row
        ></tr>
    </table>
    <mat-paginator
        [pageSizeOptions]="[20, 50, 100]"
        aria-label="Select page"
        showFirstLastButtons
    >
    </mat-paginator>
</mat-card>
