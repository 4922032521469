<app-page-toolbar [pageTitle]="'Property Search'"> </app-page-toolbar>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">apartment</mat-icon>
            Select a Property
        </mat-card-title>
    </mat-card-header>
    <form [formGroup]="searchForm" class="selectForm">
        <mat-label>Please select a Unit Court or House:</mat-label>
        <br />
        <mat-form-field appearance="outline">
            <mat-label>Unit Court</mat-label>
            <mat-select formControlName="unitCourt">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let court of unitCourts" [value]="court">{{
                    court
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Unit House</mat-label>
            <mat-select formControlName="unitHouse">
                <mat-option>-</mat-option>
                <mat-option *ngFor="let house of unitHouses" [value]="house">{{
                    house
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-label>Then, the Unit Number:</mat-label>
        <br />

        <mat-form-field appearance="outline">
            <mat-label>Unit Number</mat-label>
            <mat-select formControlName="unit">
                <mat-option
                    *ngFor="let number of unitNumbers"
                    [value]="number"
                    >{{ number }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <button
            (click)="submitViewProperty()"
            [disabled]="searchForm.controls['unit'].value === null || loading"
            mat-raised-button
        >
            View Property
        </button>
    </form>
</mat-card>

<mat-card class="zeroPadding">
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">apartment</mat-icon>
            Property Search
        </mat-card-title>
    </mat-card-header>
    <mat-label>Please enter a minimum of 3 characters:</mat-label>

    <div id="search">
        <div id="searchFields">
            <mat-form-field appearance="outline">
                <mat-label>Field</mat-label>
                <mat-select [(ngModel)]="searchField">
                    <mat-option value="leaseholder">Leaseholder</mat-option>
                    <mat-option value="tenant">Tenant</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                (keydown.enter)="getSearchResults(searchName, searchField)"
                appearance="outline"
            >
                <mat-label>Search Value</mat-label>
                <input [(ngModel)]="searchName" matInput />
            </mat-form-field>
        </div>

        <button
            (click)="getSearchResults(searchName, searchField)"
            [disabled]="
                !searchName || searching || disableList || searchName.length < 3
            "
            mat-raised-button
        >
            Search
        </button>

        <mat-progress-bar
            *ngIf="searching"
            mode="indeterminate"
        ></mat-progress-bar>
        <mat-label *ngIf="searchResults" id="resultsLength"
            >Results: {{ searchResults.length }}</mat-label
        >

        <mat-action-list [disabled]="disableList" dense>
            <button
                (click)="submitViewProperty(result?.unitFull)"
                *ngFor="let result of searchResults"
                class="searchResultButton"
                mat-list-item
            >
                <mat-icon>person</mat-icon>
                <div class="resultList">
                    {{
                        showSearchField === 'leaseholder'
                            ? result?.ownerDetails?.name
                            : result?.subLetDetails?.occupierDetails
                                  ?.occupierName
                    }}
                </div>
                <mat-icon>apartment</mat-icon>
                <div class="resultList">{{ result?.unitFull }}</div>
                <mat-divider></mat-divider>
            </button>
        </mat-action-list>
    </div>
</mat-card>

<app-cwe-spinner *ngIf="loading || disableList"></app-cwe-spinner>
