import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { PropertyDetail, Transaction } from '../../../../interfaces/property';
import { PropertyDetailsService } from '../../../../services/property-details/property-details.service';
import { isSameDay, isWithinInterval, lightFormat } from 'date-fns';
import { ExportService } from '../../../../services/export/export.service';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { NGXLogger } from 'ngx-logger';
import { AuthServiceService } from '../../../../services/auth-service/auth-service.service';

@Component({
    selector: 'app-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.css'],
})
export class PaymentsComponent implements OnInit {
    loading: boolean = false;
    startDate!: Date;
    endDate!: Date;
    allUnits!: (Transaction & { unitFull: string })[];
    displayColumns = ['date', 'description', 'amount', 'unitFull'];
    filteredData!: (Transaction & { unitFull: string })[];
    dataSource!: MatTableDataSource<any>;
    totalIncome!: number;
    fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';

    constructor(
        public route: ActivatedRoute,
        public propertyDetails: PropertyDetailsService,
        public exportService: ExportService,
        public logger: NGXLogger,
        public auth: AuthServiceService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;

        const queryParams = await firstValueFrom(this.route.queryParams);
        this.allUnits = await firstValueFrom(
            this.propertyDetails.getAllPayments()
        );

        if (queryParams?.['startDate'] && queryParams?.['endDate']) {
            this.startDate = new Date(+queryParams['startDate']);
            this.endDate = new Date(+queryParams['endDate']);

            this.filteredData = this.allUnits
                .filter(
                    (trans) =>
                        !!trans &&
                        isWithinInterval(new Date(trans.date), {
                            start: this.startDate,
                            end: this.endDate,
                        })
                )
                .sort(
                    (a: any, b: any) =>
                        new Date(b!.date).getTime() -
                        new Date(a!.date).getTime()
                );

            this.logger.log(
                `${await this.auth.getUserName()}: Generated Payment list`
            );

            this.totalIncome = +this.filteredData
                .filter(
                    (trans: Transaction | undefined) =>
                        !!trans &&
                        ['Payment', 'Refund Payment'].includes(
                            trans.transactionType
                        )
                )
                .reduce(
                    (total, trans) =>
                        trans?.transactionType === 'Payment'
                            ? total + trans?.amount
                            : total - (trans?.amount ?? 0),
                    0
                )
                .toFixed(2);
            this.dataSource = new MatTableDataSource<any>(this.filteredData);
        }

        this.loading = false;
    }

    exportReport() {
        const formattedData: Partial<PropertyDetail>[] = this.filteredData.map(
            (row) => {
                return {
                    date: lightFormat(new Date(row!.date), 'dd/MM/yyyy'),
                    description: row?.description,
                    amount: row?.amount,
                    unitFull: row?.unitFull,
                };
            }
        );

        const workSheet: XLSX.WorkSheet =
            XLSX.utils.json_to_sheet(formattedData);
        const workBook: XLSX.WorkBook = {
            Sheets: { data: workSheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workBook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data: Blob = new Blob([excelBuffer], { type: this.fileType });
        saveAs(
            data,
            `CWE_paymentList_${lightFormat(this.startDate, 'dd-MM-yyyy')}_${lightFormat(this.endDate, 'dd-MM-yyyy')}` +
                this.fileExtension
        );
    }
}
