<app-page-toolbar [pageTitle]="'Income'"> </app-page-toolbar>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<div *ngIf="!loading">
    <mat-card>
        <mat-card-header class="selectHeader">
            <mat-label>Select Year:</mat-label>
            <mat-select
                [(ngModel)]="selectedYears"
                (ngModelChange)="updateSelectedBudgets()"
            >
                <mat-option *ngFor="let year of budgetYears" [value]="year">{{
                    year
                }}</mat-option>
            </mat-select>
        </mat-card-header>

        <mat-card-content>
            <app-income-chart
                *ngIf="true"
                [budgetYear]="selectedYears"
                [allUnits]="allUnits"
                [totalExp]="+totalExp"
            ></app-income-chart>

            <div
                class="listDiv"
                *ngIf="selectedBudgets && !loading && !updating"
            >
                <mat-list dense>
                    <mat-list-item>
                        <div class="inputDiv" *ngIf="selectedBudgets">
                            <mat-label class="catName"
                                >Expected Service Charge Income</mat-label
                            >

                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class=""
                                matInput
                                disabled
                                [value]="totalService"
                            />
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div class="inputDiv" *ngIf="selectedBudgets">
                            <mat-label class="catName"
                                >Expected Boiler Scheme Income</mat-label
                            >

                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class=""
                                matInput
                                disabled
                                [value]="totalBoiler"
                            />
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div class="inputDiv" *ngIf="selectedBudgets">
                            <mat-label class="mat-body-strong catName"
                                >Total Expected Income</mat-label
                            >

                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [value]="totalExp"
                            />
                            <mat-divider></mat-divider>
                        </div>
                    </mat-list-item>
                    <mat-list-item></mat-list-item>

                    <mat-list-item>
                        <div class="inputDiv">
                            <mat-label class="mat-body-strong catName"
                                >Total Income Received</mat-label
                            >

                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [value]="totalRec"
                            />
                            <mat-divider></mat-divider>
                        </div>
                    </mat-list-item>
                    <mat-list-item></mat-list-item>
                    <mat-list-item>
                        <div class="inputDiv">
                            <mat-label class="mat-body-strong catName"
                                >Total Outstanding</mat-label
                            >

                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: true
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [value]="totalOut"
                            />
                            <mat-divider></mat-divider>
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-card-content>
    </mat-card>
</div>
