import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import { PropertyDetail } from '../../../interfaces/property';
import { lightFormat } from 'date-fns';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { firstValueFrom } from 'rxjs';
import { StorageKeys } from '../../../enums/storage';
import { Router } from '@angular/router';
import { InfoModalComponent } from '../../../components/info-modal/info-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Location } from '@angular/common';
import { ExportService } from '../../../services/export/export.service';

@Component({
    selector: 'app-report-viewer',
    templateUrl: './report-viewer.component.html',
    styleUrls: ['./report-viewer.component.css'],
})
export class ReportViewerComponent implements OnInit, AfterViewInit {
    reportDetails!: PropertyDetail[];

    lightFormat = lightFormat;
    dataSource = new MatTableDataSource<PropertyDetail>(
        this.propertyService.reportFilteredData
    );

    @ViewChild(MatPaginator) paginator!: MatPaginator;

    constructor(
        public propertyService: PropertyDetailsService,
        public router: Router,
        public dialog: MatDialog,
        public location: Location,
        public exportService: ExportService
    ) {}

    ngOnInit(): void {
        this.reportDetails = this.propertyService.reportFilteredData || [];
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
    }

    async clickedRow(property: PropertyDetail) {
        try {
            let propertyDetails: PropertyDetail = await firstValueFrom(
                this.propertyService.callPropertyEndpoint(property.unitFull)
            );
            let propertyFiles: string[] = await firstValueFrom(
                await this.propertyService.getFileList(property.unitFull)
            );

            if (propertyDetails) {
                localStorage.setItem(
                    StorageKeys.PropertyDetail,
                    JSON.stringify(propertyDetails)
                );
                propertyFiles
                    ? localStorage.setItem(
                          StorageKeys.PropertyFiles,
                          JSON.stringify(propertyFiles)
                      )
                    : localStorage.removeItem(StorageKeys.PropertyFiles);
                return this.router.navigateByUrl(
                    'home-page/property-results?from=reports'
                );
            }
        } catch (error: any) {
            console.error(error);
            return this.openInfoDialog('Error connecting to Database');
        }
    }

    openInfoDialog = (message: string) => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    exportReport() {
        this.exportService.exportReportData(this.dataSource.filteredData);
    }

    getClipboardData(): string {
        // return this.dataSource.filteredData.map(item => item?.ownerDetails?.email1).toString()
        return this.dataSource.filteredData
            .map((item) => item?.ownerDetails?.email1)
            .join('; ');
    }
}
