import { Component, Inject } from '@angular/core';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { firstValueFrom } from 'rxjs';
import { ContractorsService } from '../../services/contractors/contractors.service';

@Component({
    selector: 'app-contractor-file-upload',
    templateUrl: './contractor-file-upload.component.html',
    styleUrls: ['./contractor-file-upload.component.css'],
})
export class ContractorFileUploadComponent {
    uploading: boolean = false;
    progress: number = 0;
    upload$!: any;
    response: HttpResponse<any> | undefined;
    fileExists: boolean = false;
    documentTypes: string[] = [
        'Ladders & Harnesses',
        'Equal Opportunities Policy',
        'Construction Industry Scheme',
        'Environmental Policy',
        'Heath and Safety Policy',
        'Risk Assessment Policy',
        'Lone Workers Policy',
        'NICEIC Certificate',
        `Employer's Liability Insurance`,
        'Public Liability Insurance',
        'BAFE Certificate',
        'Other',
    ];
    fileType!: string;

    constructor(
        public dialogRef: MatDialogRef<ContractorFileUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public contractorService: ContractorsService,
        public propertyService: PropertyDetailsService
    ) {}

    /**
     * Upload File to AWS, If file does not already exist with name
     *
     * @param file
     * @param fileType
     */
    uploadFile = async (file: File, fileType: string): Promise<any> => {
        this.uploading = true;
        const url = await firstValueFrom(
            this.contractorService.getPresignedUrl(
                this.data.applicationId.toString() +
                    '/' +
                    fileType +
                    '@$%&' +
                    file.name,
                'Put'
            )
        );

        return (this.upload$ = this.propertyService
            .fileUpload(url, '', file)
            .subscribe(async (event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const eventTotal: number = event.total
                            ? event.total
                            : 0;
                        this.progress = Math.round(
                            (event.loaded / eventTotal) * 100
                        );
                        break;

                    case HttpEventType.Response:
                        this.uploading = false;
                        this.progress = 0;
                        this.data.file = null;
                        this.response = event;
                        break;
                }
            }));
    };

    /**
     * Cancel an upload in progress
     */
    cancelUpload = (): void => {
        if (this.upload$) this.upload$.unsubscribe();
    };

    isCorrectType = (fileTypes: string[], file: File): boolean => {
        return fileTypes.includes(file.type);
    };
}
