<app-page-toolbar [pageTitle]="'Finance Reports'"></app-page-toolbar>

<mat-card>
  <mat-card-header>
    <mat-card-title>
      <mat-icon slot="start">analytics</mat-icon>
      Generate Finance Report
    </mat-card-title>
  </mat-card-header>
  <div id="formDiv">
    <mat-label>Please select a report type:</mat-label>
    <mat-form-field appearance="outline">
      <mat-label>Report Type</mat-label>
      <mat-select [(value)]="reportType">
        <mat-option [value]="null">-</mat-option>
        <mat-option [value]="'payment'"
        >Work Orders Awaiting Payment
        </mat-option
        >
        <mat-option [value]="'statement'"
        >Statement of Unit Account
        </mat-option
        >
        <mat-option [value]="'income'">Payment List</mat-option>
        <mat-option [value]="'debtors'">Debtors List</mat-option>
        <mat-option [value]="'workorder'">Work Order Export</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card>

<mat-card *ngIf="reportType === 'payment'">
  <button mat-raised-button routerLink="/home-page/invoice-payments">
    Generate Report
  </button>
</mat-card>

<mat-card *ngIf="reportType === 'debtors'">
  <button mat-raised-button routerLink="/home-page/debtors">
    Generate Report
  </button>
</mat-card>

<mat-card *ngIf="reportType === 'statement'">
  <form [formGroup]="searchForm" class="selectForm">
    <mat-label>Please select a Unit Court:</mat-label>
    <br />
    <mat-form-field appearance="outline">
      <mat-label>Unit Court</mat-label>
      <mat-select formControlName="unitCourt">
        <mat-option>-</mat-option>
        <mat-option *ngFor="let court of unitCourts" [value]="court">{{
            court
          }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label>Then, the Unit Number:</mat-label>
    <br />

    <mat-form-field appearance="outline">
      <mat-label>Unit Number</mat-label>
      <mat-select formControlName="unit">
        <mat-option
          *ngFor="let number of unitNumbers"
          [value]="number"
        >{{ number }}
        </mat-option
        >
      </mat-select>
    </mat-form-field>

    <mat-label>Select the Dates:</mat-label>

    <mat-form-field appearance="outline" (click)="startPicker.open()">
      <mat-label>Start Date</mat-label>

      <input
        [matDatepicker]="startPicker"
        formControlName="startDate"
        matInput
        placeholder="DD/MM/YYYY"
        readonly
      />
      <mat-datepicker-toggle
        [for]="startPicker"
        matSuffix
      ></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" (click)="endPicker.open()">
      <mat-label>End Date</mat-label>

      <input
        [matDatepicker]="endPicker"
        formControlName="endDate"
        matInput
        placeholder="DD/MM/YYYY"
        readonly
      />
      <mat-datepicker-toggle
        [for]="startPicker"
        matSuffix
      ></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
  </form>
  <small *ngIf="errorMessage">{{ errorMessage }}</small>
  <button
    mat-raised-button
    [disabled]="searchForm.invalid || loading"
    (click)="generateStatement()"
  >
    {{ loading ? 'Generating...' : 'Generate Report' }}
  </button>
</mat-card>

<mat-card *ngIf="reportType === 'income'">
  <form [formGroup]="paymentsForm" class="selectForm">
    <mat-label>Select the Dates:</mat-label>

    <mat-form-field appearance="outline" (click)="startPicker.open()">
      <mat-label>Start Date</mat-label>

      <input
        [matDatepicker]="startPicker"
        formControlName="startDate"
        matInput
        placeholder="DD/MM/YYYY"
        readonly
      />
      <mat-datepicker-toggle
        [for]="startPicker"
        matSuffix
      ></mat-datepicker-toggle>
      <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" (click)="endPicker.open()">
      <mat-label>End Date</mat-label>

      <input
        [matDatepicker]="endPicker"
        formControlName="endDate"
        matInput
        placeholder="DD/MM/YYYY"
        readonly
      />
      <mat-datepicker-toggle
        [for]="startPicker"
        matSuffix
      ></mat-datepicker-toggle>
      <mat-datepicker #endPicker></mat-datepicker>
    </mat-form-field>
  </form>
  <small *ngIf="errorMessage">{{ errorMessage }}</small>
  <button
    mat-raised-button
    [disabled]="paymentsForm.invalid || loading"
    (click)="generateIncomeList()"
  >
    {{ loading ? 'Generating...' : 'Generate Report' }}
  </button>
</mat-card>

<mat-card *ngIf="reportType === 'workorder'">
  <mat-label>Please select financial year:</mat-label>
  <mat-form-field appearance="outline">
    <mat-label>Financial Year</mat-label>
    <mat-select [(value)]="financeYear">
      <mat-option *ngFor="let year of budgetYears" [value]="year">{{ year }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-label>Select statuses to export:</mat-label>
  <mat-selection-list #statuses dense>
    <mat-list-option [value]="WorkOrderStatus.AwaitingPayment">{{ WorkOrderStatus.AwaitingPayment }}
    </mat-list-option>
    <mat-list-option [value]="WorkOrderStatus.Paid">{{ WorkOrderStatus.Paid }}
    </mat-list-option>
  </mat-selection-list>


  <button mat-raised-button [disabled]="statuses.selectedOptions.selected.length === 0 || exporting"
          (click)="generateWorkOrderDump(statuses.selectedOptions.selected, financeYear)">
    {{ exporting ? 'Exporting...' : 'Export Work Orders' }}
  </button>
</mat-card>
