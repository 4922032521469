import { Injectable } from '@angular/core';
import { PropertyDetail } from '../../interfaces/property';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { lightFormat } from 'date-fns';
import { WorkOrder } from '../../pages/work-orders/work-orders.component';

@Injectable({
    providedIn: 'root',
})
export class ExportService {
    fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    fileExtension = '.xlsx';

    constructor() {}

    exportReportData(reportData: Partial<PropertyDetail>[]) {
        const formattedData: Partial<PropertyDetail>[] = reportData.map(
            (row) => {
                delete row.audits;
                delete row.ownership;
                return {
                    unitType: row.unitType,
                    unitFull: row.unitFull,
                    unitCourt: row.unitCourt,
                    unitHouse: row.unitHouse,
                    unitRoad: row.unitRoad,
                    unitPostCode: row.unitPostCode,
                    unitCity: row.unitCity,
                    unit: row.unit,
                    carParkSpace: row.carParkSpace,
                    ...row.boilerDetails,
                    lastServiced: row.boilerDetails?.lastServiced
                        ? lightFormat(
                              new Date(row.boilerDetails?.lastServiced),
                              'dd/MM/yyyy'
                          )
                        : null,
                    ...row.ownerDetails,
                    ...row.rtmDetails,
                    ...row.subLetDetails?.occupierDetails,
                    ...row.subLetDetails?.agentDetails,
                    ...row.subLetDetails?.petConsent,
                    subLetExpiry: row.subLetDetails?.subLetExpiry
                        ? lightFormat(
                              new Date(row.subLetDetails?.subLetExpiry),
                              'dd/MM/yyyy'
                          )
                        : null,
                };
            }
        );

        const workSheet: XLSX.WorkSheet =
            XLSX.utils.json_to_sheet(formattedData);
        const workBook: XLSX.WorkBook = {
            Sheets: { data: workSheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workBook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data: Blob = new Blob([excelBuffer], { type: this.fileType });
        saveAs(data, 'CWE-Report' + this.fileExtension);
    }

    exportWorkOrderData(reportData: WorkOrder[], budgetYear: string) {
        const workSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(reportData);
        const workBook: XLSX.WorkBook = {
            Sheets: { data: workSheet },
            SheetNames: ['data'],
        };
        const excelBuffer: any = XLSX.write(workBook, {
            bookType: 'xlsx',
            type: 'array',
        });
        const data: Blob = new Blob([excelBuffer], { type: this.fileType });
        saveAs(data, `workorders_${budgetYear}${this.fileExtension}`);
    }
}
