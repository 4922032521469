<header>
    <mat-card-title>
        <mat-icon>notes</mat-icon>
        Generate Statement of Account
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<div [formGroup]="accountForm" class="formDiv">
    <mat-label>Please select a date range</mat-label>
    <br />
    <mat-form-field appearance="outline">
        <mat-label>Start Date </mat-label>
        <input
            matInput
            [matDatepicker]="startPicker"
            placeholder="DD/MM/YYYY"
            [formControlName]="'startDate'"
            (click)="startPicker.open()"
            readonly
        />
        <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #startPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>End Date </mat-label>
        <input
            matInput
            [matDatepicker]="picker"
            placeholder="DD/MM/YYYY"
            [formControlName]="'endDate'"
            (click)="picker.open()"
            readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <button mat-raised-button [disabled]="accountForm.invalid">
        Generate Statement
    </button>
</div>
