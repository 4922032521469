export enum WorkOrderStatus {
    Open = 'Open',
    Closed = 'Closed',
    AwaitingAuth = 'Awaiting Authorisation',
    InvoiceApproval = 'Awaiting Invoice Approval',
    AwaitingPayment = 'Awaiting Payment',
    Paid = 'Invoice Paid',
}

export enum WorkOrderEvents {
    Created = 'Work Order Created',
    RetroCreated = 'Retrospective Work Order Created',
    Authorised = 'Work Order Authorised',
    InvoiceUploaded = 'Invoice Uploaded',
    InvoiceApproved = 'Invoice Approved',
    InvoicePaid = 'Invoice Paid',
    Closed = 'Work Order Closed',
    Amended = 'Work Order Amended',
}
