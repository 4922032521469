import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-account-modal',
    templateUrl: './account-modal.component.html',
    styleUrls: ['./account-modal.component.css'],
})
export class AccountModalComponent implements OnInit {
    accountForm: FormGroup = new FormGroup({});

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit(): void {
        this.accountForm.addControl(
            'startDate',
            new FormControl(
                {
                    value: new Date(),
                    disabled: false,
                },
                { validators: Validators.required }
            )
        );
        this.accountForm.addControl(
            'endDate',
            new FormControl(
                {
                    value: new Date(),
                    disabled: false,
                },
                { validators: Validators.required }
            )
        );
    }
}
