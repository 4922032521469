<app-page-toolbar [backButtonRoute]="'/home-page/finance-reports'">
</app-page-toolbar>

<mat-card class="reportToolbar" *ngIf="dataSource?.data">
    <button
        (click)="exportReport()"
        [disabled]="dataSource.data.length === 0"
        mat-button
    >
        Export
    </button>
</mat-card>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<mat-card *ngIf="!loading">
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">analytics</mat-icon>
            Debtors List
        </mat-card-title>
    </mat-card-header>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            Filter Options
        </mat-expansion-panel-header>
        <mat-label>Min Percentage ({{ filterMin }} %)</mat-label>
        <div class="filterOpts">
            <mat-label>{{ minPercent }} %</mat-label>
            <mat-slider
                min="{{ minPercent }}"
                max="{{ maxPercent }}"
                step="1"
                [(ngModel)]="filterMin"
                thumbLabel
            ></mat-slider>
            <mat-label>{{ maxPercent }} %</mat-label>
        </div>
        <mat-label>Max Percentage ({{ filterMax }} %)</mat-label>
        <div class="filterOpts">
            <mat-label>{{ minPercent }} %</mat-label>
            <mat-slider
                min="{{ minPercent }}"
                max="{{ maxPercent }}"
                step="1"
                [(ngModel)]="filterMax"
                thumbLabel
            ></mat-slider>
            <mat-label>{{ maxPercent }} %</mat-label>
        </div>

        <div class="filterButs">
            <button mat-raised-button (click)="resetData()">Reset</button>
            <button mat-raised-button (click)="filterData()">Filter</button>
        </div>
    </mat-expansion-panel>
    <br />
    <mat-label>Results: {{ dataSource.filteredData.length }}</mat-label>

    <br />
    <table #table [dataSource]="dataSource" mat-table>
        <ng-container matColumnDef="unitFull">
            <th *matHeaderCellDef mat-header-cell>Unit</th>
            <td *matCellDef="let trans" mat-cell>{{ trans['unitFull'] }}</td>
        </ng-container>

        <ng-container matColumnDef="unitName">
            <th *matHeaderCellDef mat-header-cell>Name</th>
            <td *matCellDef="let trans" mat-cell>{{ trans['ownersName'] }}</td>
        </ng-container>

        <ng-container matColumnDef="balance">
            <th *matHeaderCellDef mat-header-cell>Balance</th>
            <td *matCellDef="let trans" mat-cell>
                {{ trans['balance'] | currency: '£ ' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="charge">
            <th *matHeaderCellDef mat-header-cell>Service Charge</th>
            <td *matCellDef="let trans" mat-cell>
                {{ trans['serviceCharge'] | currency: '£ ' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="percentage">
            <th *matHeaderCellDef mat-header-cell>Remaining</th>
            <td *matCellDef="let trans" mat-cell>
                {{ trans['percent'] | percent }}
            </td>
        </ng-container>

        <tr *matHeaderRowDef="displayColumns; sticky: true" mat-header-row></tr>
        <tr
            *matRowDef="let row; columns: displayColumns"
            disabled="true"
            mat-row
        ></tr>
    </table>
</mat-card>
