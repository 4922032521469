<mat-toolbar xmlns="http://www.w3.org/1999/html">
  <button
    *ngIf="backButtonRoute"
    mat-button
    routerLink="{{ backButtonRoute }}"
  >
    <mat-icon>arrow_back_ios</mat-icon>
    <mat-label>Back</mat-label>
  </button>
  <mat-label *ngIf="pageTitle" class="pageName">{{ pageTitle }}</mat-label>
  <div id="versions">
    <small *ngIf="appVersion" slot="end">
      <mat-icon>computer</mat-icon>
      v{{ appVersion }}</small>
    <small *ngIf="apiVersion" slot="end">
      <mat-icon>storage</mat-icon>
      v{{ apiVersion }}</small>
    <small *ngIf="environment" slot="end">
      <mat-icon>bug_report</mat-icon>
      {{ environment }}</small>
  </div>

</mat-toolbar>
