import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ContractorsService } from '../../services/contractors/contractors.service';
import { firstValueFrom } from 'rxjs';
import { ContractorDetails } from '../contractor-sign-up/contractor-sign-up.component';
import { NotificationService } from '../../services/notification/notification.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InviteComponentComponent } from '../../components/invite-component/invite-component.component';
import { NoAccountContractorComponent } from '../../components/no-account-contractor/no-account-contractor.component';

@Component({
    selector: 'app-contractors',
    templateUrl: './contractors.component.html',
    styleUrls: ['./contractors.component.css'],
})
export class ContractorsComponent implements OnInit {
    contractors!: Partial<ContractorDetails>[];
    filterString!: string;
    approvedContractors!: Partial<ContractorDetails>[];
    unApprovedContractors!: Partial<ContractorDetails>[];
    loading: boolean = true;

    constructor(
        public router: Router,
        public contractorService: ContractorsService,
        public notifications: NotificationService,
        public dialog: MatDialog
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        this.notifications.triggerApprovals();
        this.contractors =
            (await firstValueFrom(
                await this.contractorService.getContractorList()
            )) || [];
        this.approvedContractors = this.contractors
            .filter((contractor) => contractor.status === 'verified')
            .sort((a, b) => a!.companyName!.localeCompare(b!.companyName!));

        this.unApprovedContractors = this.contractors
            .filter((contractor) => contractor.status === 'unverified')
            .sort((a, b) => a!.companyName!.localeCompare(b!.companyName!));

        this.loading = false;
    }

    filterContractors(): void {
        this.approvedContractors = this.contractors
            .filter((contractor) => contractor.status === 'verified')
            .filter((contractor) =>
                contractor!
                    .companyName!.toLowerCase()
                    .includes(this.filterString?.toLowerCase() || '')
            )
            .sort((a, b) => a!.companyName!.localeCompare(b!.companyName!));
    }

    async navigateToContractor(contractor: string) {
        await this.router.navigateByUrl('/home-page/view-contractor', {
            state: { companyName: contractor },
        });
    }

    inviteContractor() {
        const dialogRef = this.dialog.open(InviteComponentComponent, {
            width: '500px',
            data: {},
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
            }
        });
    }

    addContractor() {
        const dialogRef = this.dialog.open(NoAccountContractorComponent, {
            width: '500px',
            data: {},
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await this.ngOnInit();
            }
        });
    }
}
