import { Component, OnInit } from '@angular/core';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { PropertyDetail } from '../../interfaces/property';
import { PropertyFilter } from '../../enums/property';
import { addMonths, endOfMonth, isSameMonth } from 'date-fns';
import { Router } from '@angular/router';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
    reportType: string | undefined;
    reportData!: PropertyDetail[];
    unitCourts!: Set<string>;
    unitHouses!: Set<string>;
    overDueOrMonth!: string;
    monthsArray!: Date[];
    courtSelected: string[] | undefined;
    houseSelected: string[] | undefined;
    dateSelected: Date[] | undefined;
    viewBy!: string;

    constructor(
        public propertyService: PropertyDetailsService,
        public router: Router
    ) {}

    async ngOnInit() {
        this.unitCourts = await this.propertyService.getUnitFilters(
            PropertyFilter.UnitCourt
        );
        this.unitHouses = await this.propertyService.getUnitFilters(
            PropertyFilter.UnitHouse
        );
        this.reportData = (await this.propertyService.getReportData()) || [];
        this.populateMonthSelect();
    }

    populateMonthSelect() {
        const today: Date = new Date();
        const currentMonth: number = today.getMonth();
        const months: any[] = [];

        for (let i = 0; i < 12; i++) {
            months.push(endOfMonth(addMonths(new Date(today), i)));
        }
        this.monthsArray = months;
    }

    async generateBoilerReport() {
        let filteredData = this.reportData.filter((property) =>
            this.courtSelected?.includes(property.unitCourt)
        );

        switch (this.overDueOrMonth) {
            case 'overdue':
                const oneYearAgo: Date = new Date();
                oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
                const yearAgoString: string = oneYearAgo.toISOString();
                filteredData = filteredData.filter(
                    (property) =>
                        property?.boilerDetails?.lastServiced < yearAgoString ||
                        property?.boilerDetails?.lastServiced === null
                );
                break;

            case 'month':
                filteredData = filteredData.filter((property) => {
                    if (!property?.boilerDetails?.lastServiced) return false;
                    const expiryDate = new Date(
                        property.boilerDetails.lastServiced
                    );
                    expiryDate.setFullYear(expiryDate.getFullYear() + 1);

                    return this.dateSelected?.some((month) => {
                        return isSameMonth(
                            new Date(expiryDate),
                            new Date(month)
                        );
                    });
                });
                break;
        }

        this.propertyService.reportDisplayColumns = [
            'unitFull',
            'name',
            'phone',
            'email',
            'lastServiced',
        ];
        this.propertyService.reportFilteredData = filteredData;
        return this.router.navigateByUrl('home-page/report-viewer');
    }

    async generateViewReport() {
        let filteredData: PropertyDetail[] = [];
        switch (this.viewBy) {
            case 'all':
                filteredData = this.reportData;
                break;
            case 'court':
                filteredData = this.reportData.filter((property) =>
                    this.courtSelected?.includes(property.unitCourt)
                );
                break;
            case 'house':
                filteredData = this.reportData.filter((property) =>
                    this.houseSelected?.includes(property.unitHouse)
                );
        }

        this.propertyService.reportFilteredData = filteredData;
        this.propertyService.reportDisplayColumns = [
            'unitFull',
            'name',
            'phone',
            'email',
        ];
        return this.router.navigateByUrl('home-page/report-viewer');
    }

    async generateSubLetReport() {
        let filteredData = this.reportData.filter((property) =>
            this.courtSelected?.includes(property.unitCourt)
        );

        switch (this.overDueOrMonth) {
            case 'overdue':
                filteredData = filteredData.filter((property) => {
                    return (
                        property?.subLetDetails.subLetExpiry <
                            new Date().toISOString() &&
                        property?.subLetDetails?.subLet === true
                    );
                });
                break;

            case 'month':
                filteredData = filteredData.filter((property) => {
                    if (
                        !property?.subLetDetails.subLet ||
                        !property?.subLetDetails?.subLetExpiry
                    )
                        return false;
                    const expiryDate = new Date(
                        property.subLetDetails.subLetExpiry
                    );

                    return this.dateSelected?.some((month) => {
                        return isSameMonth(
                            new Date(expiryDate),
                            new Date(month)
                        );
                    });
                });
                break;
        }

        this.propertyService.reportDisplayColumns = [
            'unitFull',
            'name',
            'phone',
            'email',
            'subLetExpiry',
        ];
        this.propertyService.reportFilteredData = filteredData;
        return this.router.navigateByUrl('home-page/report-viewer');
    }

    selectAllNone(selectEl: MatSelect) {
        const selected = selectEl.options.find((opt) => opt.selected);
        if (selected) {
            selectEl.options.forEach((item: MatOption) => item.deselect());
        } else {
            selectEl.options.forEach((item: MatOption) => item.select());
        }
    }
}
