import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PropertyDetailsService } from '../property-details/property-details.service';
import { WorkOrder } from '../../pages/work-orders/work-orders.component';
import { AuditService } from '../audit/audit.service';

@Injectable({
  providedIn: 'root',
})
export class WorkOrdersService {
  apiBaseUrl = environment.apiBaseUrl;
  apiKey = environment.apiKey;

  constructor(
    public http: HttpClient,
    public propertyDetails: PropertyDetailsService,
    public audit: AuditService
  ) {}

  createWorkOrder(data: Partial<WorkOrder>): Observable<any> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
    this.audit.createLog(`Created Work Order`);
    return this.http.put(this.apiBaseUrl + '/workorders/', data, {
      headers,
    });
  }

  getAllOrders(): Observable<WorkOrder[]> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);

    return this.http.get<WorkOrder[]>(this.apiBaseUrl + '/workorders/', {
      headers,
    });
  }

  getAllOrdersFull(): Observable<WorkOrder[]> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);

    return this.http.get<WorkOrder[]>(this.apiBaseUrl + '/workorders/all', {
      headers,
    });
  }

  getExpenses(): Observable<WorkOrder[]> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);

    return this.http.get<WorkOrder[]>(
      this.apiBaseUrl + '/workorders/getexpenses/',
      { headers }
    );
  }

  getOrder(orderNumber: string): Observable<WorkOrder> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
    let params = new HttpParams().append('orderNumber', orderNumber);
    this.audit.createLog(`Viewed Work Order, ${orderNumber}`);
    return this.http.get<WorkOrder>(this.apiBaseUrl + '/workorders/order/', {
      headers,
      params,
    });
  }

  getApprovals(): Observable<Partial<WorkOrder>[]> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);

    return this.http.get<WorkOrder[]>(
      this.apiBaseUrl + '/workorders/approvals/',
      { headers }
    );
  }

  updateOrder(
    updateData: Partial<WorkOrder> & { sendEmail?: boolean }
  ): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
    this.audit.createLog(`Updated Work Order, ${updateData.orderNumber}`);
    return this.http.post<string>(
      this.apiBaseUrl + '/workorders/order/',
      updateData,
      { headers, observe: 'response' }
    );
  }

  getPresignedUrl(key: string, operation: string): Observable<string> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
    let body = { key, operation, bucket: 'workOrders' };
    return this.http.post<string>(this.apiBaseUrl + '/presign/', body, {
      headers,
    });
  }

  fileUpload(url: string, key: string, file: File) {
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    return this.http.put(url, file, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }

  getFileList(workOrderNumber: string): Observable<string[]> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
    let params = new HttpParams().append('workOrderNumber', workOrderNumber);
    return this.http.get<string[]>(this.apiBaseUrl + '/filelist/', {
      headers,
      params,
    });
  }

  deleteFile(key: string) {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
    let body = { key, bucket: 'workOrders' };
    return this.http.post<string>(this.apiBaseUrl + '/deletefile/', body, {
      headers,
    });
  }

  getContractorOrders(orderNumber: string): Observable<Partial<WorkOrder>[]> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
    let params = new HttpParams().append('orderNumber', orderNumber);

    return this.http.get<WorkOrder[]>(
      this.apiBaseUrl + '/workorders/contractor',
      { headers, params }
    );
  }

  getAwaitingPayment(): Observable<Partial<WorkOrder>[]> {
    let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);

    return this.http.get<WorkOrder[]>(
      this.apiBaseUrl + '/workorders/invoices/',
      { headers }
    );
  }

  printOrder(orderNumber: string) {
    let headers = new HttpHeaders()
      .set('X-Api-Key', this.apiKey)
      .set('Content-Type', 'application/pdf');
    let body = { orderNumber };

    return this.http.post<string>(this.apiBaseUrl + '/workorders/print', body, {
      headers,
    });
  }
}
