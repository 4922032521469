import { Component, OnInit } from '@angular/core';
import { WorkOrdersService } from '../../../../services/work-orders/work-orders.service';
import { WorkOrder } from '../../../work-orders/work-orders.component';
import { firstValueFrom } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AuditModalComponent } from '../../../../components/audit-modal/audit-modal.component';
import { BankDetailsModalComponent } from '../../../../components/bank-details-modal/bank-details-modal.component';
import {
    WorkOrderEvents,
    WorkOrderStatus,
} from '../../../../enums/work-orders';
import { ConfirmModalComponent } from '../../../../components/confirm-modal/confirm-modal.component';
import { AuditService } from '../../../../services/audit/audit.service';

@Component({
    selector: 'app-invoice-run',
    templateUrl: './invoice-run.component.html',
    styleUrls: ['./invoice-run.component.css'],
})
export class InvoiceRunComponent implements OnInit {
    workOrders!: Partial<WorkOrder>[];
    loading: boolean = false;

    constructor(
        public workOrderService: WorkOrdersService,
        public dialog: MatDialog,
        public auditService: AuditService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        await this.getWorkOrders();
        this.loading = false;
    }

    async getWorkOrders() {
        this.workOrders =
            (await firstValueFrom(
                this.workOrderService.getAwaitingPayment()
            )) || [];
        this.workOrders = this.workOrders.sort((a, b) => {
            const dateA =
                a?.audits?.find((audit) => audit?.event === 'Invoice Approved')
                    ?.date || new Date().toISOString();
            const dateB =
                b?.audits?.find((audit) => audit?.event === 'Invoice Approved')
                    ?.date || new Date().toISOString();

            return new Date(dateA).getTime() - new Date(dateB).getTime();
        });
    }

    /**
     * * View file
     * @param fileName
     */
    openFile(fileName: string): void {
        const windowRef = window.open();
        firstValueFrom(
            this.workOrderService.getPresignedUrl(fileName, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    calculateAmount(order: Partial<WorkOrder>) {
        if (!order.leaseGroups) return '0';
        return Object.values(order.leaseGroups)
            .reduce((total, amount) => total + amount, 0)
            .toFixed(2);
    }

    getApprovedDate(order: Partial<WorkOrder>) {
        return (
            order.audits?.find((audit) => audit?.event === 'Invoice Approved')
                ?.date || ''
        );
    }

    getApprovedName(order: Partial<WorkOrder>) {
        return (
            order.audits?.find((audit) => audit?.event === 'Invoice Approved')
                ?.user || ''
        );
    }

    /** Open Info Dialog Box */
    openAuditDialog = (order: Partial<WorkOrder>) => {
        this.dialog.open(AuditModalComponent, {
            width: '600px',
            data: { audits: order.audits },
        });
    };

    /** Open Info Dialog Box */
    openBankDialog = (order: Partial<WorkOrder>) => {
        this.dialog.open(BankDetailsModalComponent, {
            width: '600px',
            data: { contractor: order.contractorId },
        });
    };

    async markAsPaid(order: Partial<WorkOrder>) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                const audits = await this.createNewAuditsArray(
                    order,
                    WorkOrderEvents.InvoicePaid
                );
                await firstValueFrom(
                    this.workOrderService.updateOrder({
                        orderNumber: order.orderNumber,
                        status: WorkOrderStatus.Paid,
                        audits,
                    })
                );
                await this.getWorkOrders();
            }
        });
    }

    createNewAuditsArray = async (
        order: Partial<WorkOrder>,
        newEvent: WorkOrderEvents
    ) => {
        if (!order.orderNumber) return;

        let audits = (
            await firstValueFrom(
                this.workOrderService.getOrder(order.orderNumber)
            )
        )?.audits;
        const newAudit = await this.auditService.createWorkOrderAudit(newEvent);
        return audits ? [...audits, newAudit] : [newAudit];
    };
}
