<app-page-toolbar [backButtonRoute]="'/home-page/finance-reports'">
</app-page-toolbar>

<mat-card class="reportToolbar" *ngIf="dataSource?.data">
    <button
        (click)="exportReport()"
        [disabled]="dataSource.data.length === 0"
        mat-button
    >
        Export
    </button>
</mat-card>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<mat-card *ngIf="!loading">
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">analytics</mat-icon>
            Allocated Payments
        </mat-card-title>
    </mat-card-header>
    <ul *ngIf="dataSource">
        <li>
            <mat-label class="headLabel">Date Range:</mat-label>
            <mat-label
                >{{ startDate | date: 'dd/MM/yyyy' }} -
                {{ endDate | date: 'dd/MM/yyyy' }}</mat-label
            >
        </li>
        <li>
            <mat-label class="headLabel">Payments:</mat-label>
            <mat-label>{{ dataSource.data.length }}</mat-label>
        </li>
        <li>
            <mat-label class="headLabel">Total Income:</mat-label>
            <mat-label>{{ totalIncome | currency: '£ ' }}</mat-label>
        </li>
    </ul>

    <br />
    <table [dataSource]="dataSource" mat-table *ngIf="!loading">
        <ng-container matColumnDef="date">
            <th *matHeaderCellDef mat-header-cell>Date</th>
            <td *matCellDef="let trans" mat-cell>
                {{ trans['date'] | date: 'dd/MM/yyyy' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="description">
            <th *matHeaderCellDef mat-header-cell>Description</th>
            <td *matCellDef="let trans" mat-cell>{{ trans['description'] }}</td>
        </ng-container>

        <ng-container matColumnDef="amount">
            <th *matHeaderCellDef mat-header-cell>Amount</th>
            <td *matCellDef="let trans" mat-cell>
                {{
                    (trans.transactionType === 'Payment'
                        ? trans['amount']
                        : -trans['amount']
                    ) | currency: '£ '
                }}
            </td>
        </ng-container>

        <ng-container matColumnDef="unitFull">
            <th *matHeaderCellDef mat-header-cell>Unit</th>
            <td *matCellDef="let trans" mat-cell>{{ trans['unitFull'] }}</td>
        </ng-container>

        <tr *matHeaderRowDef="displayColumns; sticky: true" mat-header-row></tr>
        <tr
            *matRowDef="let row; columns: displayColumns"
            disabled="true"
            mat-row
        ></tr>
    </table>
</mat-card>
