<app-page-toolbar [backButtonRoute]="'/home-page/budgets'"> </app-page-toolbar>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<div>
    <div id="sideBar" *ngIf="newBudgetForm && !loading">
        <mat-card id="totals">
            <mat-card-header>
                <mat-card-title>
                    <mat-icon>currency_pound</mat-icon>
                    Totals
                </mat-card-title>
            </mat-card-header>
            <div class="listDiv">
                <mat-list dense>
                    <mat-list-item>
                        <div class="inputDiv">
                            <mat-label>Budget Total</mat-label>
                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="budgetValue"
                            />
                        </div>
                    </mat-list-item>
                    <mat-list-item>
                        <div class="inputDiv">
                            <mat-label>Reserves Total</mat-label>
                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="reserveValue"
                            />
                        </div>
                    </mat-list-item>
                    <mat-divider></mat-divider>
                    <mat-list-item>
                        <div class="inputDiv">
                            <mat-label class="mat-body-strong"
                                >Total Income</mat-label
                            >
                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="totalValue"
                            />
                        </div>
                    </mat-list-item>
                </mat-list>
            </div>
        </mat-card>

        <mat-card id="serviceCharge" [formGroup]="searchForm">
            <mat-card-header>
                <mat-card-title>
                    <mat-icon>description</mat-icon>
                    Examples
                </mat-card-title>
            </mat-card-header>
            <mat-label>Select Unit to view Breakdown</mat-label>
            <br />
            <mat-form-field appearance="outline">
                <mat-label>Unit Court</mat-label>
                <mat-select formControlName="unitCourt">
                    <mat-option>-</mat-option>
                    <mat-option
                        *ngFor="let court of unitCourts"
                        [value]="court"
                        >{{ court }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Unit Number</mat-label>
                <mat-select formControlName="unit">
                    <mat-option
                        *ngFor="let number of unitNumbers"
                        [value]="number"
                        >{{ number }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <button
                mat-flat-button
                (click)="getBreakdown()"
                [disabled]="!searchForm.controls.unit.value"
            >
                View Breakdown
            </button>
            <button
                mat-flat-button
                (click)="getServiceCharge()"
                [disabled]="!searchForm.controls.unit.value"
            >
                Service Charge
            </button>
        </mat-card>
    </div>

    <mat-card>
        <mat-card-header>
            <mat-card-title>
                <mat-icon>edit_square</mat-icon>
                Edit Budget {{ readOnlyMode ? '(Read Only)' : '' }}
            </mat-card-title>
            <mat-label>{{ nextBudgetYear }}</mat-label>
        </mat-card-header>

        <div
            class="listDiv"
            *ngIf="newBudgetForm && !loading"
            [formGroup]="newBudgetForm"
        >
            <ul>
                <li *ngFor="let head of budgetHeads">
                    <div class="inputDiv" *ngIf="!head?.subCats">
                        <mat-label>{{ head.name }}</mat-label>
                        <div class="moneyDiv">
                            <input
                                currencyMask
                                [options]="{ prefix: '£ ' }"
                                matInput
                                autocomplete="off"
                                placeholder="0.00"
                                formControlName="{{ head.code }}"
                            />
                        </div>
                    </div>

                    <div class="listWrapper" *ngIf="head?.subCats">
                        <ul>
                            <li>
                                <div class="inputDiv">
                                    <label class="mat-body-strong">{{
                                        head.name
                                    }}</label>
                                    <div class="moneyDiv">
                                        <input
                                            class="mat-body-strong"
                                            currencyMask
                                            [options]="{ prefix: '£ ' }"
                                            matInput
                                            autocomplete="off"
                                            placeholder="0.00"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            [ngModel]="
                                                calculateCatValue(head?.subCats)
                                            "
                                            disabled
                                        />
                                    </div>
                                </div>
                            </li>
                            <li *ngFor="let subCat of head.subCats">
                                <div class="inputDiv">
                                    <mat-label>- {{ subCat?.name }}</mat-label>
                                    <div class="moneyDiv">
                                        <input
                                            currencyMask
                                            [options]="{ prefix: '£ ' }"
                                            matInput
                                            autocomplete="off"
                                            placeholder="0.00"
                                            formControlName="{{ subCat.code }}"
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <mat-divider></mat-divider>
                </li>
            </ul>
        </div>
        <div
            class="listDiv"
            *ngIf="newBudgetForm && !loading"
            [formGroup]="newBudgetForm"
        >
            <mat-list dense>
                <mat-list-item>
                    <div class="inputDiv">
                        <mat-label class="mat-body-strong catName"
                            >Reserve Contributions</mat-label
                        >
                        <mat-divider></mat-divider>
                    </div>
                </mat-list-item>
                <mat-list-item *ngFor="let head of reserveHeads">
                    <div class="inputDiv">
                        <mat-label class="catName">{{ head.name }}</mat-label>
                        <div class="moneyDiv">
                            <input
                                currencyMask
                                [options]="{ prefix: '£ ' }"
                                matInput
                                autocomplete="off"
                                placeholder="0.00"
                                formControlName="{{ head.code }}"
                            />
                        </div>
                    </div>

                    <mat-divider></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>

        <app-footer-toolbar
            [cancelButton]="true"
            [saveButton]="true"
            [saveDisabled]=""
            [showToolBar]="!loading && newBudgetForm && newBudgetForm.dirty"
            (cancelClick)="createForm()"
            (saveClick)="updateBudget()"
        >
        </app-footer-toolbar>
    </mat-card>

    <mat-toolbar *ngIf="messageBanner" id="messageBanner">
        <mat-label>{{ messageText }}</mat-label>
    </mat-toolbar>
</div>
