import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-page-toolbar',
    templateUrl: './page-toolbar.component.html',
    styleUrls: ['./page-toolbar.component.css'],
})
export class PageToolbarComponent implements OnInit {
    @Input() pageTitle: string | undefined;
    @Input() backButtonRoute!: string;
    @Input() appVersion!: string;
    @Input() apiVersion!: string;
    @Input() environment!: string;

    constructor() {}

    ngOnInit(): void {}
}
