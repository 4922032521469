import { Component, OnInit } from '@angular/core';
import { BudgetHeads } from '../../../constants/budgets';
import { firstValueFrom } from 'rxjs';
import { BudgetsService } from '../../../services/budgets/budgets.service';
import { isAfter, isBefore, parseISO } from 'date-fns';
import { WorkOrder } from '../../work-orders/work-orders.component';
import { WorkOrdersService } from '../../../services/work-orders/work-orders.service';
import { Budget } from '../../../interfaces/budget-interfaces';

@Component({
    selector: 'app-variance',
    templateUrl: './variance.component.html',
    styleUrls: ['./variance.component.css'],
})
export class VarianceComponent implements OnInit {
    selectedYears!: string;
    budgetYears: string[] = [];
    totalBudget = 0;
    totalExpenses = 0;
    totalVariance = 0;
    percentage: number[] = [];
    totalPercentage = 0;
    budgetHeads = BudgetHeads.sort((a, b) => a.name.localeCompare(b.name));
    loading: boolean = true;
    updating = true;
    currentBudgetYear!: string;
    nextBudgetYear!: string;
    allWorkOrders!: WorkOrder[];
    filteredWorkOrders!: WorkOrder[];
    selectedBudgets!: Budget;
    relevantWorkOrder!: WorkOrder[];
    expenses: { [key: string]: number } = {};
    budgetTotalNoRes!: number;
    totalExpensesNoRes!: number;
    totalVarianceNoRes!: number;
    totalPercentageNoRes!: number;

    constructor(
        private budgetService: BudgetsService,
        private workOrderService: WorkOrdersService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        const budgetResponse = await firstValueFrom(
            await this.budgetService.getAllYears()
        );

        const budgetYear = this.budgetService.calculateBudgetYear();
        this.nextBudgetYear =
            (+budgetYear + 1).toString() + '-' + (+budgetYear + 2).toString();
        this.budgetYears = budgetResponse
            .map((budget) => budget.budgetYear)
            .filter((budget) => budget !== this.nextBudgetYear)
            .sort((a, b) => +a.split('-')[0] - +b.split('-')[0]);
        this.currentBudgetYear =
            this.budgetYears.find(
                (budget) => budget.split('-')[0] === budgetYear
            ) || '';
        this.selectedYears = this.currentBudgetYear;
        await this.updateSelected();
        this.loading = false;
    }

    async updateSelected() {
        this.updating = true;
        await this.updateSelectedExpenses();
        await this.updateSelectedBudgets();

        this.totalBudget = this.selectedBudgets.budgetValues.reduce(
            (a, b) => a + (b.value || 0),
            0
        );
        this.budgetTotalNoRes = this.selectedBudgets.budgetValues
            .filter((val) => !val.code.includes('SDH'))
            .reduce((a, b) => a + (b.value || 0), 0);

        this.totalExpenses = Object.values(this.expenses).reduce(
            (a, b) => a + (b || 0),
            0
        );

        this.totalExpensesNoRes = Object.entries(this.expenses)
            .filter(([key, value]) => key !== 'RES')
            .map(([key, value]) => value)
            .reduce((a, b) => a + (b || 0), 0);

        this.totalVarianceNoRes =
            this.budgetTotalNoRes - this.totalExpensesNoRes;
        this.totalPercentageNoRes =
            (this.totalExpensesNoRes / this.budgetTotalNoRes) * 100;

        this.totalVariance = this.totalBudget - this.totalExpenses;
        this.totalPercentage = (this.totalExpenses / this.totalBudget) * 100;

        this.updating = false;
    }

    async updateSelectedBudgets() {
        this.selectedBudgets = (
            await firstValueFrom(
                await this.budgetService.getSelectedBudgets([
                    this.selectedYears,
                ])
            )
        )[0];
    }

    async updateSelectedExpenses() {
        if (!this.allWorkOrders)
            this.allWorkOrders = await firstValueFrom(
                this.workOrderService.getExpenses()
            );
        this.relevantWorkOrder = this.allWorkOrders;

        this.filteredWorkOrders = [];
        const budgetYear = this.selectedYears.split('-')[0];
        this.filteredWorkOrders = this.relevantWorkOrder.filter((workOrder) => {
            const approvalDate = workOrder.audits?.find(
                (audit) => audit.event === 'Invoice Approved'
            )!.date;
            if (!approvalDate) return false;

            return (
                isAfter(parseISO(approvalDate), new Date(+budgetYear, 8, 1)) &&
                isBefore(
                    parseISO(approvalDate),
                    new Date(+budgetYear + 1, 8, 1)
                )
            );
        });

        this.expenses = {};

        this.expenses['RES'] = this.filteredWorkOrders
            .filter((order) => order.expenseCategory === 'RES')
            .map(
                (order) =>
                    Object.values(order.leaseGroups).reduce((a, b) => a + b),
                0
            )
            .reduce((a, b) => a + b, 0);

        await Promise.all(
            BudgetHeads.map(async (head) => {
                this.expenses[head.code] = this.filteredWorkOrders
                    .filter((order) => order.expenseCategory === head.code)
                    .map(
                        (order) =>
                            Object.values(order.leaseGroups).reduce(
                                (a, b) => a + b
                            ),
                        0
                    )
                    .reduce((a, b) => a + b, 0);
            })
        );
    }

    getModelValue(
        head: { code: string; name: string; subCats?: any[] },
        budget: { budgetValues: any[] }
    ) {
        if (head?.subCats) {
            const catCodes = head?.subCats?.map((subCat) => subCat.code);
            return budget.budgetValues
                .filter((values) => catCodes?.includes(values.code))
                .map((values) => values.value)
                .reduce((a, b) => a + b, 0);
        }

        return budget.budgetValues.find(
            (budgetCodes) => budgetCodes.code === head.code
        ).value;
    }

    getPercentage(expense: number, budget: number): string {
        return (((expense || 0) / (budget || 0)) * 100).toString() || '0';
    }

    getReservesValue(budget: { budgetValues: any[] }) {
        return budget.budgetValues
            .filter((budgetHead) => budgetHead.code.includes('SDH'))
            .reduce((a, b) => a + b.value, 0);
    }
}
