export interface Schedule {
    name: string;
    code: string;
    size: number;
    breakdowns?: { electricity?: number; insuranceSplit?: number };
}

export const BudgetSchedules: Schedule[] = [
    {
        name: 'Estate',
        code: 'SDH01',
        size: 942,
        breakdowns: {
            electricity: 0.014578,
            insuranceSplit: 0.0263648837674025,
        },
    },
    {
        name: 'Cardiff House',
        code: 'SDH02',
        size: 14,
        breakdowns: {
            electricity: 0.01496,
            insuranceSplit: 0.019594697,
        },
    },
    {
        name: 'Seville / Barcelona / Madrid House',
        code: 'SDH03',
        size: 42,
        breakdowns: {
            electricity: 0.023322,
            insuranceSplit: 0.039103328,
        },
    },
    {
        name: 'Milan House',
        code: 'SDH04',
        size: 16,
        breakdowns: {
            electricity: 0.016843,
            insuranceSplit: 0.019262113,
        },
    },
    {
        name: 'Venice House',
        code: 'SDH05',
        size: 14,
        breakdowns: {
            electricity: 0.008927,
            insuranceSplit: 0.013215813,
        },
    },
    {
        name: 'Oslo House',
        code: 'SDH06',
        size: 15,
        breakdowns: {
            electricity: 0.024724,
            insuranceSplit: 0.01974042,
        },
    },
    {
        name: 'Athens House',
        code: 'SDH07',
        size: 19,
        breakdowns: {
            electricity: 0.018677,
            insuranceSplit: 0.015839031,
        },
    },
    {
        name: 'Palma House',
        code: 'SDH08',
        size: 23,
        breakdowns: {
            electricity: 0.031316,
            insuranceSplit: 0.01998126,
        },
    },
    {
        name: 'Florence House',
        code: 'SDH09',
        size: 19,
        breakdowns: {
            electricity: 0.024396,
            insuranceSplit: 0.017556333,
        },
    },
    {
        name: 'Naples House',
        code: 'SDH10',
        size: 16,
        breakdowns: {
            electricity: 0.028869,
            insuranceSplit: 0.018507189,
        },
    },
    {
        name: 'Capri House',
        code: 'SDH11',
        size: 16,
        breakdowns: {
            electricity: 0.023968,
            insuranceSplit: 0.019055296,
        },
    },
    {
        name: 'Sienna House',
        code: 'SDH12',
        size: 16,
        breakdowns: {
            electricity: 0.020511,
            insuranceSplit: 0.020556896,
        },
    },
    {
        name: 'Paris House',
        code: 'SDH13',
        size: 20,
        breakdowns: {
            electricity: 0.015419,
            insuranceSplit: 0.02058358,
        },
    },
    {
        name: 'Monaco House',
        code: 'SDH14',
        size: 25,
        breakdowns: {
            electricity: 0.025302,
            insuranceSplit: 0.028611579,
        },
    },
    {
        name: 'Rome House',
        code: 'SDH15',
        size: 12,
        breakdowns: {
            electricity: 0.013536,
            insuranceSplit: 0.010687478,
        },
    },
    {
        name: 'Lisbon House',
        code: 'SDH16',
        size: 25,
        breakdowns: {
            electricity: 0.032913,
            insuranceSplit: 0.023532561,
        },
    },
    {
        name: 'Faro House',
        code: 'SDH17',
        size: 24,
        breakdowns: {
            electricity: 0.013495,
            insuranceSplit: 0.023054014,
        },
    },
    {
        name: 'Rio House',
        code: 'SDH18',
        size: 20,
        breakdowns: {
            electricity: 0.016074,
            insuranceSplit: 0.018801092,
        },
    },
    {
        name: 'Geneva House',
        code: 'SDH19',
        size: 30,
        breakdowns: {
            electricity: 0.021666,
            insuranceSplit: 0.034102624,
        },
    },
    {
        name: 'Vienna House',
        code: 'SDH20',
        size: 30,
        breakdowns: {
            electricity: 0.023832,
            insuranceSplit: 0.029790728,
        },
    },
    {
        name: 'Cannes House',
        code: 'SDH21',
        size: 23,
        breakdowns: {
            electricity: 0.027185,
            insuranceSplit: 0.034275228,
        },
    },
    {
        name: 'Malaga House',
        code: 'SDH22',
        size: 28,
        breakdowns: {
            electricity: 0.029337,
            insuranceSplit: 0.022282675,
        },
    },
    {
        name: 'Bordeaux House',
        code: 'SDH23',
        size: 35,
        breakdowns: {
            electricity: 0.032204,
            insuranceSplit: 0.044640888,
        },
    },
    {
        name: 'Porto House',
        code: 'SDH24',
        size: 35,
        breakdowns: {
            electricity: 0.020228,
            insuranceSplit: 0.044640888,
        },
    },
    {
        name: 'Calais House',
        code: 'SDH25',
        size: 36,
        breakdowns: {
            electricity: 0.029842,
            insuranceSplit: 0.031290579,
        },
    },
    {
        name: 'Genoa House',
        code: 'SDH26',
        size: 25,
        breakdowns: {
            electricity: 0.024173,
            insuranceSplit: 0.030921989,
        },
    },
    {
        name: 'Prague House',
        code: 'SDH27',
        size: 30,
        breakdowns: {
            electricity: 0.020938,
            insuranceSplit: 0.024414026,
        },
    },
    {
        name: 'Munich House',
        code: 'SDH28',
        size: 30,
        breakdowns: {
            electricity: 0.02441,
            insuranceSplit: 0.029202889,
        },
    },
    {
        name: 'Sorrento House',
        code: 'SDH29',
        size: 25,
        breakdowns: {
            electricity: 0.022672,
            insuranceSplit: 0.024385217,
        },
    },
    {
        name: 'Nice House',
        code: 'SDH30',
        size: 48,
        breakdowns: {
            electricity: 0.026985,
            insuranceSplit: 0.022238007,
        },
    },
    {
        name: 'Zurich House',
        code: 'SDH31',
        size: 31,
        breakdowns: {
            electricity: 0.017428,
            insuranceSplit: 0.015064053,
        },
    },
    {
        name: 'Dubrovnik House',
        code: 'SDH32',
        size: 47,
        breakdowns: {
            electricity: 0.023784,
            insuranceSplit: 0.030023282,
        },
    },
    {
        name: 'Marseille House',
        code: 'SDH33',
        size: 70,
        breakdowns: {
            electricity: 0.035423,
            insuranceSplit: 0.053028477,
        },
    },
    {
        name: 'Lyon House',
        code: 'SDH34',
        size: 47,
        breakdowns: {
            electricity: 0.026423,
            insuranceSplit: 0.033187731,
        },
    },
    { name: 'Refuse Chute', code: 'SDH35', size: 838 },
    { name: 'Window Cleaning', code: 'SDH36', size: 8 },
    { name: 'Cold Water', code: 'SDH37', size: 906 },
    { name: 'Pergola Car Spaces', code: 'SDH38', size: 222 },
    {
        name: 'Judkin Court Undercroft Parking',
        code: 'SDH39',
        size: 0,
        breakdowns: {
            electricity: 0.03882,
            insuranceSplit: 0.013815775,
        },
    },
    {
        name: 'Lynton Court Undercroft Parking',
        code: 'SDH40',
        size: 0,
        breakdowns: {
            electricity: 0.034724,
            insuranceSplit: 0.015372759,
        },
    },
    {
        name: 'Taliesin Court Undercroft Parking',
        code: 'SDH41',
        size: 0,
        breakdowns: {
            electricity: 0.021924,
            insuranceSplit: 0.010708377,
        },
    },
    {
        name: 'Penstone Court Undercroft Parking',
        code: 'SDH42',
        size: 0,
        breakdowns: {
            electricity: 0.061989,
            insuranceSplit: 0.036211389,
        },
    },
    {
        name: 'Ezel Court Undercroft Parking',
        code: 'SDH43',
        size: 0,
        breakdowns: {
            electricity: 0.02361,
            insuranceSplit: 0.016141396,
        },
    },
    {
        name: 'Hansen Court Undercroft Parking',
        code: 'SDH44',
        size: 0,
        breakdowns: {
            electricity: 0.044573,
            insuranceSplit: 0.030213459,
        },
    },
];

