<app-page-toolbar [pageTitle]="'Work Orders'"></app-page-toolbar>

<div>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-label class="info-panel">
                <mat-icon>info</mat-icon>
                Work Order Statuses
            </mat-label>
        </mat-expansion-panel-header>

        <h3>Active Statuses</h3>
        <mat-divider></mat-divider>
        <mat-list dense>
            <mat-list-item>
                <span matLine class="statusHeader">{{
                    WorkOrderStatus.Open
                }}</span>
                <span matLine
                    >Work Order open and ready for work to begin.</span
                >
            </mat-list-item>
            <mat-list-item>
                <span matLine class="statusHeader">{{
                    WorkOrderStatus.AwaitingAuth
                }}</span>
                <p matLine>
                    Work Order has been created by someone without high enough
                    authorisation limit, and is awaiting approval from someone
                    with appropriate limit.
                </p>
            </mat-list-item>
            <mat-list-item>
                <span matLine class="statusHeader">{{
                    WorkOrderStatus.InvoiceApproval
                }}</span>
                <span matLine
                    >Invoice has been uploaded, and is awaiting approval.</span
                >
            </mat-list-item>
            <mat-list-item>
                <span matLine class="statusHeader">{{
                    WorkOrderStatus.AwaitingPayment
                }}</span>
                <span matLine
                    >Invoice has been approved, but not yet paid.</span
                >
            </mat-list-item>
        </mat-list>

        <h3>Closed Statuses</h3>
        <mat-divider></mat-divider>

        <mat-list dense>
            <mat-list-item>
                <span matLine class="statusHeader">{{
                    WorkOrderStatus.Paid
                }}</span>
                <span matLine>Invoice has been paid.</span>
            </mat-list-item>
            <mat-list-item>
                <span matLine class="statusHeader">{{
                    WorkOrderStatus.Closed
                }}</span>
                <span matLine
                    >Work Order is not longer relevant / required. (Essentially
                    deleted)</span
                >
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>

    <button
        mat-raised-button
        class="createNew"
        routerLink="/home-page/create-works-order"
    >
        Create New Work Order
    </button>
</div>

<mat-tab-group mat-stretch-tabs="always" color="primary">
    <mat-tab>
        <ng-template mat-tab-label class="tab-label">
            <mat-icon slot="start">receipt</mat-icon>
            Active
        </ng-template>
        <div class="pageDiv">
            <mat-form-field appearance="outline">
                <mat-label>Enter Filter Criteria...</mat-label>
                <input
                    matInput
                    [(ngModel)]="orderFilter"
                    (ngModelChange)="filterWorkOrders(orderFilter)"
                    autocomplete="off"
                />
            </mat-form-field>

            <small
                *ngIf="filteredOpenOrders?.length === 0 && !loading"
                class="noResults"
                >There are no Work Orders matching selection</small
            >

            <mat-card
                class="workOrderDisplay"
                *ngFor="let workOrder of filteredOpenOrders"
                [routerLink]="[
                    '/home-page/view-work-order',
                    workOrder.orderNumber
                ]"
            >
                <mat-card-header>
                    <mat-label>#{{ workOrder.orderNumber }}</mat-label>
                </mat-card-header>
                <mat-card-content>
                    <div class="listCol">
                        <small>Date Created</small>
                        <mat-label
                            >{{ workOrder.dateCreated | date: 'dd/MM/yyyy' }}
                        </mat-label>
                    </div>
                    <div class="contractorCol">
                        <small>Status</small>
                        <mat-label>{{ workOrder.status }}</mat-label>
                    </div>
                    <div class="contractorCol">
                        <small>Contractor</small>
                        <mat-label
                            >{{ workOrder.contractorId.companyName }}
                        </mat-label>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label class="tab-label">
            <mat-icon slot="start">receipt</mat-icon>
            Closed
        </ng-template>
        <div class="pageDiv">
            <mat-form-field appearance="outline" color="primary">
                <mat-label>Enter Filter Criteria...</mat-label>
                <input
                    matInput
                    [(ngModel)]="orderFilter"
                    (ngModelChange)="filterWorkOrders(orderFilter)"
                    autocomplete="off"
                />
            </mat-form-field>

            <small
                *ngIf="filteredClosedOrders?.length === 0 && !loading"
                class="noResults"
                >There are no Work Orders matching selection</small
            >

            <mat-card
                class="workOrderDisplay"
                *ngFor="let workOrder of filteredClosedOrders"
                [routerLink]="[
                    '/home-page/view-work-order',
                    workOrder.orderNumber
                ]"
            >
                <mat-card-header>
                    <mat-label>#{{ workOrder.orderNumber }}</mat-label>
                </mat-card-header>
                <mat-card-content>
                    <div class="listCol">
                        <small>Date Created</small>
                        <mat-label
                            >{{ workOrder.dateCreated | date: 'dd/MM/yyyy' }}
                        </mat-label>
                    </div>
                    <div class="contractorCol">
                        <small>Status</small>
                        <mat-label>{{ workOrder.status }}</mat-label>
                    </div>
                    <div class="contractorCol">
                        <small>Contractor</small>
                        <mat-label
                            >{{ workOrder.contractorId.companyName }}
                        </mat-label>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-tab>
</mat-tab-group>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>
