import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { WorkOrder } from '../work-orders.component';
import { WorkOrdersService } from '../../../services/work-orders/work-orders.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ContractorDetails } from '../../contractor-sign-up/contractor-sign-up.component';
import { ContractorsService } from '../../../services/contractors/contractors.service';
import { InfoModalComponent } from '../../../components/info-modal/info-modal.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WorkOrderEvents, WorkOrderStatus } from '../../../enums/work-orders';
import { ConfirmModalComponent } from '../../../components/confirm-modal/confirm-modal.component';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { AuditService } from '../../../services/audit/audit.service';
import { NotificationService } from '../../../services/notification/notification.service';
import { BudgetHeads } from '../../../constants/budgets';
import { BudgetSchedules } from '../../../constants/schedules';
import { AuthServiceService } from '../../../services/auth-service/auth-service.service';

@Component({
    selector: 'app-view-work-order',
    templateUrl: './view-work-order.component.html',
    styleUrls: ['./view-work-order.component.css'],
})
export class ViewWorkOrderComponent implements OnInit {
    orderNumber!: string;
    workOrder!: WorkOrder;
    workOrderForm!: FormGroup;
    loading: boolean = true;
    approvedContractors!: Partial<ContractorDetails>[];
    authLimit!: number;
    leaseGroups = BudgetSchedules;
    totalValue!: string;
    selectedLeaseGroups: string[] = [];
    expenseCategory = BudgetHeads.sort((a, b) => a.name.localeCompare(b.name));
    risks = [
        'Demolition',
        'Working With Electricity',
        'Fire Alarm System',
        'Working At Height',
        'Hot Works',
        'Public Domain',
    ];
    messageText: string = '';
    messageBanner: boolean = false;
    uploading: boolean = false;
    docsUploading: boolean = false;
    upload$!: any;
    docsUpload$: any;
    progress: number = 0;
    supportingDocProgress: number = 0;
    invoice: File | undefined;
    supportDocs!: File | undefined;
    fileList: string[] = [];
    amending: boolean = false;
    printing: boolean = false;

    constructor(
        public route: ActivatedRoute,
        public workOrderService: WorkOrdersService,
        public contractorService: ContractorsService,
        public dialog: MatDialog,
        public auditService: AuditService,
        public auth: AuthServiceService,
        public notifications: NotificationService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            this.orderNumber = (await firstValueFrom(this.route.params))[
                'orderNumber'
            ];
            this.workOrder = await firstValueFrom(
                await this.workOrderService.getOrder(this.orderNumber)
            );
            this.fileList =
                (await firstValueFrom(
                    await this.workOrderService.getFileList(this.orderNumber)
                )) || [];

            this.authLimit = (await this.auth.getAuthLimit()) || 0;
            this.initialiseForm(this.workOrder);
            this.onValueChange();
            this.approvedContractors = (
                (await firstValueFrom(
                    await this.contractorService.getContractorList()
                )) || []
            )
                .filter((contractor) => contractor.status === 'verified')
                .sort((a, b) => a!.companyName!.localeCompare(b!.companyName!));

            if (+this.totalValue > this.authLimit) this.workOrderForm.disable();
            if (
                [
                    WorkOrderStatus.Closed,
                    WorkOrderStatus.Paid,
                    WorkOrderStatus.AwaitingPayment,
                ].includes(this.workOrder.status)
            )
                this.workOrderForm.disable();
        } catch {
        } finally {
            this.loading = false;
        }
    }

    initialiseForm(workOrder: WorkOrder) {
        this.workOrderForm = new FormGroup({
            contractorId: new FormControl(
                { value: workOrder.contractorId, disabled: false },
                Validators.required
            ),
            expenseCategory: new FormControl(
                { value: workOrder.expenseCategory, disabled: false },
                Validators.required
            ),
            leaseGroups: new FormGroup({}),
            description: new FormControl(
                { value: workOrder.description, disabled: false },
                Validators.required
            ),
            risks: new FormControl({ value: workOrder.risks, disabled: false }),
        });

        this.selectedLeaseGroups = Object.keys(this.workOrder.leaseGroups);
        this.onSelectedLeaseGroupChange();
        this.getLeaseGroupsForms().patchValue(this.workOrder.leaseGroups);
    }

    getLeaseGroupsForms() {
        return this.workOrderForm.get('leaseGroups') as FormGroup;
    }

    onValueChange(): void {
        this.totalValue = (
            Object.values(this.getLeaseGroupsForms().value) as number[]
        )
            .reduce((a, b) => a + b, 0)
            .toFixed(2);

        this.getLeaseGroupsForms().valueChanges.subscribe(() => {
            this.totalValue = (
                Object.values(this.getLeaseGroupsForms().value) as number[]
            )
                .reduce((a, b) => a + b, 0)
                .toFixed(2);
            this.workOrderForm.markAsDirty();
        });
    }

    onSelectedLeaseGroupChange() {
        this.selectedLeaseGroups.forEach((group) => {
            if (
                !Object.keys(this.getLeaseGroupsForms().controls).includes(
                    group
                )
            ) {
                this.getLeaseGroupsForms().addControl(
                    group,
                    new FormControl(null, Validators.required)
                );
            }
        });

        Object.keys(this.getLeaseGroupsForms().controls).forEach((control) => {
            if (!this.selectedLeaseGroups.includes(control)) {
                this.getLeaseGroupsForms().removeControl(control);
                this.getLeaseGroupsForms().markAsDirty();
            }
        });
    }

    compareContractors(
        objectOne: Partial<ContractorDetails>,
        objectTwo: Partial<ContractorDetails>
    ): boolean {
        return objectOne.applicationId === objectTwo.applicationId;
    }

    resetForm() {
        this.initialiseForm(this.workOrder);
        this.onValueChange();
    }

    /**
     * Show Message banner
     *
     * @param text
     */
    showMessageBanner = (text: string): void => {
        this.messageText = text;
        this.messageBanner = true;
        setTimeout(() => (this.messageBanner = false), 3000);
    };

    /**
     * Open info dialog
     * @param message
     */
    openInfoDialog = (message: string): void => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    async saveChanges() {
        const changedValues: Partial<WorkOrder> = {
            orderNumber: this.orderNumber,
        };

        Object.keys(this.workOrderForm.controls).forEach((controlName) => {
            if (this.workOrderForm.get(controlName)?.dirty) {
                changedValues[controlName as keyof WorkOrder] =
                    this.workOrderForm.get(controlName)?.value;
            }
        });
        if (Object.keys(changedValues).length > 1) {
            try {
                changedValues.audits = await this.createNewAuditsArray(
                    WorkOrderEvents.Amended
                );
                const submitResponse = await firstValueFrom(
                    await this.workOrderService.updateOrder(changedValues)
                );
                this.workOrderForm.markAsPristine();
                this.showMessageBanner(submitResponse.body);
                this.workOrder.audits = changedValues.audits;
            } catch {
                this.openInfoDialog(
                    'Cannot connect to Server. Changes have not been saved.'
                );
            }
        }
    }

    amendWorkOrder(status: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
                message: `Are you sure you want to ${status === WorkOrderStatus.Closed ? 'close' : 'Approve'} this Work Order?`,
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            this.amending = true;

            if (result) {
                try {
                    const workOrderStatus: WorkOrderStatus =
                        status as WorkOrderStatus;
                    let event: WorkOrderEvents;
                    let sendEmail: boolean = false;

                    switch (status) {
                        case WorkOrderStatus.Closed:
                            event = WorkOrderEvents.Closed;
                            break;
                        case WorkOrderStatus.Open:
                            event = WorkOrderEvents.Authorised;
                            sendEmail = true;
                            break;
                        case WorkOrderStatus.AwaitingPayment:
                            event = WorkOrderEvents.InvoiceApproved;
                            break;
                        default:
                            event = WorkOrderEvents.Amended;
                            break;
                    }

                    const audits = await this.createNewAuditsArray(event);

                    const submitResponse = await firstValueFrom(
                        await this.workOrderService.updateOrder({
                            orderNumber: this.orderNumber,
                            status: workOrderStatus,
                            audits,
                            sendEmail,
                        })
                    );
                    this.workOrder.status = workOrderStatus;
                    this.workOrder.audits = audits;
                    this.workOrderForm.markAsPristine();
                    if (
                        [
                            WorkOrderStatus.Closed,
                            WorkOrderStatus.AwaitingPayment,
                        ].includes(workOrderStatus)
                    )
                        this.workOrderForm.disable();
                    this.showMessageBanner(submitResponse.body);
                    this.notifications.triggerApprovals();
                    this.amending = false;
                } catch {
                    this.amending = false;
                    this.openInfoDialog(
                        'Cannot connect to Server. Changes have not been saved.'
                    );
                }
            }
        });
    }

    /**
     * Upload File to AWS, If file does not already exist with name
     *
     * @param file
     */
    uploadFile = async (file: File | undefined): Promise<any> => {
        if (!file) return;
        this.uploading = true;
        const url = await firstValueFrom(
            this.workOrderService.getPresignedUrl(
                this.orderNumber + '/invoice.pdf',
                'Put'
            )
        );

        return (this.upload$ = this.workOrderService
            .fileUpload(url, '', file)
            .subscribe(async (event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const eventTotal: number = event.total
                            ? event.total
                            : 0;
                        this.progress = Math.round(
                            (event.loaded / eventTotal) * 100
                        );
                        break;

                    case HttpEventType.Response:
                        this.fileList =
                            (await firstValueFrom(
                                await this.workOrderService.getFileList(
                                    this.orderNumber
                                )
                            )) || [];
                        const audits = await this.createNewAuditsArray(
                            WorkOrderEvents.InvoiceUploaded
                        );
                        const submitResponse = await firstValueFrom(
                            await this.workOrderService.updateOrder({
                                orderNumber: this.orderNumber,
                                status: WorkOrderStatus.InvoiceApproval,
                                audits,
                            })
                        );
                        this.workOrder.status = WorkOrderStatus.InvoiceApproval;
                        this.workOrder.audits = audits;
                        this.invoice = undefined;
                        this.uploading = false;
                        this.progress = 0;
                        break;
                }
            }));
    };

    /**
     * Upload File to AWS, If file does not already exist with name
     *
     * @param file
     */
    uploadSupportingDocs = async (file: File | undefined): Promise<any> => {
        if (!file) return;
        try {
            this.docsUploading = true;

            const url = await firstValueFrom(
                this.workOrderService.getPresignedUrl(
                    this.orderNumber + '/supportingDocs/' + file.name,
                    'Put'
                )
            );

            return (this.docsUpload$ = this.workOrderService
                .fileUpload(url, '', file)
                .subscribe(async (completed) => {
                    this.fileList =
                        (await firstValueFrom(
                            await this.workOrderService.getFileList(
                                this.orderNumber
                            )
                        )) || [];
                    this.docsUploading = false;
                    this.supportDocs = undefined;
                }));
        } catch {
        } finally {
            this.docsUploading = false;
        }
    };

    /**
     * Cancel an upload in progress
     */
    cancelUpload = (): void => {
        if (this.upload$) this.upload$.unsubscribe();
        this.uploading = false;
    };

    /**
     * Cancel an upload in progress
     */
    cancelUploadDocs = (): void => {
        if (this.docsUpload$) this.docsUpload$.unsubscribe();
        this.docsUploading = false;
    };

    /**
     * * View file
     * @param fileName
     */
    openFile(fileName: string): void {
        const windowRef = window.open();
        firstValueFrom(
            this.workOrderService.getPresignedUrl(fileName, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * Delete file for property
     * @param file
     */
    deleteFile(file: string): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this file?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                (await this.workOrderService.deleteFile(file)).subscribe(
                    async () => {
                        this.fileList = await firstValueFrom(
                            await this.workOrderService.getFileList(
                                this.orderNumber
                            )
                        );
                        const submitResponse = await firstValueFrom(
                            await this.workOrderService.updateOrder({
                                orderNumber: this.orderNumber,
                                status: WorkOrderStatus.Open,
                            })
                        );
                        this.workOrder.status = WorkOrderStatus.Open;
                    }
                );
            }
        });
    }

    async canDeactivate() {
        return new Promise((resolve) => {
            if (
                this.workOrderForm &&
                this.workOrderForm.dirty &&
                !this.workOrderForm.disabled
            ) {
                const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                        message:
                            'You have unsaved changes that will be lost, are you sure?',
                    },
                });

                dialogRef.afterClosed().subscribe(async (result) => {
                    if (!result) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    createNewAuditsArray = async (newEvent: WorkOrderEvents) => {
        let audits = (
            await firstValueFrom(
                await this.workOrderService.getOrder(this.orderNumber)
            )
        )?.audits;
        const newAudit = await this.auditService.createWorkOrderAudit(newEvent);
        return audits ? [...audits, newAudit] : [newAudit];
    };

    scheduleDisplay = (code: string) =>
        this.leaseGroups.find((sched) => sched.code === code)?.name;

    supportingFiles = () => {
        return (
            this.fileList.filter((file: string) =>
                file.startsWith(this.orderNumber + '/supportingDocs')
            ) || []
        );
    };

    isCorrectType = (fileTypes: string[], file: File): boolean => {
        return fileTypes.includes(file.type);
    };

    async getPrintPDF() {
        this.printing = true;

        try {
            const pdf = await firstValueFrom(
                this.workOrderService.printOrder(this.workOrder.orderNumber)
            );

            const url = `data:application/pdf;base64,${pdf}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = `Work_Order_${this.workOrder.orderNumber}`;
            link.click();
        } catch {
            this.openInfoDialog('An error occurred while generating the PDF');
        } finally {
            this.printing = false;
        }
    }
}
