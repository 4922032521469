import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-password-modal',
    templateUrl: './password-modal.component.html',
    styleUrls: ['./password-modal.component.css'],
})
export class PasswordModalComponent implements OnInit {
    passwordForm: FormGroup = new FormGroup<any>({
        newPassword: new FormControl(),
        confirmPassword: new FormControl(),
    });

    newPassword!: string;

    match: boolean = false;
    oneNumber: boolean = false;
    oneSpec: boolean = false;
    oneUpper: boolean = false;
    oneLower: boolean = false;
    minlength: boolean = false;

    constructor() {}

    ngOnInit(): void {
        this.passwordForm.valueChanges.subscribe(
            (value: { [key: string]: string }) => {
                const { newPassword, confirmPassword } = value;
                this.match = newPassword === confirmPassword;
                this.oneNumber = /\d/.test(newPassword);
                this.oneSpec = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                    newPassword
                );
                this.oneUpper = /[A-Z]/.test(newPassword);
                this.oneLower = /[a-z]/.test(newPassword);
                this.minlength = newPassword.length >= 8;
                this.newPassword = newPassword;
            }
        );
    }
}
