<app-page-toolbar [pageTitle]="'Contractors'"> </app-page-toolbar>
<div id="inviteButton">
    <button mat-raised-button (click)="inviteContractor()">
        Invite New Contractor
    </button>
    <button mat-raised-button (click)="addContractor()">
        Add New Contractor (No Account)
    </button>
</div>
<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">task_alt</mat-icon>
            Awaiting Approval
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <small *ngIf="unApprovedContractors?.length === 0"
            >There are no Contractors waiting for approval</small
        >
        <mat-action-list dense>
            <button
                *ngFor="let contractor of unApprovedContractors"
                [routerLink]="[
                    '/home-page/view-contractor',
                    contractor.applicationId
                ]"
                mat-list-item
            >
                <mat-icon slot="start">work</mat-icon>
                {{ contractor.companyName }}
            </button>
        </mat-action-list>
    </mat-card-content>
</mat-card>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">people</mat-icon>
            Approved Contractors
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <mat-form-field appearance="outline">
            <mat-label>Filter Company Name</mat-label>
            <input
                [(ngModel)]="filterString"
                (ngModelChange)="filterContractors()"
                [disabled]="!approvedContractors"
                matInput
            />
        </mat-form-field>
        <small *ngIf="approvedContractors?.length === 0"
            >There are no Approved Contractors matching the selection</small
        >
        <mat-label
            *ngIf="approvedContractors && approvedContractors?.length !== 0"
            id="results"
        >
            Results: {{ approvedContractors.length }}
        </mat-label>
        <mat-action-list dense>
            <button
                *ngFor="let contractor of approvedContractors"
                [routerLink]="[
                    '/home-page/view-contractor',
                    contractor.applicationId
                ]"
                mat-list-item
                matLine
            >
                <mat-icon slot="start">work</mat-icon>
                <div>
                    <mat-label matLine>{{ contractor.companyName }}</mat-label>
                    <small *ngIf="contractor.expired" matLine class="expired"
                        >Expired Documents</small
                    >
                </div>
            </button>
        </mat-action-list>
    </mat-card-content>
</mat-card>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>
