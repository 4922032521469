import { Component, OnInit } from '@angular/core';
import { BudgetHeads } from '../../../constants/budgets';
import { BudgetsService } from '../../../services/budgets/budgets.service';
import { firstValueFrom } from 'rxjs';
import { Budget } from '../../../interfaces/budget-interfaces';
import { BudgetSchedules } from '../../../constants/schedules';
import { HttpClient } from '@angular/common/http';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import { AuthServiceService } from '../../../services/auth-service/auth-service.service';

@Component({
    selector: 'app-budgets',
    templateUrl: './budgets.component.html',
    styleUrls: ['./budgets.component.css'],
})
export class BudgetsComponent implements OnInit {
    loading = true;
    updating = true;
    totalValue = 0;
    budgetYears: string[] = [];
    currentBudgetYear!: string;
    nextBudgetYear!: string;
    userGroups: (string | number)[] = [];
    budgetHeads = [...BudgetHeads].sort((a, b) => a.name.localeCompare(b.name));
    reserveHeads = BudgetSchedules;
    selectedBudgets!: Budget[];
    selectedYears: string[] = [];

    reserveOpen: boolean = false;

    constructor(
        public budgetService: BudgetsService,
        public propertyService: PropertyDetailsService,
        public http: HttpClient,
        public auth: AuthServiceService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            const budgetResponse = await firstValueFrom(
                await this.budgetService.getAllYears()
            );
            this.userGroups = await this.auth.getGroups();
            const budgetYear = this.budgetService.calculateBudgetYear();
            this.nextBudgetYear =
                (+budgetYear + 1).toString() +
                '-' +
                (+budgetYear + 2).toString();
            this.budgetYears = budgetResponse
                .map((budget) => budget.budgetYear)
                .filter((budget) => budget !== this.nextBudgetYear)
                .sort((a, b) => +a.split('-')[0] - +b.split('-')[0]);
            this.currentBudgetYear =
                this.budgetYears.find(
                    (budget) => budget.split('-')[0] === budgetYear
                ) || '';
            this.selectedYears.push(this.currentBudgetYear);
            await this.updateSelectedBudgets();
        } catch (error: any) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    getModelValue(head: { code: any }, budget: { budgetValues: any[] }) {
        return budget.budgetValues.find(
            (budgetCodes) => budgetCodes.code === head.code
        ).value;
    }

    async updateSelectedBudgets() {
        if (this.selectedYears.length > 0 && this.selectedYears.length <= 3) {
            this.updating = true;
            this.selectedBudgets = await firstValueFrom(
                await this.budgetService.getSelectedBudgets(this.selectedYears)
            );
            this.selectedBudgets = this.selectedBudgets.sort(
                (a, b) =>
                    +a.budgetYear.split('-')[0] - +b.budgetYear.split('-')[0]
            );
            this.updating = false;
        }
    }

    calculateTotal(budget: Budget, code: string): string {
        return budget.budgetValues
            .filter((head) => head.code.includes(code))
            .map((head) => head.value)
            .reduce((a, b) => a + b || 0, 0)
            .toString();
    }

    calculateCatValue(subCats: any[] | undefined, budget: Budget): string {
        const catCodes = subCats?.map((cat) => cat.code) || [];
        return budget.budgetValues
            .filter((head) => catCodes.includes(head.code))
            .reduce((a, b) => a + b.value, 0)
            .toString();
    }
}
