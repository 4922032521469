import { Component, OnInit } from '@angular/core';
import {
    Audit,
    PropertyDetail,
    Transaction,
} from '../../../interfaces/property';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageKeys } from '../../../enums/storage';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    get,
    isEqual,
    isObject,
    reject,
    set,
    startCase,
    transform,
} from 'lodash';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import { firstValueFrom, Observable } from 'rxjs';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { AuditService } from '../../../services/audit/audit.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NoteModalComponent } from '../../../components/note-modal/note-modal.component';
import { InfoModalComponent } from '../../../components/info-modal/info-modal.component';
import { ConfirmModalComponent } from '../../../components/confirm-modal/confirm-modal.component';
import { ReversePipe } from 'ngx-pipes';
import { FileUploadComponent } from '../../../components/file-upload/file-upload.component';
import { Location } from '@angular/common';
import { PaymentModalComponent } from '../../../components/payment-modal/payment-modal.component';
import { isToday } from 'date-fns';
import { AuthServiceService } from '../../../services/auth-service/auth-service.service';
import { AccountModalComponent } from '../../../components/account-modal/account-modal.component';
import { ServiceChargeModalComponent } from '../../../components/service-charge-modal/service-charge-modal.component';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

export interface SectionConfig {
    headerDetails: HeaderConfig;
    data: DataConfig[];
}

export interface HeaderConfig {
    section: string;
    icon: string;
    sectionName: string;
    subSections?: string[];
}

export interface DataConfig {
    fieldName: string;
    type: string;
    disabled: boolean;
    required: any[];
    subSection?: string;
}

@Component({
    selector: 'app-property-result',
    templateUrl: './property-result.component.html',
    styleUrls: ['./property-result.component.css'],
    providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
        ReversePipe,
    ],
})
export class PropertyResultComponent implements OnInit {
    serviceCharges: string[] = [];
    paymentButtons: boolean[] = [];
    startCase = startCase;
    messageBanner: boolean = false;
    cameFrom!: string | null;
    emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
    messageText: string = '';
    storageProperty: string | null;
    saving: boolean = false;
    propertyDetails: PropertyDetail;
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;
    propertyFiles: string[] = [];
    propertyFolderPath!: string | '';
    propertyForm: FormGroup;
    originalValuesForm: any;
    minPaymentDate: Date = new Date(
        new Date().setUTCDate(new Date().getDate() - 180)
    );
    propertyConfig: SectionConfig[] = [
        {
            headerDetails: {
                section: 'Property Address',
                icon: 'apartment',
                sectionName: '',
            },
            data: [
                {
                    fieldName: 'unit',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'unitFull',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'unitCourt',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'unitHouse',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'unitRoad',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'unitPostCode',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'unitCity',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'unitType',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'carParkSpace',
                    type: 'text',
                    disabled: true,
                    required: [],
                },
            ],
        },
        {
            headerDetails: {
                section: 'Owner Details',
                icon: 'person',
                sectionName: 'ownerDetails.',
            },
            data: [
                {
                    fieldName: 'ownedSince',
                    type: 'date',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'name',
                    type: 'text',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    fieldName: 'phone',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'email1',
                    type: 'text',
                    disabled: false,
                    required: [Validators.email],
                },
                {
                    fieldName: 'email2',
                    type: 'text',
                    disabled: false,
                    required: [Validators.email],
                },
                {
                    fieldName: 'addressLine1',
                    type: 'text',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    fieldName: 'addressLine2',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'addressLine3',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'postcode',
                    type: 'text',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    fieldName: 'city',
                    type: 'text',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    fieldName: 'county',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'country',
                    type: 'text',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    fieldName: 'postCorrespondence',
                    type: 'select',
                    disabled: false,
                    required: [],
                },
            ],
        },
        {
            headerDetails: {
                section: 'Boiler Details',
                icon: 'shower',
                sectionName: 'boilerDetails.',
            },
            data: [
                {
                    fieldName: 'planMember',
                    type: 'select',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'lastServiced',
                    type: 'date',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'preferredContractor',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    fieldName: 'valveInstalled',
                    type: 'date',
                    disabled: false,
                    required: [],
                },
            ],
        },
        {
            headerDetails: {
                section: 'RTM Details',
                icon: 'manage_accounts',
                sectionName: 'rtmDetails.',
            },
            data: [
                {
                    fieldName: 'rtmGroup',
                    type: 'text',
                    disabled: true,
                    required: [Validators.required],
                },
                {
                    fieldName: 'rtmMember',
                    type: 'select',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    fieldName: 'rtmEmail',
                    type: 'text',
                    disabled: false,
                    required: [Validators.email],
                },
            ],
        },
        {
            headerDetails: {
                section: 'Sub Let Details',
                icon: 'supervisor_account',
                sectionName: 'subLetDetails.',
                subSections: ['agentDetails', 'occupierDetails', 'petConsent'],
            },
            data: [
                {
                    fieldName: 'subLet',
                    type: 'select',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    fieldName: 'subLetExpiry',
                    type: 'date',
                    disabled: false,
                    required: [],
                },
                {
                    subSection: 'agentDetails.',
                    fieldName: 'agentName',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    subSection: 'agentDetails.',
                    fieldName: 'agentEmail',
                    type: 'text',
                    disabled: false,
                    required: [Validators.email],
                },
                {
                    subSection: 'agentDetails.',
                    fieldName: 'agentPhone',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    subSection: 'occupierDetails.',
                    fieldName: 'occupierName',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    subSection: 'occupierDetails.',
                    fieldName: 'occupierEmail',
                    type: 'text',
                    disabled: false,
                    required: [Validators.email],
                },
                {
                    subSection: 'occupierDetails.',
                    fieldName: 'occupierPhone',
                    type: 'text',
                    disabled: false,
                    required: [],
                },
                {
                    subSection: 'petConsent.',
                    fieldName: 'consentGiven',
                    type: 'select',
                    disabled: false,
                    required: [Validators.required],
                },
                {
                    subSection: 'petConsent.',
                    fieldName: 'consentDetails',
                    type: 'textArea',
                    disabled: false,
                    required: [],
                },
            ],
        },
    ];

    paymentArray: Transaction[] = [];
    accountBalance = 0;

    showAllPayments: boolean = false;
    userName!: string;
    userGroups!: (string | number)[];

    constructor(
        public router: Router,
        public propertyDetailsService: PropertyDetailsService,
        public propDetailsService: PropertyDetailsService,
        public auditService: AuditService,
        public dialog: MatDialog,
        public reversePipe: ReversePipe,
        public location: Location,
        private activatedRoute: ActivatedRoute,
        public auth: AuthServiceService,
        public http: HttpClient
    ) {
        this.propertyForm = new FormGroup({});
        this.storageProperty = localStorage.getItem(StorageKeys.PropertyDetail);
        this.propertyDetails = this.storageProperty
            ? JSON.parse(this.storageProperty)
            : {};

        for (let section of this.propertyConfig) {
            section.data.forEach((value: DataConfig) => {
                this.propertyForm.addControl(
                    value.fieldName,
                    new FormControl(
                        {
                            value: get(
                                this.propertyDetails,
                                `${section.headerDetails.sectionName}${value?.subSection || ''}${value.fieldName}`
                            ),
                            disabled: value.disabled,
                        },
                        { validators: value.required }
                    )
                );
            });
        }

        this.originalValuesForm = this.propertyForm.getRawValue();
    }

    /**
     * Angular Lifecycle event
     */
    async ngOnInit(): Promise<void> {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.cameFrom = params['from'];
        });

        this.userName = await this.auth.getUserName();
        this.userGroups = await this.auth.getGroups();

        let stringDetails: string | null = localStorage.getItem(
            StorageKeys.PropertyDetail
        );

        if (stringDetails) {
            this.propertyDetails = JSON.parse(stringDetails);
            this.propertyFolderPath =
                this.propertyDetails?.unitFull.replace(/ /g, '_') + '/';
            this.updateFiles();
            if (this.userGroups?.includes('PropertyDetailsFull'))
                this.updatePaymentList(this.propertyDetails.unitFull);
        }

        this.serviceCharges = await firstValueFrom(this.getFileList());
        this.serviceCharges.sort((a, b) =>
            +b.split('_')[1] - +a.split('_')[1] === 0
                ? b.localeCompare(a)
                : +b.split('_')[1] - +a.split('_')[1]
        );

        if (!this.userGroups?.includes('PropertyDetailsFull'))
            this.propertyForm.disable();
    }

    getFileList(): Observable<string[]> {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        let params = new HttpParams().append('serviceCharge', true);
        return this.http.get<string[]>(this.apiBaseUrl + '/filelist/', {
            headers,
            params,
        });
    }

    async updatePaymentList(unitFull: string) {
        this.paymentArray = (
            await firstValueFrom(
                this.propDetailsService.getPaymentList(unitFull)
            )
        ).sort(
            (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
        );

        this.accountBalance = this.paymentArray.reduce((total, trans) => {
            return ['Charge', 'Refund Payment'].includes(trans.transactionType)
                ? total + trans.amount
                : total - trans.amount;
        }, 0);

        this.paymentButtons.fill(false, 0, this.paymentArray.length);
    }

    /** Get File List */
    updateFiles = (): void => {
        let stringFiles = localStorage.getItem(StorageKeys.PropertyFiles);
        if (stringFiles) {
            const fileArray: string[] = JSON.parse(stringFiles) || [];
            this.propertyFiles = fileArray.map((file) =>
                file.replace(this.propertyFolderPath, '')
            );
        } else {
            this.propertyFiles = [];
        }
    };

    /**
     * Reset form to stored values
     */
    resetForm = (): void => {
        for (let section of this.propertyConfig) {
            section.data.forEach((value: DataConfig) => {
                this.propertyForm.controls[value.fieldName].setValue(
                    get(
                        this.propertyDetails,
                        `${section.headerDetails.sectionName}${value?.subSection || ''}${value.fieldName}`
                    ),
                    {
                        onlySelf: true,
                        emitEvent: false,
                    }
                );
            });
        }
        this.propertyForm.markAsPristine();
    };

    /**
     * Check if Origin data has changed since page was loaded
     */
    checkOriginHasNotChanged = async (): Promise<boolean> => {
        let remoteDetails: PropertyDetail = await firstValueFrom(
            this.propDetailsService.callPropertyEndpoint(
                this.propertyDetails.unitFull
            )
        );
        let originSame =
            (!remoteDetails?.version && !this.propertyDetails?.version) ||
            remoteDetails.version === this.propertyDetails.version;
        if (!originSame) {
            this.openInfoDialog(
                'Origin has changed, reloading Data. Please re-enter changes'
            );
            this.propertyDetails = remoteDetails;
            localStorage.setItem(
                StorageKeys.PropertyDetail,
                JSON.stringify(remoteDetails)
            );
            this.resetForm();
        }
        return originSame;
    };

    /**
     * Deep diff between two object, using lodash
     * @param  {Object} object Object compared
     * @param  {Object} base   Object to compare with
     * @return {Object}        Return a new object who represent the diff
     */
    difference = (object: object, base: object): object => {
        function changes(object: object, base: { [x: string]: any }) {
            return transform(
                object,
                function (result: object, value, key: string) {
                    if (!isEqual(value, base[key])) {
                        set(
                            result,
                            key,
                            isObject(value) && isObject(base[key])
                                ? changes(value, base[key])
                                : value
                        );
                    }
                }
            );
        }

        return changes(object, base);
    };

    /**
     * Save new values
     */
    saveChanges = async (): Promise<any> => {
        this.saving = true;
        if (!(await this.checkOriginHasNotChanged())) {
            this.saving = false;
            return;
        }

        let propertyDetails = this.propertyForm.getRawValue();
        let mappedDetails: PropertyDetail = {} as PropertyDetail;

        for await (let section of this.propertyConfig) {
            section.data.forEach((value: DataConfig) => {
                set(
                    mappedDetails,
                    `${section.headerDetails.sectionName}${value?.subSection || ''}${value.fieldName}`,
                    propertyDetails[value.fieldName]
                );
            });
        }

        let updateDiff = this.difference(mappedDetails, this.propertyDetails);
        let updateAudit: Audit = await this.auditService.createAudit(
            'Update Details',
            `Updated the following fields: ${JSON.stringify(this.getDirtyFormValues())}`
        );

        mappedDetails.audits = [...this.propertyDetails.audits, updateAudit];
        mappedDetails.ownership = '';
        mappedDetails.version = (this.propertyDetails?.version || 0) + 1;

        try {
            const submitResponse = await firstValueFrom(
                await this.propertyDetailsService.callUpdatePropertyEndpoint(
                    mappedDetails
                )
            );
            this.propertyDetails = mappedDetails;
            localStorage.setItem(
                StorageKeys.PropertyDetail,
                JSON.stringify(mappedDetails)
            );
            this.originalValuesForm = this.propertyForm.getRawValue();

            this.propertyForm.markAsPristine();
            this.showMessageBanner(submitResponse.body);
        } catch {
            this.openInfoDialog(
                'Cannot connect to Server. Changes have not been saved.'
            );
        } finally {
            this.saving = false;
        }
    };

    getDirtyFormValues() {
        let updatedValues = {};
        for (const control in this.propertyForm.controls) {
            if (this.propertyForm.controls[control].dirty) {
                updatedValues = {
                    ...updatedValues,
                    [control]: {
                        original: this.originalValuesForm[control],
                        new: this.propertyForm.controls[control].value,
                    },
                };
            }
        }
        return updatedValues;
    }

    /**
     * Show Message banner
     *
     * @param text
     */
    showMessageBanner = (text: string): void => {
        this.messageText = text;
        this.messageBanner = true;
        setTimeout(() => (this.messageBanner = false), 3000);
    };

    /**
     * * Calculate indexes for object subsection
     * @param section
     */
    calcSubSectionIndexes = (section: SectionConfig): number[] => {
        let subSection = section.headerDetails.subSections;
        let indexes: number[] = [];
        subSection?.forEach((subSection) => {
            indexes.push(
                section.data.findIndex(
                    (element) => element.subSection === subSection + '.'
                )
            );
        });
        return indexes;
    };

    /**
     * * Delete audit
     * @param audit
     */
    removeAudit = async (audit: Audit) => {
        if (this.propertyForm.dirty) {
            this.openInfoDialog(
                'Please Save or Cancel changes before removing a Note.'
            );
            return;
        }

        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this note?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                let remoteDetails: PropertyDetail = await firstValueFrom(
                    this.propDetailsService.callPropertyEndpoint(
                        this.propertyDetails.unitFull
                    )
                );
                let updateAudits = reject(remoteDetails.audits, audit);

                const version = (remoteDetails?.version || 0) + 1;
                firstValueFrom(
                    await this.propertyDetailsService.callUpdatePropertyEndpoint(
                        {
                            audits: updateAudits,
                            unitFull: this.propertyDetails.unitFull,
                            version,
                        } as PropertyDetail
                    )
                ).then((response) => {
                    this.propertyDetails = {
                        ...remoteDetails,
                        audits: updateAudits,
                        version,
                    };
                    localStorage.setItem(
                        StorageKeys.PropertyDetail,
                        JSON.stringify(this.propertyDetails)
                    );
                    this.resetForm();
                    this.showMessageBanner(response.body);
                });
            }
        });
    };

    /**
     * Open info dialog
     * @param message
     */
    openInfoDialog = (message: string): void => {
        this.dialog.open(InfoModalComponent, {
            width: '500px',
            data: { message },
        });
    };

    /**
     * Open note dialog
     */
    openNoteDialog = (): any => {
        if (this.propertyForm.dirty) {
            this.openInfoDialog(
                'Please Save or Cancel changes before adding a Note.'
            );
            return;
        }

        const dialogRef = this.dialog.open(NoteModalComponent, {
            width: '500px',
            data: {},
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                let remoteDetails: PropertyDetail = await firstValueFrom(
                    this.propDetailsService.callPropertyEndpoint(
                        this.propertyDetails.unitFull
                    )
                );
                if (!remoteDetails?.audits) remoteDetails.audits = [];
                remoteDetails.audits.push(
                    await this.auditService.createAudit('Note', result)
                );
                remoteDetails.version = (remoteDetails?.version || 0) + 1;

                firstValueFrom(
                    await this.propertyDetailsService.callUpdatePropertyEndpoint(
                        remoteDetails
                    )
                )
                    .then((response) => {
                        this.propertyDetails = remoteDetails;
                        localStorage.setItem(
                            StorageKeys.PropertyDetail,
                            JSON.stringify(remoteDetails)
                        );
                        this.resetForm();
                        this.showMessageBanner(response.body);
                        return;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        });
    };

    /**
     * * View file
     * @param fileName
     */
    openFile(fileName: string): void {
        const windowRef = window.open();
        const filePath = this.propertyFolderPath + fileName;

        firstValueFrom(
            this.propertyDetailsService.getPresignedUrl(filePath, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * * View file
     * @param fileName
     */
    openServiceCharge(fileName: string): void {
        const windowRef = window.open();
        const filePath = `${fileName}/${this.propertyDetails.unitCourt}/${this.propertyDetails.unitFull}.pdf`;

        firstValueFrom(
            this.propertyDetailsService.getServiceCharge(filePath, 'Get')
        ).then((url) => {
            if (windowRef) windowRef.location = url;
        });
    }

    /**
     * Open file upload modal
     */
    openFileUploadModal = (): void => {
        const fileRef = this.dialog.open(FileUploadComponent, {
            width: '500px',
            data: {
                folderPath: this.propertyFolderPath,
                unitFull: this.propertyDetails.unitFull,
                unitFiles: this.propertyFiles,
            },
            disableClose: true,
        });

        fileRef.afterClosed().subscribe(() => {
            this.updateFiles();
        });
    };

    /**
     * Delete file for property
     * @param file
     */
    deleteFile(file: string): void {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: { message: 'Are you sure you want to delete this file?' },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                const key = this.propertyFolderPath + file;
                (await this.propertyDetailsService.deleteFile(key)).subscribe(
                    async () => {
                        const files = await firstValueFrom(
                            await this.propertyDetailsService.getFileList(
                                this.propertyDetails.unitFull
                            )
                        );
                        files
                            ? localStorage.setItem(
                                  StorageKeys.PropertyFiles,
                                  JSON.stringify(files)
                              )
                            : localStorage.removeItem(
                                  StorageKeys.PropertyFiles
                              );
                        this.updateFiles();
                    }
                );
            }
        });
    }

    async canDeactivate() {
        return new Promise((resolve) => {
            if (this.propertyForm.dirty) {
                const dialogRef = this.dialog.open(ConfirmModalComponent, {
                    width: '500px',
                    data: {
                        message:
                            'You have unsaved changes that will be lost, are you sure?',
                    },
                });

                dialogRef.afterClosed().subscribe(async (result) => {
                    if (!result) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            } else {
                resolve(true);
            }
        });
    }

    openPaymentModal = () => {
        const dialog = this.dialog.open(PaymentModalComponent, {
            width: '500px',
            data: { unitFull: this.propertyDetails.unitFull },
        });

        dialog
            .afterClosed()
            .subscribe(() =>
                this.updatePaymentList(this.propertyDetails.unitFull)
            );
    };

    openAccountModal = () => {
        const dialog = this.dialog.open(AccountModalComponent, {
            width: '500px',
            data: { unitFull: this.propertyDetails.unitFull },
        });
    };

    async deleteTransaction(id: string, message: string) {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
            width: '500px',
            data: {
                message: `Are you sure you want to delete this Transaction? \n ${message}`,
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                await firstValueFrom(
                    this.propDetailsService.removeTransaction(
                        id,
                        this.propertyDetails.unitFull
                    )
                );
                await this.updatePaymentList(this.propertyDetails.unitFull);
            }
        });
    }

    isToday(paymentDate: string): boolean {
        return isToday(new Date(paymentDate));
    }

    /**
     * Open file upload modal
     */
    openServiceChargeModal = (): void => {
        const fileRef = this.dialog.open(ServiceChargeModalComponent, {
            width: '500px',
            data: {
                serviceCharge: this.serviceCharges,
                unitFull: this.propertyDetails.unitFull,
            },
        });

        fileRef.afterClosed().subscribe(() => {
            // Update Service charge display
        });
    };

    dateBeforeMin(transDate: string): boolean {
        return this.minPaymentDate.getTime() > new Date(transDate).getTime();
    }

    isAutoCharge(desc: string): boolean {
        return [
            'Service Charge Demand',
            'Reserve Contributions',
            'Balancing Charges for year ending',
            'Boiler Servicing Scheme',
            'Balance from Ringley',
        ].some((charge) => desc.includes(charge));
    }
}
