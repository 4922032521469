import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ContractorDetails } from '../../pages/contractor-sign-up/contractor-sign-up.component';
import { ContractorsService } from '../../services/contractors/contractors.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-bank-details-modal',
    templateUrl: './bank-details-modal.component.html',
    styleUrls: ['./bank-details-modal.component.css'],
})
export class BankDetailsModalComponent implements OnInit {
    contractorDetails!: ContractorDetails;

    constructor(
        public contractorService: ContractorsService,
        @Inject(MAT_DIALOG_DATA)
        public data: { contractor: Partial<ContractorDetails> }
    ) {}

    async ngOnInit(): Promise<void> {
        if (!this.data.contractor.applicationId) return;
        this.contractorDetails = await firstValueFrom(
            this.contractorService.getContractorDetails(
                this.data.contractor.applicationId
            )
        );
    }
}
