import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexStroke,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexYAxis,
    ChartComponent,
} from 'ng-apexcharts';
import { WorkOrder } from '../../pages/work-orders/work-orders.component';
import { format, isSameMonth, parseISO } from 'date-fns';

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis: ApexXAxis;
    yaxis: ApexYAxis;
    title: ApexTitleSubtitle;
    dataLabels: ApexDataLabels;
    stroke: ApexStroke;
    colors: string[];
    fill: ApexFill;
};

@Component({
    selector: 'app-variance-chart',
    templateUrl: './variance-chart.component.html',
    styleUrls: ['./variance-chart.component.css'],
})
export class VarianceChartComponent implements OnInit, OnChanges {
    @ViewChild('chart') chart!: ChartComponent;
    @Input() totalBudget: number = 0;
    @Input() budgetYear: string = '';
    @Input() workOrders: WorkOrder[] = [];
    budgetStartYear: number = 0;

    public chartOptions!: Partial<ChartOptions>;
    public chartOptions2!: Partial<ChartOptions>;

    constructor() {}

    ngOnInit(): void {}

    ngOnChanges() {
        this.budgetStartYear = +this.budgetYear.split('-')[0];

        this.chartOptions = {
            colors: ['#6e97c9', '#FFA500'],
            series: [
                {
                    name: 'Cumulative Budget',
                    type: 'line',
                    data: Array.from({ length: 12 }, (_, i) => i + 1).map(
                        (month) => (this.totalBudget / 12) * month
                    ),
                },
                {
                    name: 'Cumulative Expenses',
                    data: this.calculateCumulativeExpenseData(),
                    type: 'line',
                },
            ],
            chart: {
                height: 250,
                type: 'line',
                fontFamily: 'Poppins, sans-serif',
                redrawOnParentResize: true,
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth',
            },
            title: {
                text: 'Cumulative Variance (No Reserves)',
            },
            xaxis: {
                categories: this.arrayOfMonths().map((date) =>
                    format(date, 'MMM')
                ),
            },
            yaxis: {
                title: { text: 'Amount ( £ )' },
                labels: {
                    formatter: (value) => {
                        return `£ ${this.numberWithCommas(Math.round(value))}`;
                    },
                },
            },
        };

        this.chartOptions2 = {
            colors: ['#6e97c9', '#FFA500', '#c5c6c8'],
            fill: { type: 'solid', opacity: 1 },
            stroke: {
                show: true,
                lineCap: 'round',
                width: [5, 0, 0],
            },
            series: [
                {
                    name: 'Monthly Budget',
                    data: Array.from({ length: 12 }, (_, i) => i + 1).map(
                        (month) => this.totalBudget / 12
                    ),
                    type: 'line',
                },
                {
                    name: 'Monthly Expenses',
                    data: this.calcuateMonthlyExpenses(),
                    type: 'bar',
                },
                {
                    name: 'Monthly Res. Allocations',
                    data: this.calculateMonthlyReserves(),
                    type: 'bar',
                },
            ],
            chart: {
                height: 250,
                type: 'line',
                stacked: true,
                fontFamily: 'Poppins, sans-serif',
                redrawOnParentResize: true,
            },
            dataLabels: {
                enabled: false,
            },

            title: {
                text: 'Monthly Variance',
            },
            xaxis: {
                categories: this.arrayOfMonths().map((date) =>
                    format(date, 'MMM')
                ),
            },
            yaxis: {
                title: { text: 'Amount ( £ )' },
                labels: {
                    formatter: (value) => {
                        return `£ ${this.numberWithCommas(Math.round(value))}`;
                    },
                },
            },
        };
    }

    numberWithCommas(x: number) {
        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
    }

    calculateCumulativeExpenseData = () =>
        this.arrayOfMonths().map((month, index, array) => {
            const orders = this.workOrders
                .filter((order) => order.expenseCategory !== 'RES')
                .filter((workOrder) => {
                    const approvalDate = workOrder.audits?.find(
                        (audit) => audit.event === 'Invoice Approved'
                    )!.date;
                    if (!approvalDate) return false;

                    return array
                        .slice(0, index + 1)
                        .some((prevMonth) =>
                            isSameMonth(prevMonth, parseISO(approvalDate))
                        );
                });
            return orders
                .map(
                    (order) =>
                        Object.values(order.leaseGroups).reduce(
                            (a, b) => a + b
                        ),
                    0
                )
                .reduce((a, b) => a + b, 0);
        });

    calcuateMonthlyExpenses = () =>
        this.arrayOfMonths()
            .map((month) => {
                const orders = this.workOrders
                    .filter((order) => order.expenseCategory !== 'RES')
                    .filter((workOrder) => {
                        const approvalDate = workOrder.audits?.find(
                            (audit) => audit.event === 'Invoice Approved'
                        )!.date;
                        if (!approvalDate) return false;

                        return isSameMonth(month, parseISO(approvalDate));
                    });
                return orders
                    .map(
                        (order) =>
                            Object.values(order.leaseGroups).reduce(
                                (a, b) => a + b
                            ),
                        0
                    )
                    .reduce((a, b) => a + b, 0);
            })
            .map((val) => (val !== 0 ? val : null));

    calculateMonthlyReserves = () =>
        this.arrayOfMonths()
            .map((month) => {
                const orders = this.workOrders
                    .filter((order) => order.expenseCategory === 'RES')
                    .filter((workOrder) => {
                        const approvalDate = workOrder.audits?.find(
                            (audit) => audit.event === 'Invoice Approved'
                        )!.date;
                        if (!approvalDate) return false;

                        return isSameMonth(month, parseISO(approvalDate));
                    });
                return orders
                    .map(
                        (order) =>
                            Object.values(order.leaseGroups).reduce(
                                (a, b) => a + b
                            ),
                        0
                    )
                    .reduce((a, b) => a + b, 0);
            })
            .map((val) => (val !== 0 ? val : null));

    arrayOfMonths = () =>
        Array.from({ length: 12 }, (_, month) =>
            parseISO(
                new Date(this.budgetStartYear, month + 8, 10).toISOString()
            )
        );
}
