<header>
    <mat-card-title>
        <mat-icon>currency_pound</mat-icon>
        Add Payment / Charge
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<div *ngIf="completed" class="formDiv">
    <mat-label>Payment Added</mat-label>
</div>

<div *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<div [formGroup]="paymentForm" class="formDiv" *ngIf="!loading && !completed">
    <mat-form-field appearance="outline">
        <mat-label>Transaction Type</mat-label>
        <mat-select formControlName="transactionType">
            <mat-option [value]="'Payment'">Payment</mat-option>
            <mat-option [value]="'Charge'">Charge</mat-option>
            <mat-option [value]="'Refund Payment'">Refund Payment</mat-option>
            <mat-option [value]="'Refund Charge'">Refund Charge</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Date </mat-label>
        <input
            matInput
            [max]="maxDate"
            [min]="minDate"
            [matDatepicker]="picker"
            placeholder="DD/MM/YYYY"
            [formControlName]="'date'"
            (click)="picker.open()"
            readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Description </mat-label>
        <textarea
            matInput
            rows="3"
            [formControlName]="'description'"
        ></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Amount </mat-label>
        <input
            currencyMask
            [options]="{ prefix: '£ ' }"
            matInput
            autocomplete="off"
            placeholder="0.00"
            [formControlName]="'amount'"
        />
    </mat-form-field>
    <div class="buttonDiv">
        <button
            mat-raised-button
            [mat-dialog-close]="null"
            class="cancelButton"
        >
            Cancel
        </button>
        <button
            mat-raised-button
            [disabled]="paymentForm.invalid || loading"
            (click)="addTransaction()"
            class="saveButton"
        >
            Add
        </button>
    </div>
</div>
