<mat-toolbar class="footer" *ngIf="showToolBar">
    <button
        mat-flat-button
        id="cancelButton"
        *ngIf="cancelButton"
        (click)="onCancelClick()"
    >
        <mat-icon class="footerBut">cancel</mat-icon>
        <mat-label>Cancel Changes</mat-label>
    </button>
    <button
        mat-flat-button
        id="saveButton"
        *ngIf="saveButton"
        (click)="onSaveClick()"
        [disabled]="saveDisabled"
    >
        <mat-icon class="footerBut">save</mat-icon>
        <mat-label>Save Changes</mat-label>
    </button>
</mat-toolbar>
