<header>
    <mat-card-title>Forgotten Password</mat-card-title>
    <button [mat-dialog-close]="null" mat-icon-button>
        <mat-icon>close</mat-icon>
    </button>
</header>

<div *ngIf="enterEmail">
    <mat-label>Please Enter your Email Address</mat-label>
    <br />
    <mat-form-field>
        <mat-label>Email</mat-label>
        <input [(ngModel)]="email" matInput />
    </mat-form-field>
    <button (click)="submitEmail()" mat-raised-button>Submit</button>
</div>

<div *ngIf="!enterEmail" [formGroup]="passwordForm">
    <mat-label
        >A Verification Code has been sent to your E-Mail Address</mat-label
    >
    <br />
    <mat-form-field>
        <mat-label>Verification Code</mat-label>
        <input formControlName="verificationCode" matInput />
    </mat-form-field>
    <mat-label>Please Create a new Password</mat-label>
    <br />
    <mat-form-field>
        <mat-label>New Password</mat-label>
        <input formControlName="newPassword" matInput type="password" />
    </mat-form-field>
    <mat-form-field>
        <mat-label>Confirm Password</mat-label>
        <input formControlName="confirmPassword" matInput type="password" />
    </mat-form-field>
    <ul>
        <li [class]="code ? 'valid' : 'invalid'">Verification Code</li>
        <li [class]="match ? 'valid' : 'invalid'">Passwords must match</li>
        <li [class]="oneNumber ? 'valid' : 'invalid'">At least one number</li>
        <li [class]="oneSpec ? 'valid' : 'invalid'">
            At least one Special Character
        </li>
        <li [class]="oneUpper ? 'valid' : 'invalid'">
            At least one Uppercase Character
        </li>
        <li [class]="oneLower ? 'valid' : 'invalid'">
            At least one Lowercase Character
        </li>
        <li [class]="minlength ? 'valid' : 'invalid'">Minimum Length 8</li>
        <br />
    </ul>
    <button
        (click)="createNew()"
        [disabled]="
            !(
                match &&
                oneNumber &&
                oneUpper &&
                oneLower &&
                minlength &&
                oneSpec &&
                verifyCode
            )
        "
        mat-raised-button
    >
        Create
    </button>
</div>
