<mat-list>
    <mat-list-item>
        <mat-label>{{ data.message }}</mat-label>
    </mat-list-item>
    <mat-list-item>
        <button mat-flat-button [mat-dialog-close]="false">No</button>
        <button mat-flat-button [mat-dialog-close]="true" color="warn">
            Yes
        </button>
    </mat-list-item>
</mat-list>
