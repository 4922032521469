import { Component, NgIterable, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Guid } from 'guid-typescript';
import { ContractorsService } from '../../services/contractors/contractors.service';
import { firstValueFrom, forkJoin } from 'rxjs';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

export interface SignUpForm {
    applicationId: FormControl;
    companyName: FormControl;
    contactName: FormControl;
    email: FormControl;
    phone: FormControl;
    addressOne: FormControl;
    addressTwo: FormControl;
    addressThree: FormControl;
    postcode: FormControl;
    consent: FormControl;
    files?: FormArray<FormGroup<{ fileType: FormControl; file: FormControl }>>;
    bankName?: FormControl;
    sortCode?: FormControl;
    accountNumber?: FormControl;
    accountName?: FormControl;
    workOrderEmails?: FormControl;
}

export interface ContractorDetails {
    applicationId: string;
    companyName: string;
    contactName: string;
    email: string;
    phone: string;
    addressOne: string;
    addressTwo: string;
    addressThree: string;
    postcode: string;
    consent: string;
    status: string;
    files: { fileType: string; filename: string }[];
    bankName?: string;
    sortCode?: string;
    accountNumber?: string;
    accountName?: string;
    noAccount?: boolean;
    deletionMarker?: boolean;
    expired?: boolean;
    workOrderEmails?: boolean;
}

@Component({
    selector: 'app-contractor-sign-up',
    templateUrl: './contractor-sign-up.component.html',
    styleUrls: ['./contractor-sign-up.component.css'],
})
export class ContractorSignUpComponent implements OnInit {
    applicationId!: Guid;
    documentTypes: string[] = [
        'Ladders & Harnesses',
        'Equal Opportunities Policy',
        'Construction Industry Scheme',
        'Environmental Policy',
        'Heath and Safety Policy',
        'Risk Assessment Policy',
        'Lone Workers Policy',
        'NICEIC Certificate',
        `Employer's Liability Insurance`,
        'Public Liability Insurance',
        'BAFE Certificate',
        'Other',
    ];
    fileFields: any[] = [{}];
    signUpForm!: FormGroup;
    contractorDetails!: ContractorDetails;
    submitting = false;
    successfulSubmit = false;
    showFileTypeMessage = false;
    code: string = 'abh345jrf455';
    queryCode: string | undefined;
    queryEmail: string | undefined;
    errorMessage: string | undefined;
    showWrongType: boolean = false;

    constructor(
        public contractorService: ContractorsService,
        public propertyService: PropertyDetailsService,
        public route: ActivatedRoute
    ) {}

    get filesArray() {
        return this.signUpForm.controls['files'] as FormArray<
            FormGroup<{
                fileType: FormControl;
                file: FormControl;
            }>
        > &
            NgIterable<any>;
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.queryCode = params['code'];
            this.queryEmail = params['email'];
        });

        this.signUpForm = new FormGroup<SignUpForm>({
            applicationId: new FormControl(
                { value: Guid.create().toString(), disabled: true },
                Validators.required
            ),
            companyName: new FormControl(
                { value: null, disabled: false },
                Validators.required
            ),
            contactName: new FormControl(
                { value: null, disabled: false },
                Validators.required
            ),
            email: new FormControl(
                { value: this.queryEmail, disabled: false },
                [Validators.email, Validators.required]
            ),
            phone: new FormControl({ value: null, disabled: false }, [
                Validators.required,
                Validators.pattern(/^[0-9]*$/),
            ]),
            addressOne: new FormControl(
                { value: null, disabled: false },
                Validators.required
            ),
            addressTwo: new FormControl({ value: null, disabled: false }),
            addressThree: new FormControl({ value: null, disabled: false }),
            postcode: new FormControl(
                { value: null, disabled: false },
                Validators.required
            ),
            consent: new FormControl(
                { value: false, disabled: false },
                Validators.requiredTrue
            ),
            files: new FormArray([
                new FormGroup({
                    fileType: new FormControl({ value: null, disabled: false }),
                    file: new FormControl(),
                }),
            ]),
        });
    }

    removeFile(index: number) {
        (this.signUpForm.get('files') as FormArray).removeAt(index);
    }

    addFile() {
        (this.signUpForm.get('files') as FormArray).push(
            new FormGroup({
                fileType: new FormControl(''),
                file: new FormControl(),
            })
        );
    }

    validateFiles = () => {
        console.log(this.filesArray.value);
        const inValid: boolean = this.filesArray?.value.some(
            (fileObject) => fileObject.file && !fileObject.fileType
        );
        const wrongType: boolean = this.filesArray?.value.some(
            (fileObject) =>
                !!fileObject.file &&
                !this.isCorrectType(
                    ['application/pdf'],
                    fileObject.file as File
                )
        );
        this.showWrongType = wrongType;
        this.showFileTypeMessage = inValid;
        return !inValid && !wrongType;
    };

    async submit(): Promise<void> {
        if (this.signUpForm.valid && this.validateFiles()) {
            try {
                this.submitting = true;

                let { files, ...data } = this.signUpForm.getRawValue();
                files = files.filter((fileObject: any) => !!fileObject.file);
                const uploads = [];

                const response = await firstValueFrom(
                    await this.contractorService.callContractorApplicationEndpoint(
                        {
                            ...data,
                            status: 'unverified',
                        }
                    )
                );

                for (const upload of files) {
                    const preSignedUrl = await firstValueFrom(
                        await this.contractorService.getPresignedUrlNoAuth(
                            data.applicationId.toString() +
                                '/' +
                                upload.fileType +
                                '@$%&' +
                                upload.file.name,
                            'Put'
                        )
                    );
                    uploads.push(
                        this.propertyService.fileUpload(
                            preSignedUrl,
                            '',
                            upload.file
                        )
                    );
                }

                forkJoin(uploads).subscribe(async (event: HttpEvent<any>[]) => {
                    if (
                        event.every(
                            (type) => type.type === HttpEventType.Response
                        )
                    ) {
                        this.submitting = false;
                        this.successfulSubmit = true;
                    }
                });

                if (files.length === 0) {
                    this.submitting = false;
                    this.successfulSubmit = true;
                }
            } catch (error: any) {
                console.error(error);
                this.submitting = false;
                this.errorMessage = error.error;
            }
        }
    }

    isCorrectType = (fileTypes: string[], file: File): boolean => {
        return fileTypes.includes(file.type);
    };
}
