<app-page-toolbar [backButtonRoute]="'/home-page/finance-reports'">
</app-page-toolbar>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<mat-card>
    <mat-card-header>
        <mat-card-title>
            <mat-icon slot="start">analytics</mat-icon>
            Invoices Awaiting Payment
        </mat-card-title>
    </mat-card-header>
    <small *ngIf="!loading && workOrders.length === 0"
        >No Orders requiring Payment</small
    >
</mat-card>

<mat-accordion>
    <mat-expansion-panel *ngFor="let order of workOrders">
        <mat-expansion-panel-header>
            <mat-label class="numberField">#{{ order.orderNumber }}</mat-label>
            <mat-label class="companyField">{{
                order.contractorId?.companyName
            }}</mat-label>
        </mat-expansion-panel-header>

        <div class="columns">
            <ul class="displayDetails">
                <li>
                    <mat-label class="mat-body-strong"
                        >Work Order Details</mat-label
                    >
                </li>
                <li>
                    <mat-label class="fieldNames">Invoice Approved:</mat-label>
                    <mat-label class="fieldValues">{{
                        getApprovedDate(order) | date
                    }}</mat-label>
                </li>
                <li>
                    <mat-label class="fieldNames">Approved By:</mat-label>
                    <mat-label class="fieldValues">{{
                        getApprovedName(order)
                    }}</mat-label>
                </li>
                <li>
                    <mat-label class="fieldNames">Total Amount:</mat-label>
                    <mat-label class="fieldValues"
                        >£ {{ calculateAmount(order) }}</mat-label
                    >
                </li>
                <li>
                    <mat-label class="fieldNames">Description:</mat-label>
                    <mat-label class="fieldValues">{{
                        order.description
                    }}</mat-label>
                </li>
            </ul>

            <ul class="displayDetails">
                <li>
                    <mat-label class="mat-body-strong"
                        >Contractor Details</mat-label
                    >
                </li>
                <li>
                    <mat-label class="fieldNames">Company Name:</mat-label>
                    <mat-label class="fieldValues">{{
                        order.contractorId?.companyName
                    }}</mat-label>
                </li>
                <li>
                    <mat-label class="fieldNames">Contact Name:</mat-label>
                    <mat-label class="fieldValues">{{
                        order.contractorId?.contactName
                    }}</mat-label>
                </li>
            </ul>
        </div>

        <div class="panel-footer">
            <button
                (click)="openBankDialog(order)"
                class="invoice"
                mat-raised-button
            >
                Show Payment Details
            </button>
            <button
                (click)="openAuditDialog(order)"
                class="invoice"
                mat-raised-button
            >
                View Audits
            </button>
            <button
                (click)="openFile(order.orderNumber + '/invoice.pdf')"
                class="invoice"
                mat-raised-button
            >
                View Invoice
            </button>
            <button (click)="markAsPaid(order)" class="paid" mat-raised-button>
                Mark as Paid
            </button>
        </div>
    </mat-expansion-panel>
</mat-accordion>
