import { Injectable, OnDestroy } from '@angular/core';
import {
    firstValueFrom,
    merge,
    ReplaySubject,
    Subject,
    takeUntil,
    timer,
} from 'rxjs';
import { WorkOrder } from '../../pages/work-orders/work-orders.component';
import { ContractorDetails } from '../../pages/contractor-sign-up/contractor-sign-up.component';
import { WorkOrdersService } from '../work-orders/work-orders.service';
import { ContractorsService } from '../contractors/contractors.service';
import { AuthServiceService } from '../auth-service/auth-service.service';

@Injectable({
    providedIn: 'root',
})
export class NotificationService implements OnDestroy {
    approvals: ReplaySubject<{
        workOrder?: Partial<WorkOrder>[];
        contractor?: Partial<ContractorDetails>[];
        updatedAt: string;
    }> = new ReplaySubject(1);
    interval = 5000;
    destroy$ = new Subject<any>();
    updating!: boolean;

    approvalsTrigger$: Subject<any> = new Subject();

    constructor(
        public workOrderService: WorkOrdersService,
        public contractorService: ContractorsService,
        public auth: AuthServiceService
    ) {}

    ngOnDestroy() {
        this.destroy$.next(true);
    }

    triggerApprovals() {
        this.approvalsTrigger$.next(true);
    }

    startPolling(userGroups: (string | number)[]) {
        merge(timer(1, 600000), this.approvalsTrigger$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(async () => {
                if (!document.hidden) {
                    this.updating = true;
                    try {
                        this.approvals.next({
                            ...(userGroups.includes('WorkordersAccess') && {
                                workOrder: await firstValueFrom(
                                    this.workOrderService.getApprovals()
                                ),
                            }),
                            ...(userGroups.includes('ContractAccess') && {
                                contractor: await firstValueFrom(
                                    this.contractorService.getContractorApprovals()
                                ),
                            }),
                            updatedAt: new Date().toISOString(),
                        });
                    } catch {
                    } finally {
                        this.updating = false;
                    }
                }
            });
    }
}
