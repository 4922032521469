<div class="page-container">
    <div id="imgContainer">
        <mat-card>
            <img alt="" id="logo" src="assets/navLogo.png" />
            <div id="formDiv">
                <mat-form-field appearance="standard" (keydown.enter)="login()">
                    <mat-label>E-Mail</mat-label>
                    <input [(ngModel)]="email" matInput />
                </mat-form-field>
                <mat-form-field appearance="standard" (keydown.enter)="login()">
                    <mat-label>Password</mat-label>
                    <input
                        [(ngModel)]="password"
                        id="password"
                        matInput
                        type="password"
                    />
                </mat-form-field>

                <button (click)="login()" mat-raised-button>Sign In</button>
                <br />
                <a (click)="forgottenPassword()" class="forgotP">
                    <mat-label>Forgotten Password?</mat-label>
                </a>
            </div>
        </mat-card>
    </div>
    <div id="aboutUsContainer">
        <div id="aboutUs" hidden>
            <h2>About Us</h2>
            <p>
                Welcome to Century Wharf, one of Cardiff’s premier apartment
                complexes built on the east bank of the River Taff, south of the
                City Centre. This 10 acre residential development boasts over
                900 dwellings, set in beautiful landscaped gardens, providing
                Residents with a tranquil environment and a sense of space in
                the heart of Cardiff Bay.
            </p>

            <p>
                Century Wharf is located within walking distance of Cardiff city
                centre, including the Principality Stadium and world class
                shopping centre, as well as being close to the vibrant cultural
                life of Cardiff Bay, including the Wales Millennium Centre, home
                of Welsh National Opera, and Mermaid Quay’s waterside quarter
                with its impressive choice of restaurants and coffee shops.
            </p>
        </div>
        <div id="contactUs" hidden>
            <h2>Contact Us</h2>
            <p>For general enquiries please contact:</p>
            <p>Email:</p>
            <p>Phone:</p>
        </div>
    </div>
</div>
