<mat-list>
    <mat-list-item>
        <mat-label>Upload File</mat-label>
    </mat-list-item>

    <mat-list-item>
        <mat-form-field appearance="outline" class="fileFormFields">
            <mat-label>Document Type</mat-label>
            <mat-select [(ngModel)]="fileType">
                <mat-option
                    *ngFor="let fileType of documentTypes"
                    [value]="fileType"
                    >{{ fileType }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </mat-list-item>
    <mat-list-item>
        <mat-form-field
            appearance="outline"
            (click)="fileInput.openFilePicker()"
        >
            <ngx-mat-file-input
                #fileInput
                [(ngModel)]="data.file"
                (change)="response = undefined; fileExists = false"
                accept="application/pdf"
            >
            </ngx-mat-file-input>
        </mat-form-field>
    </mat-list-item>

    <mat-list-item>
        <button
            mat-flat-button
            [mat-dialog-close]="null"
            (click)="cancelUpload()"
        >
            {{ uploading ? 'Cancel' : 'Close' }}
        </button>
        <button
            mat-flat-button
            [disabled]="
                !data.file ||
                uploading ||
                !fileType ||
                (!!data.file && !isCorrectType(['application/pdf'], data.file))
            "
            (click)="uploadFile(data.file, fileType)"
        >
            {{ uploading ? 'Uploading...' : 'Upload File' }}
        </button>
    </mat-list-item>

    <small *ngIf="!!data.file && !isCorrectType(['application/pdf'], data.file)"
        >Unsupported file type. Please upload PDF</small
    >

    <mat-progress-bar
        *ngIf="uploading"
        mode="determinate"
        value="{{ progress }}"
    ></mat-progress-bar>
    <mat-label *ngIf="!uploading && response">{{
        response.status === 200 ? 'Upload Successful' : 'An Error occurred'
    }}</mat-label>
    <mat-label *ngIf="fileExists" class="warning"
        >File with name already exists</mat-label
    >
</mat-list>
