import { Injectable } from '@angular/core';
import { Audit } from '../../interfaces/property';
import { AuthServiceService } from '../auth-service/auth-service.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({
    providedIn: 'root',
})
export class AuditService {
    apiKey = environment.apiKey;
    apiBaseUrl = environment.apiBaseUrl;

    constructor(
        public auth: AuthServiceService,
        public http: HttpClient,
        public logger: NGXLogger
    ) {}

    createAudit = async (type: string, message: string): Promise<Audit> => {
        return {
            type,
            message,
            user: (await this.auth.getUserName()) || 'Unknown User',
            date: new Date().toISOString(),
        };
    };

    createWorkOrderAudit = async (event: string, date?: Date) => {
        return {
            event,
            user: (await this.auth.getUserName()) || 'Unknown User',
            date: (date ?? new Date()).toISOString(),
        };
    };

    getLogs = () => {
        let headers = new HttpHeaders().set('X-Api-Key', this.apiKey);
        return this.http.get<any[]>(this.apiBaseUrl + '/logging', { headers });
    };

    createLog = (message: string) => {
        this.auth.getUserName().then((user) => {
            this.logger.info(`${user}: ${message}`);
        });
    };
}
