<app-page-toolbar [backButtonRoute]="'/home-page/contractors'">
</app-page-toolbar>

<mat-card>
    <mat-card-title>
        <mat-icon>work</mat-icon>
        <small *ngIf="expiredFiles">Expired Documents</small>
        <mat-label class="title"
            >{{ companyName }} {{ noAccount ? '(No Account)' : '' }}</mat-label
        >
    </mat-card-title>
</mat-card>
<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<div *ngIf="contractor && !loading" [formGroup]="contractorForm">
    <mat-expansion-panel class="details">
        <mat-expansion-panel-header class="details-header">
            <mat-icon slot="start">work</mat-icon>
            <mat-label>Company Details</mat-label>
        </mat-expansion-panel-header>
        <mat-card-content>
            <div *ngIf="contractorForm" class="contractorFormContainer">
                <mat-form-field appearance="outline">
                    <mat-label>Company Name</mat-label>
                    <input formControlName="companyName" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['companyName'].touched &&
                        contractorForm.controls['companyName'].invalid
                    "
                    class="errorMessage"
                    >Please enter a Company Name</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>Contact Full Name</mat-label>
                    <input formControlName="contactName" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['contactName'].touched &&
                        contractorForm.controls['contactName'].invalid
                    "
                    class="errorMessage"
                    >Please enter a Contact Name</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>E-Mail</mat-label>
                    <input formControlName="email" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['email'].touched &&
                        contractorForm.controls['email'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Email Address</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>Phone Number</mat-label>
                    <input formControlName="phone" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['phone'].touched &&
                        contractorForm.controls['phone'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Phone Number</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>Address Line 1</mat-label>
                    <input formControlName="addressOne" matInput />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['addressOne'].touched &&
                        contractorForm.controls['addressOne'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Address Line One</small
                >

                <mat-form-field appearance="outline">
                    <mat-label>Address Line 2</mat-label>
                    <input formControlName="addressTwo" matInput />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Address Line 3</mat-label>
                    <input formControlName="addressThree" matInput />
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Post Code</mat-label>
                    <input
                        class="upperCase"
                        formControlName="postcode"
                        matInput
                    />
                </mat-form-field>
                <small
                    *ngIf="
                        contractorForm.controls['postcode'].touched &&
                        contractorForm.controls['postcode'].invalid
                    "
                    class="errorMessage"
                    >Please enter a valid Postcode</small
                >
            </div>
        </mat-card-content>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="!approval" class="details">
        <mat-expansion-panel-header class="details-header">
            <mat-icon slot="start">payments</mat-icon>
            <mat-label>Payment Details</mat-label>
        </mat-expansion-panel-header>
        <mat-form-field appearance="outline">
            <mat-label>Bank Name</mat-label>
            <input formControlName="bankName" matInput />
        </mat-form-field>
        <small
            *ngIf="
                contractorForm.controls['bankName'].touched &&
                contractorForm.controls['bankName'].invalid
            "
            class="errorMessage"
            >Please enter a valid Bank Name</small
        >

        <mat-form-field appearance="outline">
            <mat-label>Account Name</mat-label>
            <input formControlName="accountName" matInput />
        </mat-form-field>
        <small
            *ngIf="
                contractorForm.controls['accountName'].touched &&
                contractorForm.controls['accountName'].invalid
            "
            class="errorMessage"
            >Please enter a valid Account Name</small
        >

        <mat-form-field appearance="outline">
            <mat-label>Sort Code</mat-label>
            <input formControlName="sortCode" matInput />
        </mat-form-field>
        <small
            *ngIf="
                contractorForm.controls['sortCode'].touched &&
                contractorForm.controls['sortCode'].invalid
            "
            class="errorMessage"
            >Please enter a valid Sort Code</small
        >

        <mat-form-field appearance="outline">
            <mat-label>Account Number</mat-label>
            <input formControlName="accountNumber" matInput />
        </mat-form-field>
        <small
            *ngIf="
                contractorForm.controls['accountNumber'].touched &&
                contractorForm.controls['accountNumber'].invalid
            "
            class="errorMessage"
            >Please enter a valid Account Number</small
        >
    </mat-expansion-panel>

    <mat-expansion-panel class="details">
        <mat-expansion-panel-header class="details-header">
            <mat-icon slot="start">folder</mat-icon>
            <mat-label>Files</mat-label>
        </mat-expansion-panel-header>
        <button
            (click)="openFileUploadModal()"
            *ngIf="!approval"
            id="fileButton"
            mat-flat-button
        >
            Upload File
        </button>
        <mat-list>
            <mat-list-item *ngFor="let file of mappedFiles" role="listitem">
                <mat-icon mat-list-icon>article</mat-icon>
                <a
                    (click)="openFile(file.fileKey); $event.preventDefault()"
                    class="fileAnchor"
                    href="#"
                    mat-line
                    mat-list-item
                >
                    <mat-label class="fileAnchor" mat-line>{{
                        file.fileKey
                            .split('@$%&')[0]
                            .replace(applicationId + '/', '')
                    }}</mat-label>
                    <mat-label class="fileAnchor" mat-line>{{
                        file.fileKey.split('@$%&')[1]
                    }}</mat-label>
                </a>
                <div class="expiryDiv" *ngIf="!file.updatingExpiry">
                    <mat-label>Expiry:</mat-label>
                    <input
                        (click)="picker.open()"
                        [matDatepicker]="picker"
                        [(ngModel)]="file.expiry"
                        [ngModelOptions]="{ standalone: true }"
                        (dateInput)="updateExpiry(file)"
                        matInput
                        placeholder="DD/MM/YYYY"
                        readonly
                        class="fileAnchor"
                    />
                    <mat-datepicker #picker>
                        <mat-date-range-picker-actions>
                            <button mat-button matDatepickerApply>Save</button>
                            <button mat-button matDatepickerCancel>
                                Cancel
                            </button>
                            <button
                                mat-button
                                (click)="
                                    picker.select(undefined); picker.close()
                                "
                            >
                                Clear
                            </button>
                        </mat-date-range-picker-actions>
                    </mat-datepicker>
                </div>
                <div class="expiryDiv" *ngIf="file.updatingExpiry">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>

                <button
                    (click)="deleteFile(file.fileKey)"
                    *ngIf="!approval"
                    class="deleteButton"
                    color="warn"
                    mat-icon-button
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header class="details-header">
            <mat-icon slot="start">mail</mat-icon>
            <mat-label>Notification Preferences</mat-label>
        </mat-expansion-panel-header>
        <mat-label>Receive Emails for the following events:</mat-label>
        <mat-list>
            <mat-list-item>
                <mat-checkbox formControlName="workOrderEmails">
                    New Work Order Created
                </mat-checkbox>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="!approval" class="details">
        <mat-expansion-panel-header class="details-header">
            <mat-icon slot="start">receipt</mat-icon>
            <mat-label>Work Orders</mat-label>
        </mat-expansion-panel-header>
        <mat-card
            *ngFor="let workOrder of workOrders"
            [routerLink]="['/home-page/view-work-order', workOrder.orderNumber]"
            class="workOrderDisplay"
        >
            <mat-card-header>
                <mat-label>#{{ workOrder.orderNumber }}</mat-label>
            </mat-card-header>
            <mat-card-content>
                <div class="listCol">
                    <small class="workOrderLabel">Date Created</small>
                    <mat-label>{{
                        workOrder.dateCreated | date: 'dd/MM/yyyy'
                    }}</mat-label>
                </div>
                <div class="contractorCol">
                    <small class="workOrderLabel">Status</small>
                    <mat-label>{{ workOrder.status }}</mat-label>
                </div>
                <div class="contractorCol">
                    <small class="workOrderLabel">Description</small>
                    <mat-label class="desc">{{
                        workOrder?.description
                    }}</mat-label>
                </div>
            </mat-card-content>
        </mat-card>
    </mat-expansion-panel>

    <button
        (click)="deleteContractor()"
        *ngIf="!loading && !approval"
        class="delete"
        color="warn"
        id="deleteButton"
        mat-raised-button
    >
        Delete Contractor
    </button>
</div>

<div *ngIf="approval && contractor" class="footer">
    <button (click)="deleteContractor()" id="reject" mat-raised-button>
        Reject Application
    </button>
    <button (click)="approveContractor()" id="approve" mat-raised-button>
        Approve Application
    </button>
</div>

<mat-toolbar *ngIf="messageBanner" id="messageBanner">
    <mat-label>{{ messageText }}</mat-label>
</mat-toolbar>

<app-footer-toolbar
    (cancelClick)="resetForm()"
    (saveClick)="saveChanges()"
    [cancelButton]="true"
    [saveButton]="true"
    [saveDisabled]="contractorForm && contractorForm.invalid"
    [showToolBar]="
        !approval && contractor && contractorForm && contractorForm.dirty
    "
>
</app-footer-toolbar>
