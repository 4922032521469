import { Component, OnInit } from '@angular/core';
import { PropertyFilter } from '../../../enums/property';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyDetailsService } from '../../../services/property-details/property-details.service';
import {
    endOfDay,
    isAfter,
    isBefore,
    lightFormat,
    parseISO,
    startOfDay,
} from 'date-fns';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { AuthServiceService } from '../../../services/auth-service/auth-service.service';
import { WorkOrdersService } from '../../../services/work-orders/work-orders.service';
import { WorkOrder } from '../../work-orders/work-orders.component';
import { ExportService } from '../../../services/export/export.service';
import { WorkOrderEvents, WorkOrderStatus } from '../../../enums/work-orders';
import { BudgetHeads } from '../../../constants/budgets';
import { BudgetSchedules } from '../../../constants/schedules';
import { MatLegacyListOption as MatListOption } from '@angular/material/legacy-list';
import { BudgetsService } from '../../../services/budgets/budgets.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
})
export class FinanceReportsComponent implements OnInit {
    public unitCourts: Set<string> | undefined;
    public unitNumbers: Set<string> | undefined;
    reportType: string | undefined;
    searchForm = new FormGroup({
        unitCourt: new FormControl(null, Validators.required),
        unit: new FormControl(null, Validators.required),
        startDate: new FormControl(new Date(), Validators.required),
        endDate: new FormControl(new Date(), Validators.required),
    });
    paymentsForm = new FormGroup({
        startDate: new FormControl(new Date(), Validators.required),
        endDate: new FormControl(new Date(), Validators.required),
    });
    currentDate: Date = new Date();
    errorMessage: string = '';
    loading: boolean = false;
    user!: string;
    financeYear: string | undefined;
    budgetYears!: string[];
    exporting: boolean = false;

    constructor(
        public propDetailsService: PropertyDetailsService,
        public route: Router,
        public logger: NGXLogger,
        public auth: AuthServiceService,
        public workOrders: WorkOrdersService,
        public exportService: ExportService,
        public budgetService: BudgetsService
    ) {}

    async ngOnInit(): Promise<void> {
        this.unitCourts = await this.propDetailsService.getUnitFilters(
            PropertyFilter.UnitCourt
        );
        this.subscribeToCourt();

        const years = await firstValueFrom(
            await this.budgetService.getAllYears()
        );
        this.budgetYears = years.map((year) => year.budgetYear);
        const year = this.budgetService.calculateBudgetYear();
        this.financeYear = `${year}-${+year + 1}`;
        this.user = await this.auth.getUserName();
    }

    /** Subscription to unitCourt value changes */
    subscribeToCourt(): void {
        this.searchForm.controls[
            PropertyFilter.UnitCourt
        ].valueChanges.subscribe(async (value) => {
            if (!value) return;
            this.unitNumbers = await this.propDetailsService.getUnitNumbers(
                PropertyFilter.UnitCourt,
                value
            );
            this.searchForm.controls[PropertyFilter.UnitNumber].setValue(null, {
                onlySelf: true,
                emitEvent: false,
            });
        });
    }

    async generateStatement() {
        this.loading = true;
        try {
            const { unitCourt, unit, startDate, endDate } =
                this.searchForm.getRawValue();
            if (!endDate || !startDate || !unit || !unitCourt) return;

            const start = startOfDay(startDate);
            const end = endOfDay(endDate);

            if (end < start)
                return (this.errorMessage =
                    'End Date cannot be before Start Date');

            const unitFull = await this.propDetailsService.getSearchCriteria(
                unit,
                'unitCourt',
                unitCourt
            );
            if (!unitFull) return (this.errorMessage = 'No Unit Found');

            const pdf = await firstValueFrom(
                this.propDetailsService.getStatement(
                    unitFull,
                    start.toDateString(),
                    end.toDateString()
                )
            );

            const url = `data:application/pdf;base64,${pdf}`;

            const link = document.createElement('a');
            link.href = url;
            link.download = `Statement ${unitFull}`.replace(/ /g, '_');
            link.click();

            this.logger.log(`${this.user}: Generated Account statement`);
            this.loading = false;
            return (this.errorMessage = '');
        } catch (error: any) {
            this.loading = false;
            return (this.errorMessage = error.message);
        }
    }

    async generateIncomeList() {
        const { startDate, endDate } = this.paymentsForm.getRawValue();
        if (!endDate || !startDate) return;

        const start = startOfDay(startDate).getTime();
        const end = endOfDay(endDate).getTime();

        this.logger.log(`${this.user}: Generated Income list`);
        await this.route.navigate(['home-page/payments-list'], {
            queryParams: { startDate: start, endDate: end },
        });
    }

    async generateWorkOrderDump(
        statuses: MatListOption[],
        financeYear: string | undefined
    ) {
        this.exporting = true;
        try {
            const status: string[] = statuses.map((choice) => choice.value);
            const budgetYear: string = financeYear?.split('-')[0] as string;

            const workOrders: WorkOrder[] = await lastValueFrom(
                this.workOrders.getAllOrdersFull()
            );

            const filteredData: any[] = workOrders
                .filter((order) => {
                    const { audits } = order;
                    let approvalDate = audits?.find(
                        (audit) =>
                            audit.event === WorkOrderEvents.InvoiceApproved
                    )?.date;

                    if (!approvalDate) return false;

                    return (
                        isAfter(
                            parseISO(approvalDate),
                            new Date(+budgetYear, 8, 1)
                        ) &&
                        isBefore(
                            parseISO(approvalDate),
                            new Date(+budgetYear + 1, 8, 1)
                        )
                    );
                })
                .filter((order) => status.includes(order.status))
                .map((order) => {
                    const {
                        orderNumber,
                        dateCreated,
                        contractorId,
                        audits,
                        description,
                        status,
                        leaseGroups,
                    } = order;

                    let paidDate : any = audits?.find(
                        (audit) => audit.event === WorkOrderEvents.InvoicePaid
                    )?.date;
                    let approvalDate: any = audits?.find(
                        (audit) =>
                            audit.event === WorkOrderEvents.InvoiceApproved
                    )?.date;

                    paidDate = paidDate
                        ? new Date(paidDate)
                        : '';

                    approvalDate = approvalDate
                        ? new Date(approvalDate)
                        : '';

                    const schedules = BudgetSchedules.map((sched) => {
                        return {
                            [sched.name]:
                                sched.code in leaseGroups
                                    ? +leaseGroups[sched.code].toFixed(2)
                                    : '',
                        };
                    }).reduce((result, item) => {
                        let key = Object.keys(item)[0]; //first property: a, b, c
                        result[key] = item[key];
                        return result;
                    }, {});

                    return {
                        orderNumber,
                        status,
                        dateCreated: new Date(dateCreated),
                        paidDate,
                        approvalDate,
                        contractor: contractorId.companyName,
                        expenseCategory:
                            BudgetHeads.find(
                                (head) => head.code === order.expenseCategory
                            )?.name ?? 'Reserves',
                        totalValue: +Object.values(leaseGroups)
                            .reduce((a, b) => a + b, 0)
                            .toFixed(2),
                        ...schedules,
                        description,
                    };
                })
                .sort((a, b) => +a.orderNumber - +b.orderNumber);

            this.exportService.exportWorkOrderData(filteredData, financeYear!);
        } catch (error) {
            alert(error);
        } finally {
            this.exporting = false;
        }
    }

    protected readonly Object = Object;
    protected readonly WorkOrderStatus = WorkOrderStatus;
    protected readonly console = module;
}
