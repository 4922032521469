import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { NotificationService } from '../../services/notification/notification.service';
import { Subject, takeUntil } from 'rxjs';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('300ms ease-out',
              style({ height: '*', opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: "*", opacity: 1 }),
            animate('300ms ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})
export class NavigationComponent implements OnInit {
    @ViewChild('sidenav') sideNav!: MatSidenav;
    mobile: boolean = false;
    mobileMenu: boolean = false;
    loading: boolean = true;
    approvals: number = 0;
    destroy$ = new Subject();
    userName!: string;
    userGroups!: (string | number)[];
    selectedSite: string = 'century-wharf';

    backButtonRoute: string | undefined;

    constructor(
        public observer: BreakpointObserver,
        public notification: NotificationService,
        public auth: AuthServiceService
    ) {


      this.mobile = this.observer.isMatched('(max-width: 768px)');
    }

    async ngOnInit() {
        this.userName = await this.auth.getUserName();
        this.userGroups = await this.auth.getGroups();
        const authLimit = await this.auth.getAuthLimit();
        this.notification.startPolling(this.userGroups);
        this.loading = true;
        this.notification.approvals
            .pipe(takeUntil(this.destroy$))
            .subscribe((approvals) => {
                this.approvals =
                    (approvals?.workOrder?.filter(
                        (order) =>
                            Object.values(order.leaseGroups as {}).reduce(
                                (a: number, b: any) => a + +b,
                                0
                            ) <= authLimit
                    ).length || 0) + (approvals?.contractor?.length || 0);
                this.loading = false;
            });
    }

    /**
     * Angular lifecycle event
     */
    async ngAfterViewInit(): Promise<void> {
        this.observer.observe(['(max-width: 768px)']).subscribe((res) => {
          this.mobile = res.matches;
        });
    }

    /**
     * Log user out of App and return to landing page
     */
    logOut = async (): Promise<void> => {
        await this.auth.signOut();
    };

    toggleMenu =  () => {
      this.mobileMenu = !this.mobileMenu;
    }
}
