<header>
    <mat-card-title>
        <mat-icon>receipt</mat-icon>
        Work Order: #{{ workOrder && workOrder.orderNumber }}
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<ul *ngIf="workOrder">
    <li>
        <mat-label class="mat-body-strong">Date Created</mat-label>
        <mat-label>{{ workOrder.dateCreated | date: 'dd/MM/yyyy' }}</mat-label>
    </li>
    <mat-divider></mat-divider>
    <li>
        <mat-label class="mat-body-strong">Status</mat-label>
        <mat-label>{{ workOrder.status }}</mat-label>
    </li>
    <mat-divider></mat-divider>

    <li class="cols">
        <mat-label class="mat-body-strong">Description</mat-label>
        <mat-label id="desc">{{ workOrder.description }} </mat-label>
    </li>
    <mat-divider></mat-divider>

    <li>
        <mat-label class="mat-body-strong">Total Amount</mat-label>
        <mat-label class="mat-body-strong">{{
            calcTotal() | currency: 'GBP'
        }}</mat-label>
    </li>
    <mat-divider></mat-divider>
    <li class="cols">
        <mat-label class="mat-body-strong">Risks</mat-label>
        <ul class="groupList">
            <li *ngFor="let risk of workOrder.risks">
                <mat-label>{{ risk }}</mat-label>
            </li>
        </ul>
    </li>
    <mat-divider></mat-divider>
</ul>

<mat-card *ngIf="workOrder">
    <header>
        <mat-card-title>
            <mat-icon>upload_file</mat-icon>
            Upload Supporting Documents
        </mat-card-title>
    </header>
    <div>
        <small
            >Please upload any supporting Documents ( .pdf / .jpeg / .png
            )</small
        >
        <mat-form-field
            appearance="outline"
            (click)="
                ![
                    'Awaiting Payment',
                    'Invoice Paid',
                    'Closed',
                    'Awaiting Authorisation'
                ].includes(workOrder.status)
                    ? supportInput.openFilePicker()
                    : null
            "
            id="fileInput"
        >
            <ngx-mat-file-input
                #supportInput
                [(ngModel)]="supportDocs"
                accept=".pdf, .png, .jpeg, .jpg"
                placeholder="Select Files"
                [disabled]="
                    ['Awaiting Payment', 'Invoice Paid', 'Closed'].includes(
                        workOrder.status
                    )
                "
            >
            </ngx-mat-file-input>
        </mat-form-field>
        <small
            *ngIf="
                supportDocs &&
                !isCorrectType(
                    ['application/pdf', 'image/jpeg', 'image/png'],
                    supportDocs
                )
            "
            >Unsupported file type. Please upload .pdf / .jpeg / .png
        </small>
        <mat-progress-bar
            *ngIf="docsUploading"
            mode="indeterminate"
        ></mat-progress-bar>
        <button
            [disabled]="
                !supportDocs ||
                disable ||
                (supportDocs &&
                    !isCorrectType(
                        ['application/pdf', 'image/jpeg', 'image/png'],
                        supportDocs
                    ))
            "
            mat-raised-button
            class="invoiceUpload"
            (click)="
                docsUploading
                    ? cancelUploadDocs()
                    : uploadSupportingDocs(supportDocs)
            "
        >
            {{ docsUploading ? 'Cancel' : 'Upload File' }}
        </button>
    </div>
    <ul *ngIf="fileList && fileList.length > 0 && supportingFiles().length > 0">
        <li *ngFor="let file of supportingFiles()" class="supportDiv">
            <mat-label (click)="openFile(file); $event.preventDefault()">{{
                file.replace(workOrder.orderNumber + '/supportingDocs/', '')
            }}</mat-label>
            <button
                mat-icon-button
                color="warn"
                (click)="deleteFile(file)"
                [disabled]="disable"
            >
                <mat-icon>close</mat-icon>
            </button>
        </li>
    </ul>
</mat-card>

<mat-card *ngIf="workOrder">
    <header>
        <mat-card-title>
            <mat-icon>upload_file</mat-icon>
            Upload Invoice
        </mat-card-title>
    </header>
    <div
        *ngIf="
            !fileList ||
            fileList.length === 0 ||
            !fileList.includes(workOrder.orderNumber + '/invoice.pdf')
        "
    >
        <mat-label>Please make your invoice payable to:</mat-label>
        <br />
        <small>CW ESTATES MANAGEMENT COMPANY LIMITED</small>
        <small>CENTURY WHARF,</small>
        <small>CHANDLERY WAY,</small>
        <small>CARDIFF,</small>
        <small>WALES,</small>
        <small>CF10 5AU</small>
        <br />
        <small
            >Please upload an Invoice for work completed against this Work
            Order. Once uploaded, it will be sent for approval. ( .pdf )</small
        >

        <mat-form-field
            appearance="outline"
            (click)="
                ![
                    'Awaiting Payment',
                    'Invoice Paid',
                    'Closed',
                    'Awaiting Authorisation'
                ].includes(workOrder.status)
                    ? fileInput.openFilePicker()
                    : null
            "
        >
            <ngx-mat-file-input
                #fileInput
                [(ngModel)]="invoice"
                accept=".pdf"
                [disabled]="
                    [
                        'Awaiting Payment',
                        'Invoice Paid',
                        'Closed',
                        'Awaiting Authorisation'
                    ].includes(workOrder.status)
                "
            >
            </ngx-mat-file-input>
        </mat-form-field>
        <small *ngIf="invoice && !isCorrectType(['application/pdf'], invoice)"
            >Unsupported file type. Please upload PDF</small
        >

        <mat-progress-bar
            *ngIf="uploading"
            mode="determinate"
            value="{{ progress }}"
        ></mat-progress-bar>
        <button
            [disabled]="
                !invoice ||
                disable ||
                !isCorrectType(['application/pdf'], invoice)
            "
            mat-raised-button
            class="invoiceUpload"
            (click)="uploading ? cancelUpload() : uploadFile(invoice)"
        >
            {{ uploading ? 'Cancel' : 'Upload Invoice' }}
        </button>
    </div>
    <div
        *ngIf="
            fileList &&
            fileList.length > 0 &&
            fileList.includes(workOrder.orderNumber + '/invoice.pdf')
        "
    >
        <div class="fileDiv">
            <button
                mat-raised-button
                id="viewInvoice"
                (click)="
                    openFile(workOrder.orderNumber + '/invoice.pdf');
                    $event.preventDefault()
                "
            >
                View Invoice
            </button>
            <button
                mat-raised-button
                color="warn"
                id="deleteButton"
                (click)="deleteFile(workOrder.orderNumber + '/invoice.pdf')"
                [disabled]="disable"
            >
                Delete Invoice
            </button>
        </div>
    </div>
</mat-card>

<div>
    <button mat-raised-button [mat-dialog-close]="null" class="invoiceUpload">
        Back to Dashboard
    </button>
</div>
