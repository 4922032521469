import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { firstValueFrom } from 'rxjs';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { StorageKeys } from '../../enums/storage';

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
})
export class FileUploadComponent {
    uploading: boolean = false;
    progress: number = 0;
    upload$!: any;
    response: HttpResponse<any> | undefined;
    fileExists: boolean = false;

    constructor(
        public dialogRef: MatDialogRef<FileUploadComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public propertyService: PropertyDetailsService
    ) {}

    /**
     * Upload File to AWS, If file does not already exist with name
     *
     * @param file
     */
    uploadFile = async (file: File): Promise<any> => {
        const newFileName: string = file.name.replace(/ /g, '_');
        const key: string = this.data.folderPath + newFileName;

        if (this.data.unitFiles.includes(newFileName)) {
            this.fileExists = true;
            return;
        } else {
            this.fileExists = false;
        }

        this.uploading = true;
        const url: string = await firstValueFrom(
            this.propertyService.getPresignedUrl(key, 'Put')
        );

        return (this.upload$ = this.propertyService
            .fileUpload(url, key, file)
            .subscribe(async (event: HttpEvent<any>) => {
                switch (event.type) {
                    case HttpEventType.UploadProgress:
                        const eventTotal: number = event.total
                            ? event.total
                            : 0;
                        this.progress = Math.round(
                            (event.loaded / eventTotal) * 100
                        );
                        break;

                    case HttpEventType.Response:
                        const files: string[] = await firstValueFrom(
                            await this.propertyService.getFileList(
                                this.data.unitFull
                            )
                        );
                        files
                            ? localStorage.setItem(
                                  StorageKeys.PropertyFiles,
                                  JSON.stringify(files)
                              )
                            : localStorage.removeItem(
                                  StorageKeys.PropertyFiles
                              );
                        this.uploading = false;
                        this.progress = 0;
                        this.data.file = null;
                        this.response = event;
                        break;
                }
            }));
    };

    /**
     * Cancel an upload in progress
     */
    cancelUpload = (): void => {
        if (this.upload$) this.upload$.unsubscribe();
    };

    isCorrectType = (fileTypes: string[], file: File): boolean => {
        return fileTypes.includes(file.type);
    };
}
