import { Injectable } from '@angular/core';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import * as Sentry from '@sentry/angular-ivy';

export enum UserGroups {
    BudgetEdit = 'BudgetEdit',
    BudgetsAccess = 'BudgetsAccess',
    ContractAccess = 'ContractAccess',
    Contractor = 'Contractor',
    ExpensesAccess = 'ExpensesAccess',
    FinanceAccess = 'FinanceAccess',
    FinanceReportsAccess = 'FinanceReportsAccess',
    IncomeAccess = 'IncomeAccess',
    PropertyDetailsFull = 'PropertyDetailsFull',
    ReportsFull = 'ReportsFull',
    Staff = 'Staff',
    VarianceAccess = 'VarianceAccess',
    WorkordersAccess = 'WorkordersAccess',
    Admin = 'Admin',
    PaymentAdmin = 'PaymentAdmin',
}

@Injectable({
    providedIn: 'root',
})
export class AuthServiceService {
    poolData = {
        UserPoolId: environment.cognitoUserPoolId,
        ClientId: environment.cognitoAppClientId,
    };
    userPool = new CognitoUserPool(this.poolData);

    constructor(
        public router: Router,
        public logger: NGXLogger
    ) {}

    isLoggedIn(): boolean {
        let isAuth = false;
        const cognitoUser = this.userPool.getCurrentUser();

        if (cognitoUser != null) {
            cognitoUser.getSession((err: any, session: any) => {
                if (err) {
                    alert(err.message || JSON.stringify(err));
                }
                isAuth = session.isValid();
            });
        }
        return isAuth;
    }

    getToken = () => {
        const cognitoUser = this.userPool.getCurrentUser();
        return cognitoUser?.getSession((error: any, session: any) => {
            if (error) console.error(error);
            if (!session.isValid()) console.error('Invalid Session');

            return session.getAccessToken().getJwtToken();
        });
    };

    signOut = async () => {
        const cognitoUser = this.userPool.getCurrentUser();
        this.logger.log(`${await this.getUserName()}: Logged out.`);
        cognitoUser?.signOut();
        Sentry.setUser(null);
        await this.router.navigate(['']);
    };

    getGroups = (): Promise<(string | number)[]> => {
        const cognitoUser = this.userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            cognitoUser?.getSession((error: any, session: any) => {
                if (error) resolve([]);
                resolve(session.accessToken.payload['cognito:groups']);
            });
        });
    };

    getAuthLimit = async (): Promise<number> => {
        const cognitoUser = this.userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            cognitoUser?.getSession((error: any, session: any) => {
                if (error) resolve(0);
                resolve(
                    session.accessToken.payload['cognito:groups'].find(
                        (group: string | number) => +group
                    )
                );
            });
        });
    };

    getUserName = (): Promise<string> => {
        const cognitoUser = this.userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            cognitoUser?.getSession((error: any, session: any) => {
                if (error) resolve('');
                resolve(
                    session?.idToken?.payload?.given_name +
                        ' ' +
                        session?.idToken?.payload?.family_name || ''
                );
            });
        });
    };

    getUser = (): Promise<string[]> => {
        const cognitoUser = this.userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            cognitoUser?.getSession((error: any, session: any) => {
                if (error) resolve(['', '']);
                resolve([
                    session?.idToken?.payload?.given_name +
                        ' ' +
                        session?.idToken?.payload?.family_name || '',
                    session?.idToken?.payload?.email,
                ]);
            });
        });
    };

    getProfile = (): Promise<string> => {
        const cognitoUser = this.userPool.getCurrentUser();
        return new Promise((resolve, reject) => {
            cognitoUser?.getSession((error: any, session: any) => {
                if (error) resolve('');
                resolve(session?.idToken?.payload?.profile);
            });
        });
    };
}
