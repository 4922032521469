import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { firstValueFrom } from 'rxjs';
import { setHours } from 'date-fns';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';

@Component({
    selector: 'app-payment-modal',
    templateUrl: './payment-modal.component.html',
    styleUrls: ['./payment-modal.component.css'],
})
export class PaymentModalComponent implements OnInit {
    paymentForm: FormGroup = new FormGroup({});
    loading: boolean = false;
    completed: boolean = false;

    maxDate: Date = new Date();
    minDate: Date = new Date(new Date().setUTCDate(new Date().getDate() - 7));

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<PaymentModalComponent>,
        public propertyDetailsService: PropertyDetailsService,
        public auth: AuthServiceService
    ) {}

    async ngOnInit(): Promise<void> {
        this.paymentForm.addControl(
            'transactionType',
            new FormControl(
                {
                    value: 'Payment',
                    disabled: false,
                },
                { validators: Validators.required }
            )
        );
        this.paymentForm.addControl(
            'date',
            new FormControl(
                {
                    value: new Date(),
                    disabled: false,
                },
                { validators: Validators.required }
            )
        );
        this.paymentForm.addControl(
            'description',
            new FormControl(
                {
                    value: '',
                    disabled: false,
                },
                { validators: Validators.required }
            )
        );
        this.paymentForm.addControl(
            'amount',
            new FormControl(
                {
                    value: null,
                    disabled: false,
                },
                { validators: Validators.required }
            )
        );

        const userGroups = await this.auth.getGroups();
        if (userGroups.includes('PaymentAdmin')) {
            this.maxDate = new Date(
                new Date().setUTCDate(new Date().getDate() + 30)
            );
            this.minDate = new Date(
                new Date().setUTCDate(new Date().getDate() - 180)
            );
        }
    }

    async addTransaction() {
        try {
            this.loading = true;
            const origDate = this.paymentForm.controls['date'].value;
            const date = setHours(new Date(origDate), 12);
            const { transactionType, description } =
                this.paymentForm.getRawValue();
            const refund: boolean = [
                'Refund Charge',
                'Refund Payment',
            ].includes(transactionType);

            await firstValueFrom(
                this.propertyDetailsService.addTransaction(
                    {
                        ...this.paymentForm.getRawValue(),
                        description: refund
                            ? '**REFUND** ' + description
                            : description,
                        date,
                    },
                    this.data.unitFull
                )
            );
            this.completed = true;
        } catch {
        } finally {
            this.loading = false;
        }
    }
}
