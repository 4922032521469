<header>
    <mat-card-title>
        <mat-icon>currency_pound</mat-icon>
        Budget Breakdown: {{ data.budgetData['unitFull'] }}</mat-card-title
    >
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>
<div id="containerList">
    <ul>
        <li class="currencyList">
            <h2>Total</h2>
            <h2>{{ total | currency: 'GBP' }}</h2>
        </li>
        <div
            *ngFor="
                let schedule of Object.keys(
                    data.budgetData.serviceCharge
                ).sort()
            "
        >
            <li *ngIf="schedule !== 'RES'">
                <div class="currencyList botBorder">
                    <mat-label class="listTitle">{{
                        findScheduleName(schedule)
                    }}</mat-label>
                    <mat-label class="currencyLabel">{{
                        data.budgetData.totals[schedule] | currency: 'GBP'
                    }}</mat-label>
                </div>

                <ul>
                    <li
                        *ngFor="
                            let item of data.budgetData.serviceCharge[schedule]
                        "
                    >
                        <div
                            *ngFor="let head of item | keyvalue"
                            class="currencyList"
                        >
                            <small>{{ head.key }}</small>
                            <small class="currencyLabel">{{
                                head.value | currency: 'GBP'
                            }}</small>
                        </div>
                    </li>
                </ul>
                <br />
            </li>
        </div>
        <li>
            <div class="currencyList botBorder">
                <mat-label class="listTitle">Reserves</mat-label>
                <mat-label class="currencyLabel">{{
                    data.budgetData.totals['RES'] | currency: 'GBP'
                }}</mat-label>
            </div>

            <ul *ngFor="let reserve of data.budgetData.serviceCharge['RES']">
                <li
                    *ngFor="let head of reserve | keyvalue"
                    class="currencyList"
                >
                    <small>{{ findScheduleName(head.key) }}</small>
                    <small class="currencyLabel">{{
                        head.value | currency: 'GBP'
                    }}</small>
                </li>
            </ul>
        </li>
    </ul>
</div>
