import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { ContractorsService } from '../../services/contractors/contractors.service';
import { Guid } from 'guid-typescript';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-no-account-contractor',
    templateUrl: './no-account-contractor.component.html',
    styleUrls: ['./no-account-contractor.component.css'],
})
export class NoAccountContractorComponent implements OnInit {
    companyName: string | undefined;
    loading: boolean = false;
    error?: string;

    constructor(
        public contractorService: ContractorsService,
        public dialogRef: MatDialogRef<NoAccountContractorComponent>
    ) {}

    ngOnInit(): void {}

    async addCompany() {
        this.loading = true;
        try {
            const applicationId = Guid.create().toString();
            const response = await firstValueFrom(
                this.contractorService.callContractorApplicationEndpoint({
                    noAccount: true,
                    applicationId,
                    companyName: this.companyName,
                    status: 'verified',
                })
            );

            this.dialogRef.close(true);
        } catch (error: any) {
            this.error = error.message;
            this.loading = false;
        }
    }
}
