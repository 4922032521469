<app-page-toolbar
    [backButtonRoute]="
        cameFrom === 'search'
            ? '/home-page/property-search'
            : '/home-page/report-viewer'
    "
>
</app-page-toolbar>

<mat-card>
    <mat-card-title>
        <mat-icon>{{
            propertyDetails.unitType === 'Apartment' ? 'apartment' : 'cottage'
        }}</mat-icon>
        <mat-label id="unitTitle"
            >{{ propertyDetails.unit }} - {{ propertyDetails.unitCourt }} -
            {{ propertyDetails.unitHouse }}
        </mat-label>
    </mat-card-title>
</mat-card>

<div [formGroup]="propertyForm">
    <mat-expansion-panel *ngFor="let section of propertyConfig">
        <mat-expansion-panel-header
            *ngIf="
                !(
                    section.headerDetails.section === 'Boiler Details' &&
                    propertyDetails.unitType === 'Town House'
                )
            "
        >
            <mat-icon>{{ section.headerDetails.icon }}</mat-icon>
            <mat-label>{{ section.headerDetails.section }}</mat-label>
        </mat-expansion-panel-header>

        <div *ngFor="let field of section.data; let i = index">
            <mat-card-title
                *ngIf="calcSubSectionIndexes(section).includes(i)"
                class="subSectionLabel"
                >{{ startCase(field.subSection) }}
            </mat-card-title>

            <mat-form-field appearance="outline">
                <mat-label>{{ startCase(field.fieldName) }}</mat-label>

                <input
                    *ngIf="field.type === 'text'"
                    [formControlName]="field.fieldName"
                    matInput
                />

                <textarea
                    *ngIf="field.type === 'textArea'"
                    [formControlName]="field.fieldName"
                    matInput
                ></textarea>

                <mat-select
                    *ngIf="field.type === 'select'"
                    [formControlName]="field.fieldName"
                >
                    <mat-option [value]="true">Yes</mat-option>
                    <mat-option [value]="false">No</mat-option>
                </mat-select>

                <input
                    (click)="picker.open()"
                    *ngIf="field.type === 'date'"
                    [formControlName]="field.fieldName"
                    [matDatepicker]="picker"
                    matInput
                    placeholder="DD/MM/YYYY"
                    readonly
                />
                <mat-datepicker-toggle
                    *ngIf="field.type === 'date'"
                    [disabled]="propertyForm.disabled"
                    [for]="picker"
                    matSuffix
                ></mat-datepicker-toggle>
                <mat-datepicker
                    #picker
                    [disabled]="propertyForm.disabled"
                ></mat-datepicker>
                <button
                    (click)="
                        propertyForm.controls[field.fieldName].patchValue(null);
                        propertyForm.markAsDirty()
                    "
                    *ngIf="field.type === 'date'"
                    [disabled]="propertyForm.disabled"
                    class="deleteButton"
                    color="warn"
                    mat-icon-button
                    matSuffix
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-form-field>
            <small
                *ngIf="
                    propertyForm.controls[field.fieldName].errors?.['required']
                "
                >This field is required</small
            >
            <small
                *ngIf="
                    propertyForm.controls[field.fieldName].errors?.['pattern']
                "
                >Please enter a valid email address</small
            >
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="!propertyForm.disabled">
        <mat-expansion-panel-header>
            <mat-icon>currency_pound</mat-icon>
            <mat-label>Payments / Charges</mat-label>
        </mat-expansion-panel-header>
        <div class="paymentPanel">
            <button
                (click)="openPaymentModal()"
                class="paymentButtons"
                mat-raised-button
            >
                <mat-icon>add_circle</mat-icon>
                Add Payment / Charge
            </button>
        </div>
        <mat-list class="listDiv" dense>
            <mat-list-item>
                <mat-label class="balance"
                    >Account Balance:
                    {{ accountBalance | currency: 'GBP' }}</mat-label
                >
            </mat-list-item>
            <mat-list-item class="headers">
                <mat-label class="moneyDiv rowDate">Date</mat-label>
                <mat-label class="moneyDiv rowDesc">Description</mat-label>
                <mat-label class="moneyDiv rowAmount">Amount</mat-label>
                <button
                    class="deleteButton"
                    color="warn"
                    disabled
                    mat-icon-button
                ></button>
                <mat-divider></mat-divider>
            </mat-list-item>
            <ng-container *ngFor="let payment of paymentArray; let i = index">
                <mat-list-item
                    *ngIf="i < 5 || showAllPayments"
                    class="headers lineItems"
                    (mouseenter)="paymentButtons[i] = true"
                    (mouseleave)="paymentButtons[i] = false"
                >
                    <mat-label class="moneyDiv rowDate">{{
                        payment.date | date
                    }}</mat-label>
                    <mat-label class="moneyDiv rowDesc">{{
                        payment.description
                    }}</mat-label>
                    <mat-label class="moneyDiv rowAmount"
                        >{{
                            ['Payment', 'Refund Charge'].includes(
                                payment.transactionType
                            )
                                ? '- '
                                : ''
                        }}{{ payment.amount | currency: 'GBP' }}
                    </mat-label>
                    <button
                        #deleteButton
                        (click)="
                            deleteTransaction(payment.id, payment.description)
                        "
                        class="deleteButton"
                        matTooltipPosition="above"
                        *ngIf="userGroups.includes('PaymentAdmin')"
                        color="warn"
                        [disabled]="
                            !paymentButtons[i] ||
                            dateBeforeMin(payment.date) ||
                            isAutoCharge(payment.description)
                        "
                        mat-icon-button
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-list-item>
            </ng-container>
        </mat-list>
        <div id="showMoreButton">
            <button
                (click)="showAllPayments = !showAllPayments"
                class="showMore"
                mat-stroked-button
            >
                Show {{ showAllPayments ? 'Less' : 'All' }} Transactions
            </button>
        </div>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-icon>notes</mat-icon>
            <mat-label>Audits / Notes</mat-label>
        </mat-expansion-panel-header>
        <mat-list dense>
            <div class="paymentPanel">
                <button
                    (click)="openNoteDialog()"
                    [disabled]="propertyForm.disabled"
                    class="paymentButtons"
                    id="noteButton"
                    mat-raised-button
                >
                    <mat-icon>add_circle</mat-icon>
                    Add new Note
                </button>
            </div>

            <div *ngFor="let audit of propertyDetails?.audits | reverse">
                <mat-list-item class="auditList">
                    <mat-icon mat-list-icon>{{
                        audit.type === 'Note' ? 'notes' : 'edit_document'
                    }}</mat-icon>
                    <mat-label
                        class="auditTitle"
                        *ngIf="audit.type === 'Update Details'"
                        ><h4>
                            {{ audit.type }} : {{ audit.date | date }} -
                            {{ audit.user }}
                        </h4>
                    </mat-label>
                    <mat-label
                        class="auditTitle"
                        *ngIf="audit.type !== 'Update Details'"
                        mat-line
                        ><h4>
                            {{ audit.type }} : {{ audit.date | date }} -
                            {{ audit.user }}
                        </h4>
                    </mat-label>
                    <mat-label
                        *ngIf="audit.type !== 'Update Details'"
                        class="noteMessage"
                        mat-line
                        >{{ audit.message }}
                    </mat-label>
                    <div>
                        <button
                            (click)="removeAudit(audit)"
                            *ngIf="
                                userName === audit.user && audit.type === 'Note'
                            "
                            class="deleteButton"
                            color="warn"
                            mat-icon-button
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-list-item>
            </div>
        </mat-list>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-icon>folder</mat-icon>
            <mat-label>Files</mat-label>
        </mat-expansion-panel-header>
        <div class="paymentPanel">
            <button
                (click)="openFileUploadModal()"
                [disabled]="propertyForm.disabled"
                class="paymentButtons"
                id="fileButton"
                mat-raised-button
            >
                <mat-icon>upload</mat-icon>
                Upload File
            </button>
        </div>
        <mat-list>
            <mat-list-item *ngFor="let file of propertyFiles" role="listitem">
                <mat-icon mat-list-icon>article</mat-icon>
                <a
                    (click)="openFile(file); $event.preventDefault()"
                    class="fileAnchor"
                    href="#"
                    mat-line
                    mat-list-item
                    >{{ file | underscore }}</a
                >
                <button
                    (click)="deleteFile(file)"
                    *ngIf="!propertyForm.disabled"
                    class="deleteButton"
                    color="warn"
                    mat-icon-button
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-icon>folder</mat-icon>
            <mat-label>Service Charge Demands</mat-label>
        </mat-expansion-panel-header>
        <div class="paymentPanel">
            <button
                (click)="openServiceChargeModal()"
                [disabled]="
                    propertyForm.disabled ||
                    propertyForm.dirty ||
                    (userGroups && !userGroups.includes('PaymentAdmin'))
                "
                class="paymentButtons"
                mat-raised-button
            >
                <mat-icon>edit_square</mat-icon>
                Update Service Charge Demand
            </button>
        </div>
        <mat-list>
            <mat-list-item *ngFor="let file of serviceCharges" role="listitem">
                <mat-icon mat-list-icon>article</mat-icon>
                <a
                    (click)="openServiceCharge(file); $event.preventDefault()"
                    class="fileAnchor"
                    href="#"
                    mat-line
                    mat-list-item
                    >{{ startCase(file | underscore) }}</a
                >
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
</div>

<mat-toolbar *ngIf="messageBanner" id="messageBanner">
    <mat-label>{{ messageText }}</mat-label>
</mat-toolbar>

<app-footer-toolbar
    (cancelClick)="resetForm()"
    (saveClick)="saveChanges()"
    [cancelButton]="true"
    [saveButton]="true"
    [saveDisabled]="propertyForm.invalid || saving"
    [showToolBar]="propertyForm.dirty"
>
</app-footer-toolbar>
