import { BudgetSchedules } from './schedules';
import { BudgetHeadValues } from '../interfaces/budget-interfaces';

export const houseSchedules = BudgetSchedules.filter(
    (sched) => +sched.code.slice(-2) <= 34 && +sched.code.slice(-2) > 1
);

export const undercroftSchedules = BudgetSchedules.filter(
    (sched) => +sched.code.slice(-2) >= 39
);

const distributeAllHousesShared = (headTotal: number) => {
    return houseSchedules.map((sched) => {
        return {
            code: sched.code,
            amount: +(
                (headTotal / 35) *
                +(sched.code === 'SDH03' ? 3 : 1)
            ).toFixed(2),
        };
    });
};

const housesBy33 = (headTotal: number) => {
    return houseSchedules.map((sched) => {
        return { code: sched.code, amount: +(headTotal / 33).toFixed(2) };
    });
};

const housesBySize = (headTotal: number) => {
    return houseSchedules.map((schedule) => {
        return {
            code: schedule.code,
            amount: +((headTotal / 906) * schedule.size).toFixed(2),
        };
    });
};

const allToEstate = (headTotal: number) => {
    return [{ code: 'SDH01', amount: headTotal }];
};

const calcElectricity = (headTotal: number) => {
    return BudgetSchedules.filter(
        (schedule) => schedule?.breakdowns?.electricity
    ).map((schedule) => {
        return {
            code: schedule.code,
            amount: +(
                headTotal * (schedule?.breakdowns?.electricity || 0)
            ).toFixed(2),
        };
    });
};

const houseBySizeMinusEstate = (headTotal: number, estateShare: number) => {
    const estate = {
        code: 'SDH01',
        amount: +(headTotal * estateShare).toFixed(2),
    };
    const houses = housesBySize(headTotal - estate.amount);
    return [estate, ...houses];
};

const gatesAndBarriers = (headTotal: number) => {
    const estate = { code: 'SDH01', amount: +(headTotal * 0.35).toFixed(2) };
    const underCrofts = undercroftsByInsurance(headTotal - estate.amount);
    return [estate, ...underCrofts];
};

const undercroftsByInsurance = (total: number) => {
    return undercroftSchedules.map((undercroft) => {
        return {
            code: undercroft.code,
            amount: +(
                (total / 0.122463156) *
                (undercroft.breakdowns?.insuranceSplit || 0)
            ).toFixed(2),
        };
    });
};

const coldWater = (headTotal: number) => [{ code: 'SDH37', amount: headTotal }];

const communalCleaning = (headTotal: number) =>
    houseBySizeMinusEstate(headTotal, 0.292857);
const doorEntry = (headTotal: number) =>
    houseBySizeMinusEstate(headTotal, 0.099205);

const caretaker = (headTotal: number) => {
    const estate = { code: 'SDH01', amount: +(headTotal * 0.82).toFixed(2) };
    const refuseChute = {
        code: 'SDH35',
        amount: +(headTotal * 0.0231).toFixed(2),
    };
    const houses = housesBySize(headTotal - estate.amount - refuseChute.amount);
    return [estate, ...houses, refuseChute];
};

const liftCosts = (headTotal: number) => {
    return houseSchedules.map((schedules) => {
        return {
            code: schedules.code,
            amount: +(
                (headTotal / 32) *
                (['SDH34', 'SDH31'].includes(schedules.code) ? 0.5 : 1)
            ).toFixed(2),
        };
    });
};

const dryRisers = (headTotal: number) => {
    const houseWithNoRisers = ['SDH03', 'SDH05', 'SDH06', 'SDH30'];
    return houseSchedules
        .filter((schedule) => !houseWithNoRisers.includes(schedule.code))
        .map((schedule) => {
            return {
                code: schedule.code,
                amount: +((headTotal / 787) * schedule.size).toFixed(2),
            };
        });
};

const allMarseille = (headTotal: number) => [
    { code: 'SDH33', amount: headTotal },
];

const buildingsInsurance = (headTotal: number) => {
    return BudgetSchedules.filter(
        (schedule) => !!schedule.breakdowns?.insuranceSplit
    ).map((schedule) => {
        return {
            code: schedule.code,
            amount: +(
                headTotal * (schedule.breakdowns?.insuranceSplit || 0)
            ).toFixed(2),
        };
    });
};

const groundsMaintenance = (headTotal: number) => {
    const estate = {
        code: 'SDH01',
        amount: +(headTotal * 0.901234567).toFixed(2),
    };
    const surfaceParking = {
        code: 'SDH38',
        amount: +(headTotal * 0.0244107744).toFixed(2),
    };
    return [
        estate,
        surfaceParking,
        ...undercroftsByInsurance(
            headTotal - estate.amount - surfaceParking.amount
        ),
    ];
};

const generalRepairs = (headTotal: number) => {
    const estate = { code: 'SDH01', amount: +(headTotal * 0.25).toFixed(2) };
    const houses = housesBySize(headTotal * 0.629679965);
    const undercrofts = undercroftsByInsurance(headTotal * 0.120320039);
    return [estate, ...houses, ...undercrofts];
};

export interface BudgetHeadConst {
    name: string;
    code: string;
    schedules?: (headTotal: number) => { code: string; amount: number }[];
    subCats?: BudgetHeadConst[];
}

export const BudgetHeads: BudgetHeadConst[] = [
    {
        name: 'Concierge and on Costs',
        code: 'BH01',
        schedules: allToEstate,
    },
    {
        name: 'Insurance',
        code: 'BH02',
        subCats: [
            {
                name: 'Buildings Insurance',
                code: 'BH02A',
                schedules: buildingsInsurance,
            },
            {
                name: 'D & O Insurance',
                code: 'BH02B',
                schedules: allToEstate,
            },
            {
                name: 'Engineering Insurance',
                code: 'BH02C',
                schedules: distributeAllHousesShared,
            },
            {
                name: 'Insurance Excess',
                code: 'BH02D',
                schedules: buildingsInsurance,
            },
        ],
    },
    {
        name: 'Electricity',
        code: 'BH03',
        schedules: calcElectricity,
    },
    {
        name: 'Water and Sewerage Costs',
        code: 'BH04',
        schedules: coldWater,
    },
    {
        name: 'Window Cleaning',
        code: 'BH05',
        subCats: [
            {
                name: 'Communal Window Cleaning',
                code: 'BH05A',
                schedules: distributeAllHousesShared,
            },
            {
                name: 'Communal Canopy Cleaning',
                code: 'BH05B',
                schedules: allToEstate,
            },
        ],
    },
    {
        name: 'Communal Area Cleaning',
        code: 'BH06',
        schedules: communalCleaning,
    },
    {
        name: 'Refuse Bin Costs',
        code: 'BH07',
        subCats: [
            {
                name: 'Waste Removal Contract',
                code: 'BH07A',
                schedules: allToEstate,
            },
            {
                name: 'Bulk Item Removal',
                code: 'BH07B',
                schedules: housesBy33,
            },
        ],
    },
    {
        name: 'Grounds Maintenance',
        code: 'BH08',
        schedules: groundsMaintenance,
    },
    {
        name: 'Lift Costs',
        code: 'BH09',
        schedules: liftCosts,
    },
    {
        name: 'Fire Equipment and Maintenance',
        code: 'BH10',
        subCats: [
            {
                name: 'AOVs, Fire Alarms & Smoke Detectors',
                code: 'BH010A',
                schedules: housesBySize,
            },
            {
                name: 'CCTV',
                code: 'BH010B',
                schedules: allToEstate,
            },
            {
                name: 'Dry Risers',
                code: 'BH010C',
                schedules: dryRisers,
            },
            {
                name: 'Emergency Light Testing',
                code: 'BH010D',
                schedules: housesBySize,
            },
        ],
    },
    {
        name: 'Door Entry System Maintenance',
        code: 'BH11',
        schedules: doorEntry,
    },
    {
        name: 'Aerial System Maintenance',
        code: 'BH12',
        schedules: housesBy33,
    },
    {
        name: 'Gates and Barrier Maintenance',
        code: 'BH13',
        schedules: gatesAndBarriers,
    },
    {
        name: 'General Maintenance',
        code: 'BH14',
        subCats: [
            {
                name: 'Lightning Conductor',
                code: 'BH014A',
                schedules: distributeAllHousesShared,
            },
            {
                name: 'Water Tank Marseille',
                code: 'BH014B',
                schedules: allMarseille,
            },
            {
                name: 'Water Pump Marseille',
                code: 'BH014C',
                schedules: allMarseille,
            },
            {
                name: 'Foul Pump Servicing',
                code: 'BH014D',
                schedules: allToEstate,
            },
            {
                name: 'General Repairs',
                code: 'BH014E',
                schedules: generalRepairs,
            },
            { name: 'Visitor Parking', code: 'BH014F', schedules: allToEstate },
            {
                name: 'Paving & External Repairs',
                code: 'BH014G',
                schedules: allToEstate,
            },
        ],
    },
    { name: 'Mansafe Maintenance', code: 'BH16', schedules: housesBy33 },
    { name: 'Leisure Centre Repairs', code: 'BH17', schedules: allToEstate },
    {
        name: 'Leisure Centre Electricity',
        code: 'BH18',
        schedules: allToEstate,
    },
    { name: 'Leisure Centre Gas', code: 'BH19', schedules: allToEstate },
    { name: 'Leisure Centre Water', code: 'BH20', schedules: allToEstate },
    { name: 'Caretaker', code: 'BH21', schedules: caretaker },
    { name: 'Pest Control', code: 'BH22', schedules: allToEstate },
    {
        name: 'Supplies, Materials & IT System Management Fees',
        code: 'BH23',
        schedules: allToEstate,
    },
    {
        name: 'Accountancy and Audit Fees',
        code: 'BH24',
        schedules: allToEstate,
    },
    {
        name: 'Legal and Professional Fees',
        code: 'BH25',
        schedules: allToEstate,
    },
    { name: 'Company Secretarial Fee', code: 'BH26', schedules: allToEstate },
    {
        name: 'Health and Safety Costs',
        code: 'BH28',
        schedules: buildingsInsurance,
    },
];

export const constructSchedule = (
    budget: BudgetHeadValues[]
): {
    budgetHead: string;
    budgetCode: string;
    [key: string]: number | string;
}[] => {
    const reserveTotal = budget
        .filter((budgetHead) => budgetHead.code.includes('SDH'))
        .reduce((a, b) => a + b.value, 0);

    const reserveAmounts = budget
        .filter((budgetHead) => budgetHead.code.includes('SDH'))
        .reduce((a, b) => ({ ...a, [b.code]: b.value }), {});

    return [
        ...BudgetHeads.flatMap((head) => {
            if (!head?.subCats) return constructBreakdown(budget, head);
            return head.subCats.map((subHead) =>
                constructBreakdown(budget, subHead)
            );
        }),
        {
            budgetHead: 'Contribution to Reserves',
            budgetCode: 'RES',
            total: reserveTotal,
            ...reserveAmounts,
        },
    ];
};

const constructBreakdown = (
    budget: BudgetHeadValues[],
    head: BudgetHeadConst
) => {
    const total =
        budget.find((budgeHead) => budgeHead.code === head.code)?.value || 0;
    return {
        budgetHead: head.name,
        budgetCode: head.code,
        total: total,
        ...((head.schedules &&
            head.schedules(total).reduce(
                (a, b) => ({
                    ...a,
                    [BudgetSchedules?.find((sched) => sched.code === b.code)
                        ?.code || b.code]: b.amount,
                }),
                {}
            )) ||
            0),
    };
};
