<mat-list>
    <mat-list-item>
        <mat-label>Add Note</mat-label>
    </mat-list-item>

    <mat-list-item>
        <mat-form-field appearance="outline">
            <mat-label>Note Text</mat-label>
            <textarea matInput rows="5" [(ngModel)]="data.text"></textarea>
        </mat-form-field>
    </mat-list-item>

    <mat-list-item>
        <button mat-flat-button [mat-dialog-close]="null">Cancel</button>
        <button
            mat-flat-button
            [mat-dialog-close]="data.text"
            [disabled]="!data.text"
        >
            Save Note
        </button>
    </mat-list-item>
</mat-list>
