import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {
    AuthServiceService,
    UserGroups,
} from '../auth-service/auth-service.service';

const RbacRoles = {
    'dashboard-page': [],
    'property-search': [],
    'property-results': [],
    reports: [UserGroups.ReportsFull],
    'reports-viewer': [UserGroups.ReportsFull],
    'work-orders': [UserGroups.WorkordersAccess],
    'create-works-order': [UserGroups.WorkordersAccess],
    'view-work-order': [UserGroups.WorkordersAccess],
    budgets: [UserGroups.FinanceAccess, UserGroups.BudgetsAccess],
    'edit-budget': [
        UserGroups.FinanceAccess,
        UserGroups.BudgetsAccess,
        UserGroups.BudgetEdit,
    ],
    expenses: [UserGroups.FinanceAccess, UserGroups.ExpensesAccess],
    variance: [UserGroups.FinanceAccess, UserGroups.VarianceAccess],
    income: [UserGroups.FinanceAccess, UserGroups.IncomeAccess],
    'finance-reports': [
        UserGroups.FinanceAccess,
        UserGroups.FinanceReportsAccess,
    ],
    'invoice-payments': [
        UserGroups.FinanceAccess,
        UserGroups.FinanceReportsAccess,
    ],
    debtors: [UserGroups.FinanceAccess, UserGroups.FinanceReportsAccess],
    logging: [UserGroups.Admin],
    'admin-panel': [UserGroups.Admin],
};

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService  {
    constructor(
        public router: Router,
        public auth: AuthServiceService
    ) {}

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        let isAuth = this.auth.isLoggedIn();
        if (!isAuth) return this.router.navigate(['']);

        const url = state.url.split('/').pop();
        const groups = await this.auth.getGroups();

        if (!groups.includes('Staff')) return this.router.navigate(['']);
        if (!this.hasAllRoles(url as keyof typeof RbacRoles, groups)) {
            alert('Not Authorised');
            return false;
        }
        return true;
    }

    hasAllRoles(
        page: keyof typeof RbacRoles,
        groups: (string | number)[]
    ): boolean {
        if (!(page in RbacRoles)) return true;

        return RbacRoles[page].every((role) => groups.includes(role));
    }
}
