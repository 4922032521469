import { Component, OnInit } from '@angular/core';
import { AuditService } from '../../services/audit/audit.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-logging',
    templateUrl: './logging.component.html',
    styleUrls: ['./logging.component.css'],
})
export class LoggingComponent implements OnInit {
    logs!: any[];

    constructor(public audit: AuditService) {}

    async ngOnInit(): Promise<void> {
        this.logs = await firstValueFrom(this.audit.getLogs());
        this.logs = this.logs.sort(
            (a, b) =>
                new Date(b.timestamp).getTime() -
                new Date(a.timestamp).getTime()
        );
    }
}
