<header>
    <mat-card-title>
        <mat-icon>currency_pound</mat-icon>
        Payment Details
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<mat-progress-bar
    mode="indeterminate"
    *ngIf="!contractorDetails"
></mat-progress-bar>

<ul *ngIf="contractorDetails">
    <li>
        <mat-label class="fieldNames">Bank Name:</mat-label>
        <mat-label class="fieldValues">{{
            contractorDetails.bankName
        }}</mat-label>
    </li>
    <li>
        <mat-label class="fieldNames">Account Name:</mat-label>
        <mat-label class="fieldValues">{{
            contractorDetails.accountName
        }}</mat-label>
    </li>
    <li>
        <mat-label class="fieldNames">Account Number:</mat-label>
        <mat-label class="fieldValues">{{
            contractorDetails.accountNumber
        }}</mat-label>
    </li>
    <li>
        <mat-label class="fieldNames">Sort Code:</mat-label>
        <mat-label class="fieldValues">{{
            contractorDetails.sortCode
        }}</mat-label>
    </li>
</ul>
