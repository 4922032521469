import { Component, OnInit } from '@angular/core';
import { WorkOrderStatus } from '../../enums/work-orders';
import { WorkOrdersService } from '../../services/work-orders/work-orders.service';
import { firstValueFrom } from 'rxjs';
import { ContractorDetails } from '../contractor-sign-up/contractor-sign-up.component';
import { Router } from '@angular/router';
import {
    AuthServiceService,
    UserGroups,
} from '../../services/auth-service/auth-service.service';

export interface WorkOrder {
    orderNumber: string;
    dateCreated: string;
    status: WorkOrderStatus;
    contractorId: Partial<ContractorDetails>;
    expenseCategory: string;
    leaseGroups: { [key: string]: number };
    description: string;
    risks: string[];
    audits?: { date: string; event: string; user: string }[];
}

@Component({
    selector: 'app-work-orders',
    templateUrl: './work-orders.component.html',
    styleUrls: ['./work-orders.component.css'],
})
export class WorkOrdersComponent implements OnInit {
    workOrders: WorkOrder[] = [];
    openWorkOrders: WorkOrder[] = [];
    closedWorkOrders: WorkOrder[] = [];
    filteredOpenOrders: WorkOrder[] = [];
    filteredClosedOrders: WorkOrder[] = [];
    orderFilter!: string;
    loading: boolean = true;
    userGroups: (string | number)[] = [];

    constructor(
        public workOrderService: WorkOrdersService,
        public router: Router,
        public auth: AuthServiceService
    ) {}

    async ngOnInit(): Promise<void> {
        this.loading = true;
        try {
            this.workOrders = await firstValueFrom(
                await this.workOrderService.getAllOrders()
            );

            this.openWorkOrders = this.workOrders
                .filter((order) =>
                    [
                        WorkOrderStatus.AwaitingPayment,
                        WorkOrderStatus.Open,
                        WorkOrderStatus.InvoiceApproval,
                        WorkOrderStatus.AwaitingAuth,
                    ].includes(order.status)
                )
                .sort((a, b) => b!.orderNumber!.localeCompare(a!.orderNumber!));

            this.closedWorkOrders = this.workOrders
                .filter((order) =>
                    [WorkOrderStatus.Closed, WorkOrderStatus.Paid].includes(
                        order.status
                    )
                )
                .sort((a, b) => b!.orderNumber!.localeCompare(a!.orderNumber!));

            this.filteredOpenOrders = this.openWorkOrders;
            this.filteredClosedOrders = this.closedWorkOrders;
        } catch (error) {
            console.error(error);
        } finally {
            this.loading = false;
        }
    }

    filterWorkOrders(filter: string) {
        this.filteredOpenOrders = this.openWorkOrders.filter((order) =>
            Object.values(order).some((orderValue) => {
                if (!orderValue) return false;
                if (typeof orderValue === 'string') {
                    return orderValue
                        .toLowerCase()
                        .includes(filter.toLowerCase() || '');
                } else {
                    return orderValue?.companyName
                        .toLowerCase()
                        .includes(filter.toLowerCase() || '');
                }
            })
        );

        this.filteredClosedOrders = this.closedWorkOrders.filter((order) =>
            Object.values(order).some((orderValue) => {
                if (!orderValue) return false;
                if (typeof orderValue === 'string') {
                    return orderValue
                        ?.toLowerCase()
                        .includes(filter.toLowerCase() || '');
                } else {
                    return orderValue?.companyName
                        .toLowerCase()
                        .includes(filter.toLowerCase() || '');
                }
            })
        );
    }

    protected readonly UserGroups = UserGroups;
    protected readonly WorkOrderStatus = WorkOrderStatus;
}
