<header>
    <mat-card-title>
        <mat-icon>analytics</mat-icon>
        Audit Events
    </mat-card-title>
    <button mat-icon-button [mat-dialog-close]="null">
        <mat-icon>close</mat-icon>
    </button>
</header>

<mat-list dense>
    <div *ngFor="let audit of data?.audits | reverse">
        <mat-list-item class="auditList">
            <mat-icon mat-list-icon>notes</mat-icon>
            <mat-label mat-line class="auditTitle"
                >{{ audit.date | date: 'dd-MM-yyyy - hh:mm a' }} -
                {{ audit.event }} By {{ audit.user }}</mat-label
            >
        </mat-list-item>
    </div>
</mat-list>
