<ul>
    <br />
    <li *ngFor="let log of logs">
        <mat-label>{{
            log.timestamp | date: 'dd-MM-yyyy  -  hh:mm a'
        }}</mat-label>

        <mat-label>{{ log.message }}</mat-label>
    </li>
</ul>
