import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PropertyDetailsService } from '../../services/property-details/property-details.service';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-service-charge-modal',
    templateUrl: './service-charge-modal.component.html',
    styleUrls: ['./service-charge-modal.component.css'],
})
export class ServiceChargeModalComponent implements OnInit {
    serviceCharge: string | undefined;
    loading: boolean = false;
    completed: boolean = false;
    errorMessage: string | undefined;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ServiceChargeModalComponent>,
        public propertyDetailsService: PropertyDetailsService,
        public auth: AuthServiceService
    ) {}

    async ngOnInit(): Promise<void> {}

    async updateDemand() {
        this.loading = true;
        try {
            const budgetMonth = this.serviceCharge!.split('_')[0] as
                | 'march'
                | 'september';
            const selectedYear = +this.serviceCharge!.split('_')[1];
            const budgetYear =
                budgetMonth === 'march'
                    ? `${selectedYear - 1}-${selectedYear}`
                    : `${selectedYear}-${selectedYear + 1}`;
            await firstValueFrom(
                this.propertyDetailsService.updateServiceCharge(
                    budgetYear,
                    this.data.unitFull,
                    budgetMonth
                )
            );
            this.loading = false;
            this.completed = true;
        } catch (error: any) {
            this.loading = false;
            this.errorMessage = error.message;
        }
    }
}
