<mat-toolbar>
    <h2>Century Wharf Estates</h2>
    <ng-container>
        <button mat-stroked-button [mat-menu-trigger-for]="profileMenu">
            <mat-label>{{ userName }}</mat-label>
            <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #profileMenu="matMenu">
            <button mat-menu-item disabled>
                <mat-icon>settings</mat-icon>
                Account Settings
            </button>
            <button mat-menu-item (click)="logOut()">
                <mat-icon>arrow_back</mat-icon>
                Sign Out
            </button>
        </mat-menu>
    </ng-container>
</mat-toolbar>
<mat-toolbar id="selectTool">
    <h2>50 Ezel Court</h2>
    <mat-icon></mat-icon>
</mat-toolbar>

<div id="container"></div>
