<app-page-toolbar [pageTitle]="'Budgets'"> </app-page-toolbar>

<app-cwe-spinner *ngIf="loading"></app-cwe-spinner>

<div *ngIf="!loading">
    <div>
        <button
            mat-raised-button
            class="createNew"
            routerLink="/home-page/edit-budget"
            *ngIf="userGroups.includes('BudgetEdit')"
        >
            Edit {{ nextBudgetYear }} Budget
        </button>
    </div>

    <mat-card *ngIf="selectedBudgets">
        <mat-card-header>
            <mat-label>Select Year:</mat-label>
            <mat-select
                multiple
                [(ngModel)]="selectedYears"
                (ngModelChange)="updateSelectedBudgets()"
            >
                <mat-option *ngFor="let year of budgetYears" [value]="year">{{
                    year
                }}</mat-option>
            </mat-select>
        </mat-card-header>
        <div *ngIf="updating" class="loadingSpinner">
            <mat-spinner></mat-spinner>
        </div>
        <div class="listDiv" *ngIf="!updating">
            <ul>
                <li>
                    <div class="inputDiv" *ngIf="selectedBudgets">
                        <mat-label class="mat-body-strong catName"
                            >Categories</mat-label
                        >
                        <div
                            class="moneyDiv"
                            *ngFor="let budget of selectedBudgets"
                        >
                            <mat-label class="mat-body-strong">{{
                                budget.budgetYear
                            }}</mat-label>
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </li>
                <li *ngFor="let head of budgetHeads">
                    <div
                        class="inputDiv"
                        *ngIf="selectedBudgets && !head?.subCats"
                    >
                        <mat-label class="catName">{{ head.name }}</mat-label>
                        <div
                            class="moneyDiv"
                            *ngFor="let budget of selectedBudgets"
                        >
                            <input
                                currencyMask
                                [options]="{ prefix: '£ ' }"
                                matInput
                                autocomplete="off"
                                placeholder="0.00"
                                [value]="getModelValue(head, budget)"
                                disabled
                            />
                        </div>
                    </div>

                    <div class="listWrapper" *ngIf="head?.subCats">
                        <ul>
                            <li>
                                <div class="inputDiv">
                                    <mat-label
                                        class="mat-body-strong catName"
                                        >{{ head.name }}</mat-label
                                    >
                                    <div
                                        class="moneyDiv"
                                        *ngFor="let budget of selectedBudgets"
                                    >
                                        <input
                                            class="mat-body-strong"
                                            currencyMask
                                            [options]="{ prefix: '£ ' }"
                                            matInput
                                            autocomplete="off"
                                            placeholder="0.00"
                                            [ngModelOptions]="{
                                                standalone: true
                                            }"
                                            [ngModel]="
                                                calculateCatValue(
                                                    head?.subCats,
                                                    budget
                                                )
                                            "
                                            disabled
                                        />
                                    </div>
                                </div>
                            </li>
                            <li *ngFor="let subCat of head.subCats">
                                <div class="inputDiv">
                                    <mat-label class="catName"
                                        >- {{ subCat?.name }}</mat-label
                                    >
                                    <div
                                        class="moneyDiv"
                                        *ngFor="let budget of selectedBudgets"
                                    >
                                        <input
                                            currencyMask
                                            [options]="{ prefix: '£ ' }"
                                            matInput
                                            autocomplete="off"
                                            placeholder="0.00"
                                            [value]="
                                                getModelValue(subCat, budget)
                                            "
                                            disabled
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <mat-divider></mat-divider>
                </li>
                <li *ngIf="selectedBudgets">
                    <div class="inputDiv">
                        <mat-label class="mat-body-strong catName"
                            >Budget Total</mat-label
                        >
                        <div
                            class="moneyDiv"
                            *ngFor="let budget of selectedBudgets"
                        >
                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [value]="calculateTotal(budget, 'BH')"
                            />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="listDiv">
            <ul>
                <li id="reserveDropDown" (click)="reserveOpen = !reserveOpen">
                    <div class="inputDiv" *ngIf="selectedBudgets">
                        <mat-label class="mat-body-strong catName">
                            <mat-icon
                                >keyboard_double_arrow_{{
                                    reserveOpen ? 'down' : 'right'
                                }}</mat-icon
                            >
                            Reserve Contributions
                        </mat-label>
                        <div
                            class="moneyDiv"
                            *ngFor="let budget of selectedBudgets"
                        >
                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [value]="calculateTotal(budget, 'SDH')"
                            />
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </li>
                <div *ngIf="reserveOpen">
                    <li *ngFor="let head of reserveHeads">
                        <div class="inputDiv">
                            <mat-label class="catName">{{
                                head.name
                            }}</mat-label>
                            <div
                                class="moneyDiv"
                                *ngFor="let budget of selectedBudgets"
                            >
                                <input
                                    currencyMask
                                    [options]="{
                                        prefix: '£ ',
                                        allowNegative: false
                                    }"
                                    matInput
                                    disabled
                                    [value]="getModelValue(head, budget)"
                                />
                            </div>
                        </div>
                        <mat-divider></mat-divider>
                    </li>
                </div>
            </ul>
        </div>

        <div class="listDiv">
            <ul>
                <li>
                    <div class="inputDiv" *ngIf="selectedBudgets">
                        <mat-label class="mat-body-strong catName"
                            >Total Expected Income</mat-label
                        >
                        <div
                            class="moneyDiv"
                            *ngFor="let budget of selectedBudgets"
                        >
                            <input
                                currencyMask
                                [options]="{
                                    prefix: '£ ',
                                    allowNegative: false
                                }"
                                class="mat-body-strong"
                                matInput
                                disabled
                                [value]="calculateTotal(budget, '')"
                            />
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </mat-card>
</div>
