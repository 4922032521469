<mat-sidenav-container class="nav-container">
  <mat-sidenav
    #sidenav
    mode="side"
    opened
    class="wide"
    *ngIf="!mobile"
  >
    <div id="sideBar">

      <img id="navLogo" src="assets/navLogo%20copy.png" alt="logo" />
      <div id="navContent">
        <ul>
          <li
            routerLink="/home-page/dashboard-page"
            *ngIf="userGroups?.includes('Staff')"
          >
            <mat-icon
              *ngIf="approvals > 0"
              matBadge="{{ approvals }}"
              matBadgeColor="accent"
              matBadgeSize="small"
              matBadgeOverlap="true"
            >home
            </mat-icon>
            <mat-icon *ngIf="approvals === 0">home</mat-icon>
            <a class="navItem">Dashboard</a>
          </li>

          <li
            routerLink="/home-page/property-search"
            *ngIf="userGroups?.includes('Staff')"
          >
            <mat-icon>apartment</mat-icon>
            <a class="navItem">Property</a>
          </li>

          <li
            routerLink="/home-page/contractors"
            *ngIf="userGroups?.includes('ContractAccess')"
          >
            <mat-icon>people</mat-icon>
            <a class="navItem">Contractors</a>
          </li>

          <li
            routerLink="/home-page/work-orders"
            *ngIf="userGroups?.includes('WorkordersAccess')"
          >
            <mat-icon>work</mat-icon>
            <a class="navItem">Work Orders</a>
          </li>

          <li
            routerLink="/home-page/reports"
            *ngIf="userGroups?.includes('ReportsFull')"
          >
            <mat-icon>analytics</mat-icon>
            <a class="navItem">Reports</a>
          </li>

          <hr>

          <li
            routerLink="/home-page/budgets"
            *ngIf="userGroups?.includes('BudgetsAccess')"
          >
            <mat-icon>edit_square</mat-icon>
            <a class="navItem">Budgets</a>
          </li>

          <li
            routerLink="/home-page/expenses"
            *ngIf="userGroups?.includes('ExpensesAccess')"
          >
            <mat-icon>payments</mat-icon>
            <a class="navItem">Expenses</a>
          </li>

          <li
            routerLink="/home-page/variance"
            *ngIf="userGroups?.includes('VarianceAccess')"
          >
            <mat-icon>percent</mat-icon>
            <a class="navItem">Variance</a>
          </li>

          <li
            routerLink="/home-page/income"
            *ngIf="userGroups?.includes('IncomeAccess')"
          >
            <mat-icon>savings</mat-icon>
            <a class="navItem">Income</a>
          </li>

          <li
            routerLink="/home-page/finance-reports"
            *ngIf="userGroups?.includes('FinanceReportsAccess')"
          >
            <mat-icon>analytics</mat-icon>
            <a class="navItem">Finance Reports</a>
          </li>
        </ul>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="{{mobile? 'wideContent' : ''}}">
    <mat-toolbar>
      <h2 *ngIf="!mobile">Century Wharf Estates</h2>


      <div id="menu-div">
        <button *ngIf="mobile" mat-icon-button (click)="toggleMenu()">
          <mat-icon>menu</mat-icon>
        </button>
        <div id="site-div">

          <mat-icon id="site-icon">apartment</mat-icon>
          <mat-select id="site-select" [(ngModel)]="selectedSite">
            <mat-option value="century-wharf">Century Wharf</mat-option>
          </mat-select>
        </div>


      </div>

      <div>
        <button
          mat-stroked-button
          *ngIf="userName === 'Chris Rowlands' && !mobile"
          routerLink="/home-page/admin-panel"
        >
          <mat-label>Admin</mat-label>
        </button>
        <button
          mat-stroked-button
          *ngIf="userName === 'Chris Rowlands' && !mobile"
          routerLink="/home-page/logging"
        >
          <mat-label>Logs</mat-label>
        </button>
        <button mat-stroked-button [mat-menu-trigger-for]="profileMenu">
          <mat-label>{{ userName }}</mat-label>
          <mat-icon>account_circle</mat-icon>
        </button>
        <mat-menu #profileMenu="matMenu">
          <button mat-menu-item disabled>
            <mat-icon>settings</mat-icon>
            Account Settings
          </button>
          <button mat-menu-item (click)="logOut()">
            <mat-icon>arrow_back</mat-icon>
            Sign Out
          </button>
        </mat-menu>
      </div>

    </mat-toolbar>
    <router-outlet></router-outlet>

    <div id="mobile-menu" *ngIf="mobileMenu && mobile" [@inOutAnimation] (click)="toggleMenu()">
      <ul>
        <li
          routerLink="/home-page/dashboard-page"
          *ngIf="userGroups?.includes('Staff')"
        >

          <mat-icon>home</mat-icon>
          <a class="navItem">Dashboard</a>
        </li>

        <li
          routerLink="/home-page/property-search"
          *ngIf="userGroups?.includes('Staff')"
        >
          <mat-icon>apartment</mat-icon>
          <a class="navItem">Property</a>
        </li>

        <li
          routerLink="/home-page/contractors"
          *ngIf="userGroups?.includes('ContractAccess')"
        >
          <mat-icon>people</mat-icon>
          <a class="navItem">Contractors</a>
        </li>

        <li
          routerLink="/home-page/work-orders"
          *ngIf="userGroups?.includes('WorkordersAccess')"
        >
          <mat-icon>work</mat-icon>
          <a class="navItem">Work Orders</a>
        </li>

        <li
          routerLink="/home-page/reports"
          *ngIf="userGroups?.includes('ReportsFull')"
        >
          <mat-icon>analytics</mat-icon>
          <a class="navItem">Reports</a>
        </li>


        <li
          routerLink="/home-page/budgets"
          *ngIf="userGroups?.includes('BudgetsAccess')"
        >
          <mat-icon>edit_square</mat-icon>
          <a class="navItem">Budgets</a>
        </li>

        <li
          routerLink="/home-page/expenses"
          *ngIf="userGroups?.includes('ExpensesAccess')"
        >
          <mat-icon>payments</mat-icon>
          <a class="navItem">Expenses</a>
        </li>

        <li
          routerLink="/home-page/variance"
          *ngIf="userGroups?.includes('VarianceAccess')"
        >
          <mat-icon>percent</mat-icon>
          <a class="navItem">Variance</a>
        </li>

        <li
          routerLink="/home-page/income"
          *ngIf="userGroups?.includes('IncomeAccess')"
        >
          <mat-icon>savings</mat-icon>
          <a class="navItem">Income</a>
        </li>

        <li
          routerLink="/home-page/finance-reports"
          *ngIf="userGroups?.includes('FinanceReportsAccess')"
        >
          <mat-icon>analytics</mat-icon>
          <a class="navItem">Finance Reports</a>
        </li>
      </ul>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
