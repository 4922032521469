import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthServiceService } from '../../services/auth-service/auth-service.service';
import { Router } from '@angular/router';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-forgot-password-modal',
    templateUrl: './forgot-password-modal.component.html',
    styleUrls: ['./forgot-password-modal.component.css'],
})
export class ForgotPasswordModalComponent implements OnInit {
    email!: string;
    enterEmail: boolean = true;
    passwordForm: FormGroup = new FormGroup<any>({
        newPassword: new FormControl(),
        confirmPassword: new FormControl(),
        verificationCode: new FormControl(),
    });
    match: boolean = false;
    oneNumber: boolean = false;
    oneSpec: boolean = false;
    oneUpper: boolean = false;
    oneLower: boolean = false;
    minlength: boolean = false;
    newPassword!: string;
    verifyCode!: string;
    code: boolean = false;

    constructor(
        public auth: AuthServiceService,
        public router: Router,
        public dialog: MatDialogRef<ForgotPasswordModalComponent>
    ) {}

    ngOnInit(): void {
        this.passwordForm.valueChanges.subscribe(
            (value: { [key: string]: string }) => {
                const { verificationCode, newPassword, confirmPassword } =
                    value;
                this.match = newPassword === confirmPassword && !!newPassword;
                this.oneNumber = /\d/.test(newPassword);
                this.oneSpec = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
                    newPassword
                );
                this.oneUpper = /[A-Z]/.test(newPassword) && !!newPassword;
                this.oneLower = /[a-z]/.test(newPassword) && !!newPassword;
                this.minlength = newPassword?.length >= 8;
                this.code = !!verificationCode;
                this.newPassword = newPassword;
                this.verifyCode = verificationCode;
            }
        );
    }

    getCognitoUser = () => {
        const poolData = {
            UserPoolId: environment.cognitoUserPoolId,
            ClientId: environment.cognitoAppClientId,
        };
        const userPool = new CognitoUserPool(poolData);
        const userData = {
            Username: this.email,
            Pool: userPool,
        };
        return new CognitoUser(userData);
    };

    submitEmail = () => {
        const cognitoUser = this.getCognitoUser();
        cognitoUser.forgotPassword({
            onSuccess: (data) => {
                this.enterEmail = false;
            },
            onFailure: (error) => {
                console.error(error);
            },
        });
    };

    createNew = () => {
        const cognitoUser = this.getCognitoUser();
        cognitoUser.confirmPassword(this.verifyCode, this.newPassword, {
            onSuccess: (data) => {
                const authDetails = new AuthenticationDetails({
                    Username: this.email,
                    Password: this.newPassword,
                });
                cognitoUser.authenticateUser(authDetails, {
                    onSuccess: (session, userConfirmationNecessary) => {
                        this.navigateOnSuccess();
                    },
                    onFailure: (error) => {
                        alert(error.message);
                    },
                });
            },
            onFailure: (error) => {
                console.error(error);
            },
        });
    };

    navigateOnSuccess = async () => {
        const groups = await this.auth.getGroups();
        const profile = await this.auth.getProfile();

        if (groups.includes('Staff'))
            await this.router.navigate(['home-page/dashboard-page']);
        if (groups.includes('Contractor'))
            await this.router.navigate([`/contractor/${profile}`]);
        this.dialog.close();
    };
}
