<div id="container" *ngIf="queryCode === code">
    <div class="imgContainer">
        <img alt="" id="signUpLogo" src="assets/navLogo.png" />
    </div>

    <h1 *ngIf="!successfulSubmit">Contractor Registration</h1>
    <p *ngIf="!successfulSubmit">
        Please fill out the following details. Once submitted, a member of the
        team will approve the request and you will receive an E-mail with
        instructions to create a password.
    </p>
    <div
        *ngIf="!successfulSubmit"
        [formGroup]="signUpForm"
        class="signUpFormContainer"
    >
        <h3>Company Details</h3>

        <small
            *ngIf="
                signUpForm.controls['companyName'].touched &&
                signUpForm.controls['companyName'].invalid
            "
            class="errorMessage"
            >Please enter a Company Name</small
        >
        <mat-form-field appearance="outline">
            <mat-label>Company Name</mat-label>
            <input formControlName="companyName" matInput />
        </mat-form-field>

        <small
            *ngIf="
                signUpForm.controls['contactName'].touched &&
                signUpForm.controls['contactName'].invalid
            "
            class="errorMessage"
            >Please enter a Contact Name</small
        >
        <mat-form-field appearance="outline">
            <mat-label>Contact Full Name</mat-label>
            <input formControlName="contactName" matInput />
        </mat-form-field>

        <small
            *ngIf="
                signUpForm.controls['email'].touched &&
                signUpForm.controls['email'].invalid
            "
            class="errorMessage"
            >Please enter a valid Email Address</small
        >
        <mat-form-field appearance="outline">
            <mat-label>E-Mail</mat-label>
            <input formControlName="email" matInput />
        </mat-form-field>

        <small
            *ngIf="
                signUpForm.controls['phone'].touched &&
                signUpForm.controls['phone'].invalid
            "
            class="errorMessage"
            >Please enter a valid Phone Number</small
        >
        <mat-form-field appearance="outline">
            <mat-label>Phone Number</mat-label>
            <input formControlName="phone" matInput />
        </mat-form-field>

        <small
            *ngIf="
                signUpForm.controls['addressOne'].touched &&
                signUpForm.controls['addressOne'].invalid
            "
            class="errorMessage"
            >Please enter a valid Address Line One</small
        >
        <mat-form-field appearance="outline">
            <mat-label>Address Line 1</mat-label>
            <input formControlName="addressOne" matInput />
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Address Line 2</mat-label>
            <input formControlName="addressTwo" matInput />
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Address Line 3</mat-label>
            <input formControlName="addressThree" matInput />
        </mat-form-field>

        <small
            *ngIf="
                signUpForm.controls['postcode'].touched &&
                signUpForm.controls['postcode'].invalid
            "
            class="errorMessage"
            >Please enter a valid Postcode</small
        >
        <mat-form-field appearance="outline">
            <mat-label>Post Code</mat-label>
            <input class="upperCase" formControlName="postcode" matInput />
        </mat-form-field>

        <h3>Relevant Documents</h3>
        <p>
            Please upload any documents that are relevant to the service you
            provide. ( .pdf )<br /><small>
                For Example: Risk Assessment Policy, Health and Safety Policy,
                Public Liability Insurance.
            </small>
        </p>

        <div
            *ngFor="let field of filesArray.controls; index as i"
            [formGroup]="field"
            class="fileDiv"
        >
            <mat-form-field appearance="outline" class="fileFormFields">
                <mat-label>Document Type</mat-label>
                <mat-select formControlName="fileType">
                    <mat-option
                        *ngFor="let fileType of documentTypes"
                        [value]="fileType"
                        >{{ fileType }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
            <mat-form-field
                appearance="outline"
                class="fileFormFields"
                (click)="fileUploadInput.openFilePicker()"
            >
                <ngx-mat-file-input
                    #fileUploadInput
                    accept="application/pdf"
                    formControlName="file"
                >
                </ngx-mat-file-input>
                <button
                    (click)="removeFile(i)"
                    color="warn"
                    mat-icon-button
                    matSuffix
                >
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <button (click)="addFile()" *ngIf="fileFields.length <= 10" mat-button>
            Add Another File
        </button>
        <small *ngIf="showFileTypeMessage" class="errorMessage"
            >Please make sure you have selected a Document Type for all uploaded
            files</small
        >
        <small *ngIf="showWrongType" class="errorMessage"
            >Unsupported file formats. Please only upload PDF files</small
        >

        <br />
        <h3>Consent to store and use Personal Data</h3>
        <mat-checkbox formControlName="consent"
            >I consent to my personal data being stored and passed on to
            Landlords, Agents and Tenants where required, to allow them to give
            me access to carry out repairs, maintenance and any services I
            provide.
        </mat-checkbox>

        <small class="errorMessage">{{ errorMessage }}</small>
        <mat-progress-bar
            *ngIf="submitting"
            mode="indeterminate"
        ></mat-progress-bar>
        <button
            (click)="submit()"
            [disabled]="signUpForm.invalid || submitting"
            class="submit"
            mat-raised-button
        >
            {{ submitting ? 'Submitting...' : 'Submit' }}
        </button>
    </div>
    <div *ngIf="successfulSubmit" class="signUpFormContainer">
        <h1>Application Submitted</h1>
        <p>
            Your application has been submitted. A member of the team will
            review your details shortly.
        </p>
        <p>If accepted, you will receive an e-mail to create a password.</p>
        <p>
            If there is an issue with any part of your application, a member of
            the team will be in touch to advise.
        </p>
        <p></p>
    </div>
    <div class="footer"></div>
</div>
