import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.css'],
})
export class InfoModalComponent {
    constructor(
        public dialogRef: MatDialogRef<InfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
}
