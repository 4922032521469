import { Component, OnInit } from '@angular/core';
import { ContractorsService } from '../../services/contractors/contractors.service';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-invite-component',
    templateUrl: './invite-component.component.html',
    styleUrls: ['./invite-component.component.css'],
})
export class InviteComponentComponent implements OnInit {
    email: string | undefined;
    emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/;
    success: string = 'Invite Sent Successfully';
    fail: string = 'An Error Occurred';
    message: string | undefined;
    sending = false;

    constructor(public contractorService: ContractorsService) {}

    ngOnInit(): void {}

    async sendInvite() {
        if (!this.email) return;
        try {
            this.sending = true;
            await firstValueFrom(
                this.contractorService.inviteContractor(this.email)
            );
            this.message = this.success;
        } catch (error) {
            this.message = this.fail;
        } finally {
            this.sending = false;
        }
    }
}
