import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

type canDeactivateType = Observable<boolean> | Promise<boolean> | boolean;

export interface CanComponentDeactivate {
    canDeactivate: () => canDeactivateType;
}

@Injectable({
    providedIn: 'root',
})
export class CanDeactivateGuard
    
{
    constructor(
        public dialog: MatDialog,
        public router: Router
    ) {}

    canDeactivate(component: CanComponentDeactivate): canDeactivateType {
        return component.canDeactivate ? component.canDeactivate() : true;
    }
}
